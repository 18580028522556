import qs from 'qs';
import React, { useCallback, useState } from 'react';
import { EventHeaderMobile } from 'components/Events';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Spacer from 'components/Spacer';
import { useGetRefundStatus } from 'components/Transactions';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import { useUpdateQueryString } from 'hooks/useSearchInput';
import {
  DeliveryOptions,
  OrderOptions,
  PaymentOptions,
  SourceOptions,
  UserOptions,
} from 'pages/app/Transactions/transactionsList';
import { useTransactionFilter } from 'shared/transactionFilterContext';
import MobileSearchInput from '../../employee/Events/search';
import { List } from './list';

export const MobileTransactionsList = withTransactionDateFilterContext(
  (props) => {
    const { match, location } = props;
    const { data } = useGetRefundStatus();
    const [search, setSearch] = useState('');

    const {
      sortBy,
      setSortBy,
      source,
      setSource,
      userType,
      setUserType,
      startDateFilter,
      setStartDateFilter,
      endDateFilter,
      setEndDateFilter,
      paymentMethod,
      setPaymentMethod,
      deliveryMethod,
      setDeliveryMethod,
    } = useTransactionFilter();

    const { page } = qs.parse(window.location.search.substring(1));
    const updateQueryString = useUpdateQueryString();

    const debouncedInputChange = useCallback(
      useDebounceCallback((value) => {
        setSearch(value);
        updateQueryString(value);
      }, 1000),
      []
    );

    const handleChange = (event) => {
      const targetValue = event.target.value;

      debouncedInputChange(targetValue);
      setSearch(targetValue);
    };

    const handleBlur = (e) => {
      updateQueryString(e.target.value);
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        updateQueryString(e.target.value);
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const currentPage = parseInt(urlParams.get('page'));

    return (
      <div>
        <div style={{ paddingInline: 20 }}>
          <MobileSearchInput
            placeholder="Search Transactions.."
            onChange={handleChange}
            value={search}
            handleChange={handleChange}
            handleBlur={handleBlur}
            handleKeyPress={handleKeyPress}
          />
          <Spacer size={10} />

          <TransactionDateFilter
            mobilePicker={true}
            customStartDate={startDateFilter}
            customSetStartDateFilter={setStartDateFilter}
            customEndDate={endDateFilter}
            customSetEndDateFilter={setEndDateFilter}
          />
          <Spacer size={10} />

          <AutoSuggest
            placeholder="Order By"
            options={OrderOptions}
            value={sortBy}
            isSearchable
            isClearable
            onChange={(value) => {
              setSortBy(value ?? null);
            }}
          />
          <Spacer size={10} />

          <AutoSuggest
            placeholder="Source"
            options={SourceOptions}
            value={source}
            isSearchable
            isClearable
            onChange={(value) => {
              setSource(value ?? null);
            }}
          />
          <Spacer size={10} />

          {source?.value === 'pos' && (
            <>
              <AutoSuggest
                placeholder="User Type"
                options={UserOptions}
                value={userType}
                isSearchable
                isClearable
                onChange={(value) => {
                  setUserType(value ?? null);
                }}
              />
              <Spacer size={10} />
            </>
          )}

          {source?.value === 'pos' && (
            <>
              <AutoSuggest
                placeholder="Payment Method"
                options={PaymentOptions}
                value={paymentMethod}
                isSearchable
                isClearable
                onChange={(value) => {
                  setPaymentMethod(value ?? null);
                }}
              />
              <Spacer size={10} />
            </>
          )}

          {source?.value === 'pos' && (
            <AutoSuggest
              placeholder="Delivery Method"
              options={DeliveryOptions}
              value={deliveryMethod}
              isSearchable
              isClearable
              onChange={(value) => {
                setDeliveryMethod(value ?? null);
              }}
            />
          )}
        </div>
        <EventHeaderMobile noDropdown queryDate={'2020-05-23'} noMargin />
        <Spacer size={10} />

        <Spacer size={10} />
        <List
          page={page}
          refundEventStatus={data?.getEventRefundStatus}
          currentPage={currentPage}
          match={match}
          location={{ ...location, search: `?queryString=${search}` }}
        />
      </div>
    );
  }
);
