import React, { useState } from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import LineHeightText from 'components/LineHeightText';
import Loading from 'components/Loading';
import Paragraph from 'components/Paragraph';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { Table } from 'components/Table';
import { FilterContainer } from 'pages/app/AnnualTickets/AnnualTickets';
import { formatTimestamp } from 'shared/formatters';
import { useGetReserveOrders } from '../../gql/queries/get-reserved-orders';

const SearchByOptions = [
  {
    label: 'Order Number',
    value: 'order_number',
  },
  {
    label: 'Ticket Number',
    value: 'ticket_number',
  },
  {
    label: 'Purchaser',
    value: 'purchaser',
  },
  {
    label: 'Tix Holder',
    value: 'ticket_holder',
  },
];

const Order = styled.button`
  color: ${(props) => props.theme.colors.primary};
  font-size: 16px;
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
  font-family: 'Roboto';
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ReservedOrders = ({ onOpenDetail }) => {
  const [searchBy, setSearchBy] = useState(SearchByOptions[0]);

  const { data, loading } = useGetReserveOrders(searchBy && searchBy?.value);

  const columns = [
    {
      label: 'Order Number',
      key: 'orderNumber',
    },
    {
      label: 'Purchase Date/Time',
      key: 'date',
    },
    {
      label: 'Purchaser',
      key: 'purchaser',
    },
    {
      label: 'Transaction Total',
      key: 'transactionTotal',
    },
  ];

  function renderRows(rowData) {
    const { id, purchase_date, total, user } = rowData;

    return {
      orderNumber: (
        <Order onClick={() => onOpenDetail(id)}>
          <LineHeightText>{id}</LineHeightText>
        </Order>
      ),
      date: `${formatTimestamp(
        Number(purchase_date),
        'MMM DD - YYYY h:mm A',
        true
      ).toUpperCase()} EST`,
      purchaser: user?.formatted_name,
      transactionTotal: total ?? '',
    };
  }

  return (
    <SearchableListContainer
      pageCount={data?.getReserveOrders?.count ?? 0}
      paginated
      searchInputPlaceholder={`Search by ${searchBy.label ?? ''}`}
      title="Reserved Orders"
      customStyle={{ width: '100%', margin: 0, border: 'none' }}
      titleBarContent={
        <FilterContainer>
          <AutoSuggest
            placeholder="Search By"
            options={SearchByOptions}
            value={searchBy ?? null}
            isSearchable
            isClearable
            onChange={(role) => {
              setSearchBy(role ?? null);
            }}
          />
        </FilterContainer>
      }
      contentPadding={0}
    >
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginInline: 'auto',
            padding: 20,
          }}
        >
          <Loading size={50} />
        </div>
      ) : !data || data?.getReserveOrders?.count < 1 ? (
        <Paragraph textAlign="center" lineHeight={100} fontSize={20}>
          No Reserved Orders
        </Paragraph>
      ) : (
        <Table
          items={data?.getReserveOrders.results}
          columns={columns}
          renderRows={renderRows}
        />
      )}
    </SearchableListContainer>
  );
};
