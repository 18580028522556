import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import {
  EventsTable,
  EventTrackFilter,
  EventSeriesFilter,
  EventStatusColorSelect,
  useEventYearFilter,
} from 'components/Events';
import { EventAccountFilter } from 'components/Events/Components/EventAccountFilter';
import { EventFilterType } from 'components/Events/Components/EventFilterType';
import { useGetEventsAdmin } from 'components/Events/gql';
import { SearchInput } from 'components/Form/SearchInput';
import Pagination from 'components/Pagination';
import Text from 'components/Text';
import {
  TransactionDateFilter,
  withTransactionDateFilterContext,
} from 'components/Transactions/TransactionDateFilter';
import { useSearchInput, useUpdateQueryString } from 'hooks/useSearchInput';
import useTheme from 'hooks/useTheme';
import { EventHeader } from '../Events/Header';

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const Transactions = withTransactionDateFilterContext(() => {
  const { pathname } = useLocation();
  const theme = useTheme();

  const eventType = pathname.includes('track')
    ? 'track'
    : pathname.includes('/admin/series')
    ? 'series'
    : 'events';
  const { data } = useGetEventsAdmin(eventType);

  const {
    trackFilter,
    setTrackFilter,
    seriesFilter,
    setSeriesFilter,
    accountFilter,
    setAccountFilter,
    filterType,
    setFilterType,
    startTransactionDateFilter,
    setStartTransactionDateFilter,
    endTransactionDateFilter,
    setEndTransactionDateFilter,
  } = useEventYearFilter();

  const events = data?.getEventsAdmin?.results ?? [];
  const count = data?.getEventsAdmin?.count ?? 0;

  function renderPayoutStatus(event) {
    const { id, payout_status } = event;
    return (
      <EventStatusColorSelect
        eventId={id}
        statusColor={payout_status}
        payoutStatus={true}
      />
    );
  }

  const [searchInput, setSearchInput] = useState('');

  const { input } = useSearchInput();
  const currentPage = input.page ? Number(input.page) : 1;
  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  const resetFilters = () => {
    setTrackFilter(null);
    setSeriesFilter(null);
    setAccountFilter(null);
    setStartTransactionDateFilter(null);
    setEndTransactionDateFilter(null);
  };

  return (
    <>
      <EventHeader type={'transaction'} />

      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', width: '97%' }}
        >
          <TitleContainer>
            <Text
              type="heading"
              color={theme.colors.secondary}
              inlineStyle={{
                whiteSpace: 'nowrap',
                display: 'inline-block',
              }}
            >
              Transactions
            </Text>
            <SelectWrapper>
              <EventFilterType
                filterType={filterType}
                setFilterType={setFilterType}
                resetFilters={resetFilters}
              />
              {filterType?.value === 'track' && (
                <EventTrackFilter
                  trackFilter={trackFilter}
                  setTrackFilter={setTrackFilter}
                />
              )}
              {filterType?.value === 'series' && (
                <EventSeriesFilter
                  seriesFilter={seriesFilter}
                  setSeriesFilter={setSeriesFilter}
                />
              )}
              {filterType?.value === 'account' && (
                <EventAccountFilter
                  accountFilter={accountFilter}
                  setAccountFilter={setAccountFilter}
                />
              )}
              {filterType?.value === 'date' && (
                <div style={{ marginLeft: 25 }}>
                  <TransactionDateFilter
                    customStartDate={startTransactionDateFilter}
                    customSetStartDateFilter={setStartTransactionDateFilter}
                    customEndDate={endTransactionDateFilter}
                    customSetEndDateFilter={setEndTransactionDateFilter}
                    customStyle={{ margin: 0 }}
                  />
                </div>
              )}
            </SelectWrapper>
            {filterType?.value !== 'date' && (
              <div
                style={{
                  maxWidth: 300,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SearchInput
                  placeholder={'Search Events'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={searchInput}
                  style={{ marginRight: 15 }}
                />
              </div>
            )}
          </TitleContainer>
        </ContainerHeader>
        <EventsTable
          events={events}
          listType="transactions"
          renderPayoutStatus={renderPayoutStatus}
        />

        <Pagination count={count ?? 0} perPage={15} currentPage={currentPage} />
      </Container>
    </>
  );
});

export default Transactions;
