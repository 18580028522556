import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { debounce } from 'throttle-debounce';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { ResponsiveCol, ResponsiveRow } from '../AddEvents/styles';
import { DESCRIPTION_CHARACTER_LIMIT } from '../EventEdit';

const InputWrapper = styled.div`
  margin-top: 10px;
`;

const CharacterCount = styled.span`
  color: ${(props) => (props.overLimit ? props.theme.colors.error : 'unset')};
  margin-bottom: 6px;
`;

export const BundleTickets = ({
  handleChange,
  COLOR_CODES,
  ticket,
  tickets,
  index,
  ticketType,
  errors,
  allCategories,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [characterCount, setCharacterCount] = useState(0);

  useEffect(() => {
    const allTickets = [
      ...tickets.admin_tickets,
      ...tickets.admin_multiday_tickets,
      ...tickets.admin_other_tickets,
    ];

    //Check if new ticket was added or removed
    const isDeleted = allTickets.length < ticket.associated_tickets.length;
    if (!isDeleted) {
      allTickets.forEach((mTicket, myIndex) => {
        //check if ticket is already associated with bundle
        const associatedTicket = ticket.associated_tickets.find(
          (t) => t.name === mTicket.name
        );

        if (!associatedTicket) {
          const hasTempId = ticket.associated_tickets.find(
            (t) => t.id === myIndex
          );

          if (!hasTempId) {
            const newAssociatedTicket = {
              name: mTicket.name,
              quantity: '',
              id: myIndex,
            };

            handleChange({
              target: {
                name: `associated_tickets`,
                value: [...ticket.associated_tickets, newAssociatedTicket],
              },
            });
          } else {
            //If it has a temporary id, keep track of the name by updatting it
            const newAssociatedTickets = ticket.associated_tickets.reduce(
              (acc, associatedTicket) => {
                if (associatedTicket.id === myIndex) {
                  return [
                    ...acc,
                    {
                      ...associatedTicket,
                      name: mTicket.name,
                      isNew: true,
                    },
                  ];
                } else return [...acc, associatedTicket];
              },
              []
            );

            handleChange({
              target: {
                name: `associated_tickets`,
                value: newAssociatedTickets,
              },
            });
          }
        }
      });
    } else {
      //If ticket was deleted, remove it from associated tickets
      const newAssociatedTickets = ticket.associated_tickets.reduce(
        (acc, associatedTicket) => {
          const isNotDeleted = allTickets.find(
            (t) => t.name === associatedTicket.name
          );

          if (isNotDeleted) {
            return [...acc, associatedTicket];
          } else {
            return acc;
          }
        },
        []
      );

      handleChange({
        target: {
          name: `associated_tickets`,
          value: newAssociatedTickets,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tickets.admin_tickets,
    tickets.admin_multiday_tickets,
    tickets.admin_other_tickets,
  ]);

  return (
    <>
      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            id={`${ticketType}.${index}.name`}
            name={`${ticketType}.${index}.name`}
            error={
              errors[`${ticketType}.${index}.name`]
                ? errors[`${ticketType}.${index}.name`]
                : ''
            }
            label="Bundle Name"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `name`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.name`] = '';
            }}
            value={ticket.name ?? ''}
          />
        </ResponsiveCol>
        <ResponsiveCol>
          <Input
            id={`${ticketType}.${index}.price`}
            name={`${ticketType}.${index}.price`}
            error={
              errors[`${ticketType}.${index}.price`]
                ? errors[`${ticketType}.${index}.price`]
                : ''
            }
            label="Ticket Price"
            type="number"
            placeholder=""
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `price`,
                  value: target.value ? parseFloat(target.value) : '',
                },
              });
              errors[`${ticketType}.${index}.price`] = '';
            }}
            value={ticket.price ?? ''}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      <ResponsiveRow>
        <ResponsiveCol style={{ flex: !isMobile ? '0 0 70%' : '0 0 100%' }}>
          <Input
            as="textarea"
            labelRight={
              <CharacterCount
                overLimit={characterCount > DESCRIPTION_CHARACTER_LIMIT}
              >
                {characterCount} characters
              </CharacterCount>
            }
            rows={4}
            inputStyle={{ minHeight: 'unset' }}
            id={`${ticketType}.${index}.description`}
            name={`${ticketType}.${index}.description`}
            label="Description"
            placeholder=""
            onChange={({ target }) => {
              typeof target.value === 'string' &&
                debounce(300, setCharacterCount(target.value.length));
              handleChange({
                target: {
                  name: `description`,
                  value: target.value,
                },
              });
            }}
            value={ticket.description ?? ''}
          />
        </ResponsiveCol>

        <ResponsiveCol>
          <Select
            id={`${ticketType}.${index}.color_code`}
            name={`${ticketType}.${index}.color_code`}
            error={
              errors[`${ticketType}.${index}.color_code`]
                ? errors[`${ticketType}.${index}.color_code`]
                : ''
            }
            label="Color Code"
            placeholder="Select a Color"
            options={COLOR_CODES}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `color_code`,
                  value: target.value,
                },
              });
              errors[`${ticketType}.${index}.color_code`] = '';
            }}
            value={ticket.color_code}
            backgroundColor={ticket.color_code}
            changeTextColor={true}
          />
          <Select
            id={`${ticketType}.${index}.category_id`}
            name={`${ticketType}.${index}.category_id`}
            error={
              errors[`${ticketType}.${index}.category_id`]
                ? errors[`${ticketType}.${index}.category_id`]
                : ''
            }
            label="Ticket Category"
            placeholder="Category"
            options={allCategories}
            onChange={({ target }) => {
              handleChange({
                target: {
                  name: `category_id`,
                  value: +target.value,
                },
              });
              errors[`${ticketType}.${index}.category_id`] = '';
            }}
            value={ticket.category_id}
          />
        </ResponsiveCol>
      </ResponsiveRow>

      {[
        ...tickets.admin_tickets,
        ...tickets.admin_multiday_tickets,
        ...tickets.admin_other_tickets,
      ].map((mTicket, myIndex) => {
        const quantity =
          ticket.associated_tickets.find((t) => t.name === mTicket.name)
            ?.quantity ?? '';

        return (
          <ResponsiveRow key={myIndex}>
            <ResponsiveCol>
              <InputWrapper style={{ maxWidth: '20%' }}>
                <Input
                  style={{ marginRight: '15px', width: '80px' }}
                  id={`${ticketType}.${index}.quantity`}
                  name={`${ticketType}.${index}.quantity`}
                  label="Qty"
                  type="number"
                  placeholder=""
                  onChange={({ target }) => {
                    const newAssociatedTickets =
                      ticket.associated_tickets.reduce((acc, ticket) => {
                        if (ticket.id === myIndex || ticket.id === mTicket.id) {
                          acc.push({
                            ...ticket,
                            quantity: parseInt(target.value),
                          });
                        } else {
                          acc.push(ticket);
                        }

                        return acc;
                      }, []);

                    handleChange({
                      target: {
                        name: `associated_tickets`,
                        value: newAssociatedTickets,
                      },
                    });
                  }}
                  value={quantity}
                />
              </InputWrapper>
            </ResponsiveCol>
            <ResponsiveCol style={{ minWidth: '65%' }}>
              <InputWrapper style={{ maxWidth: '70%' }}>
                <Input
                  style={{ marginRight: '15px', width: '10rem' }}
                  id={`${ticketType}.${index}.date`}
                  name={`${ticketType}.${index}.date`}
                  label=""
                  type="text"
                  disabled
                  placeholder="Date"
                  value={
                    (moment(mTicket.start_date).format('ddd MMM DD') ?? '') +
                    (mTicket.end_date
                      ? moment(mTicket.start_date).format('DD') !==
                        moment(mTicket.end_date).format('DD')
                        ? ' - ' + moment(mTicket.end_date).format('DD')
                        : ''
                      : '')
                  }
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  style={{ marginRight: '15px' }}
                  id={`${ticketType}.${index}.associative_tickets_name`}
                  name={`${ticketType}.${index}.associative_tickets_name`}
                  label=""
                  type="text"
                  disabled
                  placeholder="Name"
                  value={`${mTicket.name ? mTicket.name : ''} ${
                    mTicket.price ? `- $${mTicket.price}` : ''
                  }`}
                />
              </InputWrapper>
            </ResponsiveCol>
            <Col></Col>
          </ResponsiveRow>
        );
      })}
    </>
  );
};
