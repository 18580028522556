/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import { PinInput } from 'react-input-pin-code';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import useTheme from 'hooks/useTheme';
import Images from 'images';
import { AppContext } from 'shared/AppContext';
import { generateSekretKey } from 'shared/generateSecretKey';
import storage from 'shared/storage';
import 'react-phone-number-input/style.css';
import './../../../index.css';
import RequestCode from '../gql/request-code';
import VerifyCode from '../gql/verify-code';
import HelpLink from './components/HavingTrouble';
import { Logo } from './index';

const StepTwo = ({
  verifyCodeV2,
  phoneNumber,
  setPhoneNumber,
  setStep,
  setShowConfirmation,
  requestCodeV2,
  countryCode,
}) => {
  const theme = useTheme();
  const [verificationCode, setVerificationCode] = useState([
    '',
    '',
    '',
    '',
    '',
    '',
  ]);
  const [error, setError] = useState([]);
  const { dispatch } = useContext(AppContext);
  const [verificationTried, setVerificationTried] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  return (
    <>
      <span
        style={{
          color: 'white',
          fontSize: 25,
          textAlign: 'center',
          display: 'block',
          fontWeight: 'bold',
          fontFamily: 'Barlow Condensed',
          marginBottom: 20,
        }}
      >
        Enter Verification Code
      </span>

      <div
        style={{
          backgroundColor: '#00001d',
          padding: 6,
          borderRadius: 8,
        }}
      >
        <span
          style={{
            fontSize: 18,
            textAlign: 'left',
            display: 'block',
            fontWeight: '500',
            color: '#ccccd2',
            fontFamily: 'Barlow Condensed',
            marginBottom: '30px',
            marginLeft: '5%',
            marginTop: '10px',
          }}
        >
          An SMS code was sent to
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            borderColor: 'white',
            borderWidth: 2,
            borderRadius: 8,
            alignSelf: 'center',
            width: '90%',
            marginLeft: '5%',
            borderStyle: 'solid',
            marginBottom: '30px',
          }}
        >
          <Input
            id="cellphone"
            placeholder="Phone Number"
            fontSize={35}
            fontWeight="500"
            readonly={true}
            disabled={true}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
            }}
            value={phoneNumber}
            inputStyle={{
              border: '1px solid #6c6c79',
              backgroundColor: '#00001d',
              color: 'white',
              padding: 10,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              outline: 'none',
              borderWidth: 0,
              fontFamily: 'Barlow Condensed',
            }}
            errorColor={'darkmagenta'}
          />
          <Logo
            onClick={() => {
              setShowConfirmation(false);
            }}
            src={Images.pen}
            width={250}
          />
        </div>
        <div
          className="verificationContainer"
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            backgroundColor: '#00001d',
            padding: 6,
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
          }}
        >
          <PinInput
            values={verificationCode}
            textContentType="oneTimeCode"
            autoSelect={true}
            size={'lg'}
            onChange={(_value, _index, values) => {
              setVerificationCode(values);
              setVerificationTried(false);
            }}
            onComplete={async (values) => {
              const e = values.join('');
              if (!verificationTried) {
                const secretKey = await generateSekretKey({
                  phone: phoneNumber,
                  calling_code: countryCode,
                });
                const result = await verifyCodeV2(e, secretKey);

                if (!result.errors) {
                  if (result?.data?.verifyCodeV2) {
                    storage.set('user', {
                      ...result.data.verifyCodeV2,
                      role:
                        result.data.verifyCodeV2.role === 'user'
                          ? 'track'
                          : result.data.verifyCodeV2.role,
                    });
                    dispatch({
                      type: 'SET_THEME',
                      theme: 'dark',
                    });
                    setStep('CreateAccount');
                  } else {
                    dispatch({
                      type: 'SET_THEME',
                      theme: 'dark',
                    });
                  }
                } else {
                  setVerificationTried(true);
                  setError(result.errors.code);
                }
              }
            }}
            placeholder={''}
            focusBorderColor={'#FA4616'}
            validBorderColor={'#000011'}
            borderColor={'#000011'}
            inputStyle={
              !isMobile
                ? {
                    color: 'white',
                    fontSize: 30,
                    backgroundColor: '#6D6C7C',
                    width: '4.5rem',
                    height: '5rem',
                    borderRadius: '0.6rem',
                  }
                : {
                    color: 'white',
                    fontSize: 30,
                    backgroundColor: '#6D6C7C',
                    width: '3.2rem',
                    height: '3.6rem',
                    borderRadius: '0.6rem',
                  }
            }
          />
        </div>
        {error.length ? (
          <span
            style={{
              fontSize: 18,
              textAlign: 'center',
              display: 'block',
              fontWeight: '500',
              color: theme.colors.error,
              fontFamily: 'Barlow Condensed',
              marginBottom: '30px',
              marginLeft: '5%',
              marginTop: '10px',
            }}
          >
            {error}
          </span>
        ) : (
          <></>
        )}
        <span
          onClick={async () => {
            setVerificationCode(['', '', '', '', '', '']);
            const secretKey = await generateSekretKey();

            const result = await requestCodeV2(
              {
                phone: phoneNumber,
                calling_code: countryCode,
              },
              secretKey
            );

            if (result?.data?.requestCodeV2) {
              toast.success('SMS Code resent successfully.');
              storage.set('promoter-user-phone', {
                phone: phoneNumber,
                calling_code: countryCode,
              });
              setStep('StepTwo');
            } else {
              toast.error('Error resending SMS code.');
            }
          }}
          style={{
            fontSize: 22,
            textAlign: 'center',
            display: 'block',
            color: theme.colors.primary,
            fontFamily: 'Barlow Condensed Semi',
            marginBottom: '30px',
            marginTop: '10px',
            cursor: 'pointer',
          }}
        >
          Resend Code
        </span>
      </div>

      <Spacer size={50} />
      <HelpLink />
    </>
  );
};

export default VerifyCode(RequestCode(StepTwo));
