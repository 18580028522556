import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export default graphql(
  gql`
    mutation RequestCodeV2($input: RequestCodeInput!) {
      requestCodeV2(input: $input)
    }
  `,
  {
    props: ({ mutate }) => ({
      requestCodeV2: async (input, secretKey) => {
        return mutate({
          variables: { input },
          context: {
            headers: {
              secret_key: secretKey,
            },
          },
        });
      },
    }),
  }
);
