import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { usePersistedQuery } from 'hooks/usePersistedQuery';
import { useSearchInput } from 'hooks/useSearchInput';
import { AppContext } from 'shared/AppContext';
import { useEventYearFilter } from '../Components/EventYearFilter';
import {
  CommonEventFieldsFragment,
  EditTicketFieldsFragment,
  TicketFieldsFragment,
} from './Events.fragments';

export const GET_EVENT = gql`
  query GetEvent($input: GetEventInput!) {
    getEvent(input: $input) {
      ...editTicketFields
    }
  }
  ${EditTicketFieldsFragment}
`;

export function useGetEvent(currentEventId) {
  const { id: idParam } = useParams();
  const { pathname } = useLocation();
  const isTrackEventsView = pathname.includes('/admin/track/events');
  const id =
    currentEventId ?? (!isTrackEventsView && idParam ? Number(idParam) : null);
  return useQuery(GET_EVENT, {
    variables: { input: { id } },
    skip: !id,
    fetchPolicy: 'no-cache',
  });
}

export const GET_EVENT_HEADER_INFO = gql`
  query GetEventHeaderInfo($input: GetEventInput!) {
    getEvent(input: $input) {
      ...commonEventFields
      next_date
      tickets {
        name
        price
        date
      }
      other_tickets {
        name
        price
      }
      isMultiDay
      listDates
      day
      date
      month
    }
  }
  ${CommonEventFieldsFragment}
`;

export function useGetEventHeaderInfo() {
  const { id } = useParams();
  return useQuery(GET_EVENT_HEADER_INFO, {
    variables: { input: { id: Number(id) }, skip: !id },
  });
}

export const GET_EVENTS_ADMIN = gql`
  query GetEventsAdmin($input: GetEventsAdminInput!) {
    getEventsAdmin(input: $input) {
      count
      results {
        ...commonEventFields
        tickets {
          ...ticketFields
        }
        admin_tickets {
          ...ticketFields
        }
        admin_multiday_tickets {
          ...ticketFields
        }
        admin_other_tickets {
          ...ticketFields
        }
        admin_bundle_tickets {
          ...ticketFields
        }
      }
    }
  }
  ${CommonEventFieldsFragment}
  ${TicketFieldsFragment}
`;

/**
 *
 * @param {string} type - 'track' | 'series' | 'events'
 */
export function useGetEventsAdmin(type, options = {}) {
  const {
    yearRegistrationFilter,
    trackRegistrationFilter,
    accountRegistrationFilter,
    seriesRegistrationFilter,
  } = useEventYearFilter();
  const { input: search } = useSearchInput();
  const { id } = useParams();
  const { pathname } = useLocation();

  const variables = {
    input: {
      ...search,
      ...(type === 'track' ? { track_id: Number(id) } : null),
      ...(type === 'series' ? { series_id: Number(id) } : null),
      ...(yearRegistrationFilter && pathname !== '/admin/events'
        ? { year: yearRegistrationFilter }
        : null),
      ...(trackRegistrationFilter
        ? { track_id: trackRegistrationFilter.value }
        : null),
      ...(seriesRegistrationFilter
        ? { series_id: seriesRegistrationFilter.value }
        : null),
      ...(accountRegistrationFilter
        ? { account_id: accountRegistrationFilter.value }
        : null),
    },
  };
  return useQuery(GET_EVENTS_ADMIN, { variables, ...options });
}

export const GET_USER_EVENTS = gql`
  query GetUserEvents($input: GetAccountEventsInput!) {
    getEventsByAccount(input: $input) {
      count
      results {
        ...commonEventFields
        nextGateTime
        tickets {
          name
          price
          date
        }
        other_tickets {
          name
          price
        }
      }
    }
  }
  ${CommonEventFieldsFragment}
`;

export function useGetUserEvents(search, options = {}) {
  const { yearFilter } = useEventYearFilter();
  const { input: routeSearch } = useSearchInput();
  const {
    state: { accountId },
  } = useContext(AppContext);
  const variables = {
    input: {
      ...routeSearch,
      ...(search ? { queryString: search } : null),
      ...(yearFilter ? { year: yearFilter } : null),
      ...(accountId ? { account_id: accountId } : null),
    },
  };

  const result = usePersistedQuery(
    GET_USER_EVENTS,
    `user-events-${routeSearch}-${accountId}-${search}-${yearFilter}`,
    { variables, ...options }
  );

  return { ...result };
}

const GET_ALL_TRACKS = gql`
  query GetAllTracks($input: GetTracksInput!) {
    getTracks(input: $input) {
      id
      name
    }
  }
`;

const GET_ALL_SERIES = gql`
  query GetAllSeries($input: GetSeriesInput!) {
    getSeries(input: $input) {
      id
      name
    }
  }
`;

export function useGetAllTracks() {
  return useQuery(GET_ALL_TRACKS, {
    variables: { input: {} },
  });
}

export function useGetAllSeries() {
  return useQuery(GET_ALL_SERIES, {
    variables: { input: {} },
  });
}
