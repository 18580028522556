import React from 'react';
import Capabilities from 'pages/app/Users/UserEdit/Capabilities';

const capabilityKeys = [
  'account_id',
  'events',
  'guests',
  'add_guests',
  'messages',
  'add_promos',
  'promos',
  'credits',
  'issue_credits',
  'transactions',
  'issue_refunds',
  'registrations',
  'customers',
  'memberships',
  'staff',
  'add_staff',
  'stripe',
  'scan_tickets',
  'check_tickets',
  'create_messages',
  'edit_registrations',
  'check_in_guests',
  'edit_memberships',
  'edit_billing',
  'pos',
  'cancel_event',
];

function CapabilitiesView({ data, accountId }) {
  const viewCapabilities =
    data.me && data.me['capabilities']
      ? data.me['capabilities'].find(
          (capability) => capability.account_id === accountId
        )
      : {};

  return (
    <>
      <Capabilities
        key={accountId}
        mainValue={viewCapabilities}
        values={viewCapabilities}
        index={accountId}
        capabilityKeys={capabilityKeys}
        isView={true}
        handleChange={() => {
          return;
        }}
        handleMultipleChange={() => {
          return;
        }}
      />
    </>
  );
}

export default CapabilitiesView;
