import React from 'react';
import styled from 'styled-components';
import AccountFilter from 'pages/app/AnnualTickets/component/AccountFilter';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-left: 25px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-bottom: 10px;
    padding: 0 20px;
  }
`;

export const EventAccountFilter = ({ accountFilter, setAccountFilter }) => {
  return (
    <FilterContainer>
      <AccountFilter
        accountFilter={accountFilter}
        setAccountFilter={setAccountFilter}
        customStyle={{ marginRight: '0px' }}
      />
    </FilterContainer>
  );
};
