import React, { useState } from 'react';
import Icon from 'components/Icon';
import { NavItemHeader, NavSubItem } from '../StyledAdminLayout';

const SubMenu = ({ item, isTab }) => {
  const [active, setActive] = useState(false);

  return (
    <>
      <NavItemHeader key={item.id} onClick={() => setActive(!active)}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 15, width: 20 }}>
            <Icon icon={item.icon} size={20} color="white" />
          </div>{' '}
          {isTab ? ' ' : item.name}
        </div>
        <div
          style={{
            marginLeft: 25,
            width: 20,
            transform: active ? 'rotate(180deg)' : 'rotate(0deg)',
          }}
        >
          <Icon icon={'down-arrow'} size={17} color="white" />
        </div>{' '}
      </NavItemHeader>
      {active &&
        item.submenu.map((submenu, index) => (
          <NavSubItem exact to={submenu.link} key={index}>
            {submenu.title}
          </NavSubItem>
        ))}
    </>
  );
};

export default SubMenu;
