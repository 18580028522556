import {
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'components/Button';
import { PaymentCard } from 'components/PaymentCard';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { AppContext } from 'shared/AppContext';
import { useAddAccountCard } from '../gql/mutations/useAddAccountCard';
import { StripeFields } from './StripeFields';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PAYMENT_KEY);

function StripeForm(props) {
  return (
    <Elements stripe={stripePromise}>
      <SetupForm {...props} />
    </Elements>
  );
}

const SetupForm = ({ account, refetch, onContinue }) => {
  const successMessage = () => toast.success('Card added successfully');
  const errorToast = () => toast.error('Error Adding Card');

  const [viewAddForm, setViewAddForm] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [zipCode, setZipCode] = useState('');

  const addAccountCard = useAddAccountCard();

  const {
    state: { accountId },
  } = useContext(AppContext);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setLoading(true);

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    const { error, token } = await stripe.createToken(cardNumberElement, {
      address_zip: zipCode,
    });

    if (error) {
      handleError(error);
    } else {
      const response = await addAccountCard({
        token: token.id,
        account_id: onContinue ? account?.id : accountId,
      });

      setLoading(false);

      if (!response || response.errors) {
        errorToast();
        return setErrorMessage(response.errors);
      }

      successMessage();

      if (onContinue) onContinue();
      else refetch();
    }
  };

  const handleAddCard = () => {
    setViewAddForm(true);
  };

  return (
    <div style={{ width: '50%' }}>
      {account?.card?.last4 && !onContinue && (
        <>
          <Text fontSize={20} fontWeight="500" color="#3C4144">
            Credit Card
          </Text>
          <Spacer size={24} />

          <PaymentCard card={account?.card} />

          <Spacer size={24} />

          {!viewAddForm && (
            <Button disabled={loading} onClick={handleAddCard}>
              Add New Card
            </Button>
          )}
        </>
      )}

      {(!account?.card?.last4 || viewAddForm || onContinue) && (
        <>
          {!onContinue && (
            <Text fontSize={20} fontWeight="500" color="#3C4144">
              Add a Card
            </Text>
          )}
          <Spacer size={24} />

          <StripeFields
            handleSubmit={handleSubmit}
            zipCode={zipCode}
            setZipCode={setZipCode}
            errorMessage={errorMessage}
            loading={loading}
            onContinue={onContinue}
          />
        </>
      )}
    </div>
  );
};

export default StripeForm;
