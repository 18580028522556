import { Formik } from 'formik';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import { AppContext } from 'shared/AppContext';
import { useCart } from 'shared/CartContext';
import { useMobileDelivery } from './gql/queries/useMobileDelivery';
import { useTextReceipt } from './gql/queries/useTextReceipt';
import { successMessage } from './PaymentOption';

const formValues = {
  cellphone: '',
  country_code: '',
  calling_code: '',
  send_receipt: true,
};

export const Container = styled.div`
  & .PhoneInput {
    background: ${(props) => props.theme.colors.white};
    padding: 0.4em 0.8em;
    box-sizing: border-box;
    & .PhoneInputInput {
      color: ${(props) => props.theme.colors.secondary};
      background: ${(props) => props.theme.colors.white};
      flex: 1 1;
      outline: none;
      min-width: 0;
      font-family: 'Barlow Condensed';
      font-weight: 600;
      border: 0;
      text-align: center;
      box-shadow: inset 0 0 0 2px #888888;
      border-radius: 8px;
      margin: 6px;
      padding: 10px;
      font-size: 25px;
      max-width: 215px;
    }
    & .PhoneInputInput::placeholder {
      word-spacing: -3px;
      letter-spacing: 3px;
      font-size: 31px;
      font-weight: 800;
    }

    &.PhoneInput--focus {
      border-color: ${(props) => props.theme.colors.primary};
      .PhoneInputInput {
        outline: none;
      }
    }
    .PhoneInputCountry {
      .PhoneInputCountrySelectArrow {
        color: ${(props) => props.theme.colors.primary};
        border-right-width: 3px;
        border-bottom-width: 3px;
      }
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-inline: 20;
  margin-bottom: 30;
  height: 80;
  align-items: center;

  @media (max-width: 445px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    gap: 10px;
  }
`;
const MobileDeliveryModal = ({
  isVisible,
  setIsVisible,
  printReceipt = false,
  showSuccessMessage,
}) => {
  const theme = useTheme();

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 860px)' });

  const { clearTicket, purchaseId, event } = useCart();

  const {
    state: { account },
  } = useContext(AppContext);

  const posSettings =
    event && event?.pos_settings
      ? event?.pos_settings
      : account && account?.pos_settings
      ? account?.pos_settings
      : undefined;

  const showMobilePrint = posSettings
    ? posSettings?.ticket_delivery === 'mobile'
    : false;
  const showTextReceipt = posSettings
    ? posSettings?.receipt_delivery === 'mobile'
    : false;

  const [isPrintReceipt, setIsPrintReceipt] = useState(printReceipt);
  const [printReceiptSelected, setPrintReceiptSelected] = useState(true);

  const [textSent, setTextSent] = useState(false);

  const mobileDelivery = useMobileDelivery();
  const textReceipt = useTextReceipt();

  const resetAndClose = () => {
    // Delay for 1 second to allow the clearTicket function to complete
    setTimeout(() => {
      clearTicket();

      setIsVisible(false);
      setIsPrintReceipt(false);
      setTextSent(false);
    }, 1000);
  };

  useEffect(() => {
    if (showSuccessMessage && isVisible) {
      successMessage();
    }
  }, [showSuccessMessage, isVisible]);

  // Reset values on close
  useEffect(() => {
    if (!isVisible) {
      setIsPrintReceipt(false);
      setPrintReceiptSelected(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !showMobilePrint && showTextReceipt)
      setIsPrintReceipt(true);
    else if (isVisible && !showMobilePrint && !showTextReceipt) resetAndClose();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, showMobilePrint, showTextReceipt]);

  return showMobilePrint || showTextReceipt ? (
    <Modal
      maxWidth={600}
      modalStyle={{
        height: isMobile ? '85vh' : '95vh',
        maxHeight: '95vh',
        padding: 0,
        right: isMobile ? 0 : 20,
        top: isTab ? '30px' : '2.5vh',
        margin: 'auto 0',
        width: isMobile ? '100vw' : '40vw', // Conditional width
        maxWidth: 550, // Disable maxWidth on smaller screens
        minWidth: isMobile ? 350 : null, // Full width on small screens
      }}
      overlayStyle={{
        zIndex: 10,
      }}
      isVisible={isVisible}
      hideModal={resetAndClose}
      noHeader={true}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', paddingBottom: 30 }}
      >
        <div
          style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'space-between',
            marginBottom: 10,
            borderBottom: '1px solid #EAEAEA',
            marginTop: 20,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              fontSize: 26,
              fontWeight: 'bold',
              fontFamily: 'Barlow Condensed',
              paddingBottom: 20,
              marginLeft: 25,
            }}
          >
            {isPrintReceipt ? 'Text Receipt' : 'Mobile Ticket Delivery'}
          </div>
          <div
            style={{
              transform: 'rotate(45deg)',
              display: 'flex',
              justifyContent: 'end',
              marginRight: 20,
            }}
          >
            <Icon
              icon="plus"
              color={theme.colors.primary}
              size={24}
              onClick={resetAndClose}
              style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
            />
          </div>
        </div>

        {showMobilePrint || showTextReceipt ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '80vh',
            }}
          >
            <Formik
              initialValues={formValues}
              validate={(values) => {
                const errors = {};

                if (!values.cellphone) {
                  errors.cellphone = 'Required';
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                try {
                  const result = !isPrintReceipt
                    ? mobileDelivery({
                        cellphone: values.cellphone,
                        calling_code: values.calling_code ?? '+1',
                        purchase_id: +purchaseId?.id,
                        text_receipt: values.send_receipt,
                      })
                    : textReceipt({
                        cellphone: values.cellphone,
                        calling_code: values.calling_code ?? '+1',
                        purchase_id: +purchaseId?.id,
                      });

                  if (result && values.send_receipt) {
                    setTextSent(true);
                    resetForm();
                    resetAndClose();
                  } else if (result) {
                    resetForm();
                    setIsPrintReceipt(true);
                  }
                } catch (e) {
                  console.error(e);
                }
              }}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              {({
                values,
                errors,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                return (
                  <form
                    onSubmit={handleSubmit}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: 20,
                    }}
                  >
                    <FormContainer>
                      <Container theme={theme}>
                        <PhoneInput
                          id="cellphone"
                          placeholder="___ ___ ____"
                          size={25}
                          onBlur={handleBlur}
                          defaultCountry={'US'}
                          onChange={(value) => {
                            setFieldValue('cellphone', value ?? '');
                          }}
                          onCountryChange={(e) => {
                            if (e) {
                              setFieldValue('country_code', e ?? '');
                              setFieldValue(
                                'calling_code',
                                getCountryCallingCode(e) ?? ''
                              );
                            }
                          }}
                          value={values.cellphone}
                          error={errors.cellphone}
                          style={{
                            textAlign: 'center',
                            padding: '0 10px',
                            color: 'white',
                            fontSize: 20,
                            borderRadius: 6,
                            fontFamily: 'Barlow Condensed Semi',
                            borderColor: '#00001d',
                          }}
                        />
                      </Container>

                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        buttonStyle={{
                          maxHeight: 62,
                          backgroundColor: textSent ? '#20B541' : '#fa4616',
                          borderRadius: 8,
                          height: 62,
                          padding: '0 5px',
                        }}
                      >
                        {textSent ? (
                          <div>
                            <Icon
                              icon="check"
                              size={10}
                              color="white"
                              style={{ marginRight: 5 }}
                            />{' '}
                            Sent
                          </div>
                        ) : (
                          <span style={{ fontSize: 20 }}>Send</span>
                        )}
                      </Button>
                    </FormContainer>

                    {!isPrintReceipt && (
                      <div
                        style={{
                          display: 'flex',
                          paddingLeft: '5%',
                          marginBottom: 10,
                        }}
                      >
                        <Checkbox
                          name="checkbox1"
                          checkedIcon={'check1'}
                          checkedBgColor={'#1dbe1d'}
                          checked={values?.send_receipt || false}
                          onChange={() => {
                            setFieldValue(
                              'send_receipt',
                              !values?.send_receipt ?? false
                            );

                            setPrintReceiptSelected(!values?.send_receipt);
                          }}
                          size={[32, 32, 8]}
                          check={values?.send_receipt || false}
                          rightText={'Send receipt to same number.'}
                          myStyle={{ cursor: 'pointer' }}
                          textProps={{
                            style: {
                              fontFamily: 'Barlow Condensed',
                              fontSize: '24px',
                              fontWeight: '700',
                              lineHeight: '34px',
                              letterSpacing: '0px',
                              textAlign: 'center',
                            },
                          }}
                        />
                      </div>
                    )}
                  </form>
                );
              }}
            </Formik>

            <div
              style={{
                paddingInline: 35,
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
              }}
            >
              {showTextReceipt && !(isPrintReceipt || printReceiptSelected) && (
                <Button
                  buttonStyle={{
                    borderRadius: 8,
                    minWidth: '40%',
                    marginInline: 'auto',
                    flex: 1,
                    padding: '10px 0',
                  }}
                  textColor={'#fa4616'}
                  onClick={() =>
                    isPrintReceipt || printReceiptSelected
                      ? setIsVisible(false)
                      : setIsPrintReceipt(true)
                  }
                  outlined
                >
                  <span
                    style={{
                      fontSize: '24px',
                      fontWeight: 700,
                      lineHeight: '34px',
                    }}
                  >
                    {isPrintReceipt || printReceiptSelected
                      ? 'Cancel'
                      : 'Text Receipts'}
                  </span>
                </Button>
              )}

              <Spacer size={5} />

              <Button
                buttonStyle={{
                  borderRadius: 8,
                  minWidth:
                    isPrintReceipt || printReceiptSelected ? '70%' : '40%',
                  marginInline: 'auto',
                  flex: 1,
                  padding: '13px 0',
                }}
                onClick={() =>
                  isPrintReceipt || printReceiptSelected || !showTextReceipt
                    ? resetAndClose()
                    : setIsPrintReceipt(true)
                }
                outlined
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  {isPrintReceipt || printReceiptSelected || !showTextReceipt
                    ? 'New Transaction'
                    : 'Skip'}
                </span>
              </Button>
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </Modal>
  ) : (
    <Fragment />
  );
};

export default MobileDeliveryModal;
