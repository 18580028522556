import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';

export default graphql(
  gql`
    mutation CreateReader($input: CreateReaderInput!) {
      createReader(input: $input) {
        id
        serial_number
        label
        reader_id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      createReader: async (input) => {
        return mutator(() =>
          mutate({
            variables: { input },
          })
        );
      },
    }),
  }
);
