import React, { createContext, useContext, useState } from 'react';

const FilterContext = createContext();

const startDate = '',
  endDate = '';

export const TransactionFilterProvider = ({ children }) => {
  const [sortBy, setSortBy] = useState(null);
  const [source, setSource] = useState(null);

  const [userType, setUserType] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [deliveryMethod, setDeliveryMethod] = useState(null);

  const [startDateFilter, setStartDateFilter] = useState(startDate);
  const [endDateFilter, setEndDateFilter] = useState(endDate);

  return (
    <FilterContext.Provider
      value={{
        sortBy,
        setSortBy,
        source,
        setSource,
        userType,
        setUserType,
        startDateFilter,
        setStartDateFilter,
        endDateFilter,
        setEndDateFilter,
        paymentMethod,
        setPaymentMethod,
        deliveryMethod,
        setDeliveryMethod,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useTransactionFilter = () => {
  const context = useContext(FilterContext);
  if (context === undefined) {
    throw new Error(
      'useTransactionFilter must be used within a TransactionFilterProvider'
    );
  }
  return context;
};
