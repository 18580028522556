import React from 'react';
import styled from 'styled-components';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';

const Container = styled.div`
  padding: 20px;
  justify-content: center;
  align-items: center;
`;

export const PrintingModal = ({ isVisible }) => {
  return (
    <Modal
      isVisible={isVisible}
      title={`Printing in progress...`}
      maxWidth={600}
      closable={false}
    >
      <Container>
        <Loading size={30} />
      </Container>
    </Modal>
  );
};
