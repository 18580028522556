import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkbox } from 'components/Form/Checkbox';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { ResponsiveCol } from 'pages/app/Events/AddEvents/styles';

const Container = styled.div`
  padding-block: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Default 3 columns */
  gap: 20px; /* Adjust gap between grid items */

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* 2 columns on tablets and mobile */
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr; /* Stack items in a single column on very small screens */
  }
`;

export const PointOfSaleSettings = ({
  handleChange,
  handleBlur,
  mainValues,
  handleMainChange,
}) => {
  const values = mainValues.pos_settings;
  const theme = useTheme();

  return (
    <Container>
      <ResponsiveCol>
        <Text
          type="heading"
          as="h1"
          color={theme.colors.text.header}
          inlineStyle={{
            whiteSpace: 'nowrap',
            fontSize: 20,
          }}
        >
          Accepted Payment Methods
        </Text>

        <Spacer size={18} />
        <Checkbox
          name="both"
          checked={values?.payment === 'both'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'payment',
                  value: 'both',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Both"
        />

        <Spacer size={18} />
        <Checkbox
          name="cash"
          checked={values?.payment === 'cash'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'payment',
                  value: 'cash',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Cash"
        />

        <Spacer size={18} />
        <Checkbox
          name="card"
          checked={values?.payment === 'card'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'payment',
                  value: 'card',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Card"
        />
      </ResponsiveCol>

      <ResponsiveCol>
        <Text
          type="heading"
          as="h1"
          color={theme.colors.text.header}
          inlineStyle={{
            whiteSpace: 'nowrap',
            fontSize: 20,
          }}
        >
          Show Cash Change Calculator
        </Text>

        <Spacer size={18} />
        <Checkbox
          name="yes"
          checked={values?.calculator === true}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'calculator',
                  value,
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Yes"
        />

        <Spacer size={18} />
        <Checkbox
          name="no"
          checked={values?.calculator === false}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'calculator',
                  value: false,
                },
              });
          }}
          onBlur={handleBlur}
          rightText="No"
        />
      </ResponsiveCol>

      <ResponsiveCol>
        <Text
          type="heading"
          as="h1"
          color={theme.colors.text.header}
          inlineStyle={{
            whiteSpace: 'nowrap',
            fontSize: 20,
          }}
        >
          Ticket Delivery
        </Text>

        <Spacer size={18} />
        <Checkbox
          name="mobile"
          checked={values?.ticket_delivery === 'mobile'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'ticket_delivery',
                  value: 'mobile',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Mobile Delivery"
        />

        <Spacer size={18} />
        <Checkbox
          name="print"
          checked={values?.ticket_delivery === 'print'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'ticket_delivery',
                  value: 'print',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Print from POS"
        />

        <Spacer size={18} />
        <Checkbox
          name="physical"
          checked={values?.ticket_delivery === 'physical'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'ticket_delivery',
                  value: 'physical',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Physical Tickets"
        />
      </ResponsiveCol>

      <ResponsiveCol>
        <Text
          type="heading"
          as="h1"
          color={theme.colors.text.header}
          inlineStyle={{
            whiteSpace: 'nowrap',
            fontSize: 20,
          }}
        >
          Receipt Delivery
        </Text>

        <Spacer size={18} />
        <Checkbox
          name="mobile"
          checked={values?.receipt_delivery === 'mobile'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'receipt_delivery',
                  value: 'mobile',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Mobile Delivery"
        />

        <Spacer size={18} />
        <Checkbox
          name="print"
          checked={values?.receipt_delivery === 'print'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'receipt_delivery',
                  value: 'print',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Print from POS"
        />

        <Spacer size={18} />
        <Checkbox
          name="print_w_tickets"
          checked={values?.receipt_delivery === 'print_w_tickets'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'receipt_delivery',
                  value: 'print_w_tickets',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="Print from POS w/ Tickets"
        />

        <Spacer size={18} />
        <Checkbox
          name="none"
          checked={values?.receipt_delivery === 'none'}
          onChange={(event) => {
            const value = event.target?.checked;
            if (value)
              handleChange({
                target: {
                  name: 'receipt_delivery',
                  value: 'none',
                },
              });
          }}
          onBlur={handleBlur}
          rightText="None"
        />
      </ResponsiveCol>

      {handleMainChange && (
        <ResponsiveCol>
          <Text
            type="heading"
            as="h1"
            color={theme.colors.text.header}
            inlineStyle={{
              whiteSpace: 'nowrap',
              fontSize: 20,
            }}
          >
            Default Fee Settings
          </Text>

          <Spacer size={18} />
          <Input
            id="pos_fee_dollar"
            name="pos_fee_dollar"
            label="Dollar Amount (POS)"
            onChange={handleMainChange ? handleMainChange : ''}
            onBlur={handleBlur ? handleBlur : ''}
            value={mainValues?.pos_fee_dollar ?? ''}
          />

          <Spacer size={18} />
          <Input
            id="pos_fee_percent"
            name="pos_fee_percent"
            label="Fee Percent (POS)"
            onChange={handleMainChange ? handleMainChange : ''}
            onBlur={handleBlur ? handleBlur : ''}
            value={mainValues?.pos_fee_percent ?? ''}
          />
        </ResponsiveCol>
      )}
    </Container>
  );
};
