import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetUser($id: Int!) {
    getUser(id: $id) {
      id
      name
      first_name
      middle_name
      last_name
      email
      role
      cellphone
      country_code
      calling_code
      address_1
      address_2
      city
      state
      zipcode
      birthday
      expiration
      stripe_account_id
      suffix
      inactive
      associations
      accounts {
        id
        business_dba
      }
      city
      state
      zipcode
      address_line_1
      selfie_image
      temp_image_one
      temp_image_two
      created
      parents {
        first_name
        last_name
        cellphone
        user_id
      }
      capabilities {
        account_id
        events
        guests
        add_guests
        messages
        add_promos
        promos
        credits
        issue_credits
        transactions
        issue_refunds
        registrations
        customers
        memberships
        staff
        add_staff
        stripe
        scan_tickets
        check_tickets
        create_messages
        edit_registrations
        check_in_guests
        edit_memberships
        edit_billing
        pos
        cancel_event
      }
      scanner_id
    }
  }
`;

export default graphql(query, {
  options: ({ currentUser, match }) => {
    return {
      variables: {
        id: currentUser ? currentUser : match ? Number(match.params.id) : null,
      },
      fetchPolicy: 'cache-and-network',
    };
  },
});
