import React from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon';

const CountdownDiv = styled.div`
  border: 2px solid white;
  border-radius: 8px;
  background-color: #000033;
  color: white;
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100px;
  justify-content: center;
  align-items: center;
  max-height: 40px;

  //styleName: PP - H4;
  font-family: Barlow Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
`;

const TimerText = styled.div`
  //styleName: PP - H4;
  font-family: Barlow Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  text-align: center;
`;

export const TimerCountdown = ({ timer }) => {
  return (
    <CountdownDiv>
      <Icon
        icon="access_time"
        color="white"
        size={24}
        customStyle={{ marginBottom: 5 }}
      />
      <TimerText>{`${Math.floor(timer / 60000)}:${String(
        Math.floor((timer % 60000) / 1000)
      ).padStart(2, '0')}`}</TimerText>
    </CountdownDiv>
  );
};
