import { Formik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { Input } from 'components/Form/Input';
import Spacer from 'components/Spacer';
import CreateReader from './gql/CreateReader';

const formValues = ['serial_number'];

export const AddReaderForm = compose(CreateReader)(
  ({
    isVisible,
    handleOutClick,
    createReader,
    account_id,
    readers,
    setFieldValue,
  }) => {
    const successMessage = () => toast.success('Reader Created');
    const errorMessage = () => toast.error('Error Creating Reader');

    function validate(values) {
      const errors = {};
      if (!values.serial_number) {
        errors.serial_number = 'Required';
      }

      return errors;
    }

    const initialValues = formValues.reduce((formValues, value) => {
      const initialValue = '';

      return {
        ...formValues,
        [value]: initialValue,
      };
    }, {});

    async function handleSubmit(
      values,
      { setSubmitting, setErrors, resetForm }
    ) {
      setSubmitting(true);

      const data = Object.entries(values).reduce((formValues, [key, value]) => {
        return {
          ...formValues,
          ...(value ? { [key]: value } : null),
        };
      }, {});

      const response = await createReader({
        serial_number: data.serial_number,
        account_id: parseInt(account_id),
      });

      setSubmitting(false);

      if (!response || response.errors) {
        errorMessage();
        return setErrors(response.errors);
      } else {
        const newReaders = [...readers, response.data.createReader];
        setFieldValue('readers', newReaders);

        successMessage();
        resetForm();
        handleOutClick();
      }
    }

    return (
      <Drawer
        title={'Add Reader'}
        isVisible={isVisible}
        handleOutClick={handleOutClick}
      >
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DrawerPadding border>
                  <Spacer size={18} />
                  <Input
                    id="serial_number"
                    label="Reader Serial Number"
                    placeholder="Serial Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.serial_number}
                    error={
                      errors.serial_number &&
                      touched.serial_number &&
                      errors.serial_number
                    }
                  />
                </DrawerPadding>

                <DrawerPadding>
                  <Button type="submit" disabled={isSubmitting} block>
                    {'Add Reader'}
                  </Button>
                </DrawerPadding>
              </form>
            );
          }}
        </Formik>
      </Drawer>
    );
  }
);
