import { graphql } from '@apollo/react-hoc';
import { gql } from 'graphql-tag';
import mutator from 'mutator';

export default graphql(
  gql`
    mutation verifyCodeV2($input: VerifyCodeInput!) {
      verifyCodeV2(input: $input) {
        id
        cellphone
        email
        jwt
        first_name
        last_name
        role
        address
        ownership {
          tracks {
            id
            name
          }
          series {
            id
            name
          }
        }
        track {
          id
        }
        series {
          id
        }
        message_center
        registrations
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      verifyCodeV2: async (code, secretKey) => {
        return mutator(() =>
          mutate({
            variables: { input: { code: code, token: secretKey } },
          })
        );
      },
    }),
  }
);
