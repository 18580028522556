import { SeatsioEventManager } from '@seatsio/seatsio-react';
import React from 'react';

export default function EventManager({
  seatId,
  customStyle,
  mode,
  onObjectSelected,
  onObjectDeselected,
}) {
  return (
    // <div style={customStyle ? customStyle : { height: '90vh' }}>
    <SeatsioEventManager
      secretKey={process.env.REACT_APP_SEAT_WORKSPACE_SECRET}
      event={seatId ?? ''}
      region="na"
      mode={mode}
      onObjectSelected={onObjectSelected}
      onObjectDeselected={onObjectDeselected}
      fitTo="widthAndHeight"
      showSearchButton={false}
      viewSettingsDefaults={{
        showSeatLabels: true,
        showRowLabels: true,
      }}
    />
    // </div>
  );
}
