import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'components/Button';
import { useGetParticipantsDownload } from 'components/Participants/gql/useGetParticipantsDownload';
import useNewPurchase from 'hooks/useNewPurchase';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';

const HeaderButtons = ({ event, date }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  const { data, subscribeToMore } = useGetParticipantsDownload();
  useNewPurchase({ subscribeToMore }, event.id, '', date);

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  if (!data || !data.getParticipantsList) return null;

  const handleClickScan = (id, date) => {
    pathname.includes('/admin-track/') || pathname.includes('/admin-employee/')
      ? history.push(`/admin-${userType}/scan/${id}?date=${date}`)
      : history.push(`/admin/scan/${id}?date=${date}`);
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Button
        buttonStyle={{ width: 165, marginRight: 10 }}
        onClick={
          !capabilities ||
          capabilities.length === 0 ||
          capabilities?.scan_tickets
            ? () => handleClickScan(event.id, date)
            : () => setShowPermissionModal(true)
        }
      >
        {process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'SCAN PARTICIPANTS'
          : 'SCAN TICKETS'}
      </Button>

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/1`}
        history={history}
      />
    </div>
  );
};

export default HeaderButtons;
