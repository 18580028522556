import moment from 'moment';

export function formatTimestamp(timestamp, format, unix = false) {
  const date = unix ? moment(timestamp) : moment.unix(timestamp);

  const utc = moment.utc(date);

  return utc.local().format(format);
}

const getTimeZoneAbbrivation = (timezone) => {
  switch (timezone) {
    case 'America/New_York':
      return 'EST';
    case 'America/Chicago':
      return 'CST';
    case 'America/Los_Angeles':
      return 'PST';
    case 'America/Denver':
      return 'PST';
    default:
      return 'EST';
  }
};

export const formatEventTimestamp = (timeStamp, timezone) => {
  const time = moment(timeStamp, 'HH:mm:ss').format('h:mm A');
  const timezone_abr = getTimeZoneAbbrivation(timezone);
  return `${time} ${timezone_abr}`;
};

export function formatCustomDate(date, format) {
  return moment(date, format);
}

/**
 * @param {string} [n] phoneNumber
 * @return {string} a formatted phone number
 */
export function formatPhoneNumber(phoneNumber) {
  // Filter out phone numbers with country codes other than +1
  if (
    !phoneNumber ||
    phoneNumber.startsWith('+1-') ||
    (phoneNumber.startsWith('+') && !phoneNumber.startsWith('+1'))
  ) {
    return phoneNumber;
  }
  // It's a US/Canada number; let's format it.
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return ['+1 (', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}
