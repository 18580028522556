import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useSearchInput } from 'hooks/useSearchInput';

export const SPECTATOR_REPORT = gql`
  mutation SpectatorReportMutation($spectatorInput: GetParticipantsList!) {
    getSpectatorReport(input: $spectatorInput) {
      event {
        name
        month
        day
        date
        isMultiDay
        start_date
        end_date
        track {
          name
        }
      }
      total_sales
      total_tickets
      data {
        tickets {
          ticket {
            name
          }
          holder
          is_checked
          refunded
          price
          barcode
        }
        purchaser
      }
    }
  }
`;

export function useGetSpectatorReport(event_date) {
  const [spectatorMutation] = useMutation(SPECTATOR_REPORT);
  const { input } = useSearchInput();
  const { date } = input;

  const result = useCallback(
    (eventId) => {
      return spectatorMutation({
        variables: {
          spectatorInput: {
            event_id: Number(eventId),
            date: event_date ? event_date : date,
          },
        },
      });
    },
    [spectatorMutation, date, event_date]
  );
  return result;
}
