import React, { useState } from 'react';
import styled from 'styled-components';
import ContainerHeader from 'components/ContainerHeader';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { Person } from 'components/Transactions/TransactionModal/Person';
import { TitleContainer } from 'pages/track/Contact/style';

const MainContainer = styled.div`
  padding: 10px 30px;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const MemberDetail = ({ transaction, location }) => {
  const [selectedPeople, setSelectedPeople] = useState([]);

  const employeeAdmin = location.pathname.includes('/admin-employee/');
  return (
    <MainContainer>
      <ContainerHeader style={{ paddingLeft: 0, height: 60 }}>
        <TitleContainer>
          <Text
            type="heading"
            color="#3C4144"
            inlineStyle={{ marginRight: 25 }}
          >
            Tickets
          </Text>
        </TitleContainer>
      </ContainerHeader>
      <div style={{ marginTop: 20 }}>
        {transaction.users && transaction.users.length
          ? transaction.users.map((person, index) => (
              <Person
                key={person.id ?? index}
                person={person}
                setSelectedPeople={() => setSelectedPeople(person.id ?? index)}
                selectedPeople={selectedPeople}
                index={index}
                employeeAdmin={employeeAdmin}
              />
            ))
          : null}
      </div>
      <Spacer size={20} />
    </MainContainer>
  );
};

export default MemberDetail;
