import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const REFUND_EVENT_V2 = gql`
  mutation RefundEventV2($input: EventRefundInput!) {
    refundEventV2(input: $input)
  }
`;

export function useRefundEventV2() {
  const [refendEventV2Mutation] = useMutation(REFUND_EVENT_V2);

  const { id } = useParams();

  const result = useCallback(
    (data) => {
      return refendEventV2Mutation({
        variables: { input: { event_id: +id, options: data } },
        skip: !data,
      });
    },
    [id, refendEventV2Mutation]
  );
  return result;
}
