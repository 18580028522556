import React, { useState } from 'react';
import Icon from 'components/Icon';

const ToolTip = ({
  info,
  content,
  children,
  maxWidth,
  iconColor,
  iconSize,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <span
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'relative',
        display: 'flex',
        cursor: 'pointer',
        justifyContent: 'center',
      }}
    >
      {info && (
        <Icon
          icon="info"
          color={iconColor ?? '#00001f'}
          size={iconSize ?? '20px'}
          style={{ marginLeft: '5px' }}
        />
      )}
      {children}
      {showTooltip && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'white',
            color: 'black',
            padding: '15px',
            borderRadius: '4px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            maxWidth: maxWidth ? maxWidth : '350px',
            minWidth: '100px',
            width: '160px',
            lineHeight: '1.5',
            top: '120%',
            left: '50%',
            transform: 'translateX(3%) translateY(10%)',
            zIndex: 1000,
          }}
        >
          {content}
        </div>
      )}
    </span>
  );
};

export default ToolTip;
