import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const TEXT_RECEIPT = gql`
  mutation textReceipt($input: TextReceiptInput!) {
    textReceipt(input: $input)
  }
`;

export function useTextReceipt() {
  const [textReceiptMutation] = useMutation(TEXT_RECEIPT);

  const textReceipt = useCallback(
    (input) => {
      return textReceiptMutation({
        variables: {
          input: input,
        },
        fetchPolicy: 'no-cache',
        skip: !input.cellphone,
      });
    },
    [textReceiptMutation]
  );

  return textReceipt;
}
