import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Anchor from 'components/Anchor';
import Icon from 'components/Icon';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  TransactionModal,
  useGetTrackTransactionsGetEvent,
} from 'components/Transactions';
import { withTransactionDateFilterContext } from 'components/Transactions/TransactionDateFilter';
import useTheme from 'hooks/useTheme';
import { formatTimestamp, formatPhoneNumber } from 'shared/formatters';
import { is_cellphone } from '../../app/Users';

const ParticipantList = styled.div`
  background-color: #fff;
  border-radius: 5px;
  height: 100%;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;
  padding: 10px;
  box-sizing: border-box;
`;

const ParticipantRow = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
`;

const RowText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin-right: 25px;
`;

const Wrapper = styled.div`
  @media (max-width: 768px) {
    ${(props) =>
      props.currentTransaction
        ? `  position: fixed;
    width: 100%;
    overflow: hidden;`
        : ''};
  }
`;

export const List = withTransactionDateFilterContext((props) => {
  const { match, location, currentPage } = props;
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const theme = useTheme();
  const { data, loading } = useGetTrackTransactionsGetEvent(currentPage);

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  if (!data?.getEvent && !data?.getTrackTransactionsV2) return false;

  return (
    <Wrapper currentTransaction={currentTransaction}>
      <ParticipantList>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text type="heading">Transactions</Text>
        </div>
        <Spacer size={10} />
        {!data?.getTrackTransactionsV2.results.length ? (
          <p
            style={{
              color: '#000',
              fontSize: 20,
              fontFamily: 'Barlow Condensed',
              fontWeight: 600,
              padding: '20px 0',
            }}
          >
            The event does not have any transactions yet
          </p>
        ) : null}
        {data?.getTrackTransactionsV2.results.map((transaction, index) => {
          const { purchase_date, user } = transaction;

          return (
            <Anchor
              to={
                'admin' === userType
                  ? `/admin/transactions/detail/${transaction.id}`
                  : `/admin-${userType}/transactions/detail/${transaction.id}`
              }
            >
              <ParticipantRow
                onClick={() => setCurrentTransaction(transaction)}
                key={index}
              >
                <RowText>
                  <Text
                    inlineStyle={{
                      fontWeight: '500',
                      fontSize: 18,
                    }}
                  >
                    {user
                      ? is_cellphone(user.last_name)
                        ? formatPhoneNumber(user.last_name)
                        : `${user.last_name}, ${user.first_name}`
                      : ''}
                  </Text>
                  {purchase_date && (
                    <Text inlineStyle={{ fontSize: 16 }}>
                      {formatTimestamp(
                        Number(transaction.purchase_date),
                        'MMM DD - YYYY h:mm A',
                        true
                      ).toUpperCase()}
                    </Text>
                  )}
                </RowText>
                <div style={{ transform: 'rotate(-90deg)' }}>
                  <Icon icon="chevron" color={theme.colors.primary} size={22} />
                </div>
              </ParticipantRow>
            </Anchor>
          );
        })}
      </ParticipantList>
      {currentTransaction ? (
        <TransactionModal
          location={location}
          match={match}
          employeeAdmin={location.pathname.includes('/admin-employee/')}
          admin={location.pathname.includes('/admin/')}
          currentTransaction={currentTransaction}
          close={() => {
            setCurrentTransaction(null);
          }}
        />
      ) : null}
      <Spacer size={10} />
      <div style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}>
        <Pagination
          count={loading ? 0 : data?.getTrackTransactionsV2.count}
          perPage={15}
          currentPage={currentPage || 1}
          color={'#fff'}
        />
      </div>
    </Wrapper>
  );
});
