import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import { PaymentOption } from './PaymentOption';

const ModalTitle = styled.div`
  //styleName: PP - H2;
  font-family: Barlow Condensed;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  justify-content: start;
  padding-bottom: 20px;
  margin-left: 25px;
`;

const SeatsSectionTitle = styled.span`
  font-family: Barlow Condensed;
  font-size: 28px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
`;

const SeatsRowContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bbbbbb;
  padding-bottom: 10px;
`;

const SeatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: Roboto;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  padding: 10px 0;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  margin-top: 30px;
  padding: '0 10px 10px 10px';
`;

export const SeatSelectionModal = ({
  isVisible,
  setIsVisible,
  selectedSeatObject,
  onClose,
}) => {
  const theme = useTheme();

  const isTab = useMediaQuery({ query: '(max-width: 1190px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 860px)' });

  const [isCheckoutVisible, setIsCheckoutVisible] = useState(false);

  const handleCheckout = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <Modal
      maxWidth={600}
      overlayStyle={{
        zIndex: 11,
      }}
      modalStyle={{
        height: isMobile ? '85vh' : '93vh',
        maxHeight: '93vh',
        padding: 0,
        right: isMobile ? 0 : 20,
        margin: 'auto 0',
        top: isTab ? '30px' : '2.5vh',
        width: isMobile ? '100vw' : isTab ? '50vw' : '40vw',
        maxWidth: 550, // Disable maxWidth on smaller screens
        minWidth: isMobile ? 350 : null, // Full width on small screens
        zIndex: 8001,
      }}
      isVisible={isVisible}
      hideModal={() => setIsVisible(false)}
      noHeader={true}
    >
      <div
        style={{ display: 'flex', flexDirection: 'column', paddingBottom: 30 }}
      >
        <div
          style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'space-between',
            marginBottom: 10,
            borderBottom: '1px solid #EAEAEA',
            marginTop: 20,
          }}
        >
          <ModalTitle>Your selection</ModalTitle>
          <div
            style={{
              transform: 'rotate(45deg)',
              display: 'flex',
              justifyContent: 'end',
              marginRight: 20,
            }}
          >
            <Icon
              icon="plus"
              color={theme.colors.primary}
              size={24}
              onClick={() => setIsVisible(false)}
              style={{ transform: 'rotate(45deg)', fontWeight: 'bold' }}
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: isMobile ? '67vh' : '80vh',
          }}
        >
          {Object.keys(selectedSeatObject).length > 0 ? (
            <div
              style={{
                padding: '0 25px 25px',
                maxHeight: '70vh',
                overflowY: 'auto',
              }}
            >
              {Object.keys(selectedSeatObject).map((key, index) => {
                return (
                  <div key={index}>
                    <div style={{ textAlign: 'start', marginTop: 20 }}>
                      <SeatsSectionTitle>{key}</SeatsSectionTitle>
                    </div>
                    <SeatsRowContainer>
                      {selectedSeatObject[key]?.map((seat, index) => {
                        return (
                          <SeatRow
                            style={{
                              borderBottom:
                                index + 1 === selectedSeatObject[key].length
                                  ? ''
                                  : '1px solid #eaeaea',
                            }}
                            key={index}
                          >
                            <span>Row: {seat.labels.parent}</span>
                            <span>Seat: {seat.labels.own}</span>
                          </SeatRow>
                        );
                      })}
                    </SeatsRowContainer>
                  </div>
                );
              })}
            </div>
          ) : (
            <SeatsSectionTitle style={{ textAlign: 'center', padding: 25 }}>
              Seats not selected
            </SeatsSectionTitle>
          )}

          <ButtonRow>
            <div
              style={{
                borderRadius: 8,
                border: '2px solid #fa4616',
                minWidth: '40%',
              }}
            >
              <Button
                buttonStyle={{
                  backgroundColor: 'white',
                  borderRadius: 8,
                  minWidth: '40%',
                  flex: 1,
                  minHeight: 80,
                }}
                textColor={'#fa4616'}
                onClick={() => setIsVisible(false)}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  Cancel
                </span>
              </Button>
            </div>
            <div
              style={{
                minWidth: '40%',
                backgroundColor: !selectedSeatObject ? '#ccc' : '#fa4616',
                borderRadius: 8,
              }}
            >
              <Button
                textColor={'white'}
                buttonStyle={{
                  minWidth: '40%',
                  flex: 1,
                  minHeight: 80,
                }}
                disabled={!selectedSeatObject}
                onClick={handleCheckout}
              >
                <span
                  style={{
                    fontSize: '24px',
                    fontWeight: 700,
                    lineHeight: '34px',
                  }}
                >
                  Confirm
                </span>
              </Button>
            </div>
          </ButtonRow>
        </div>
      </div>

      <PaymentOption
        isVisible={isCheckoutVisible}
        setIsVisible={setIsCheckoutVisible}
      />
    </Modal>
  );
};
