import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_ACCOUNT = gql`
  query GetAccount($id: Int!) {
    getAccount(id: $id) {
      id
      readers {
        id
        serial_number
        label
        reader_id
      }
    }
  }
`;

export function useGetReaders(currentAccount) {
  const { data, loading, error } = useQuery(GET_ACCOUNT, {
    variables: { id: currentAccount },
    fetchPolicy: 'no-cache',
    skip: !currentAccount,
  });
  return { data, loading, error };
}
