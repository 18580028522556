import { useSubscription } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useEffect } from 'react';

export const PAYMENT_SUBSCRIPTION = gql`
  subscription PosPurchaseConfirmation($input: String!) {
    posPurchaseConfirmation(payment_intent: $input) {
      payment_intent
      status
    }
  }
`;

export function usePaymentSubscription(paymentIntent, onData) {
  const { data, loading } = useSubscription(PAYMENT_SUBSCRIPTION, {
    variables: { input: paymentIntent },
    skip: !paymentIntent,
  });

  useEffect(() => {
    if (!loading && data) {
      onData(data);
    }
  }, [data, loading, onData]);

  return data;
}
