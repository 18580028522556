import React, { useState } from 'react';
import { Row } from 'react-grid-system';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled, { useTheme } from 'styled-components';
import { Button } from 'components/Button';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';
import DeleteReader from '../AddReader/gql/DeleteReader';

const Text = styled.p`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 400;
  display: block;
`;

const Column = styled.div`
  width: 25%;
  display: flex;
  padding-right: 5px;
`;

const successMessage = () => toast.success('Reader deleted successfully');
const errorMessage = () => toast.error('Error deleting Reader');

const Readers = ({ reader, deleteReader, readers, setFieldValue }) => {
  const { label, reader_id, serial_number, id } = reader;

  const theme = useTheme();
  const [showConfirmation, setShowConfirmation] = useState(false);

  return (
    <>
      <Spacer size={10} />
      <Row style={{ margin: 0 }}>
        <Column>
          <Text>{label}</Text>
        </Column>
        <Column>
          <Text>{reader_id}</Text>
        </Column>
        <Column>
          <Text>{serial_number}</Text>
        </Column>
        <Row>
          <div style={{ marginLeft: '20px', width: '10%' }}>
            <Icon
              icon="trash"
              size={18}
              color={theme.colors.primary}
              style={{ margin: '0 10px' }}
              onClick={async () => {
                setShowConfirmation(true);
              }}
            />
          </div>
        </Row>
      </Row>
      <Modal
        isVisible={showConfirmation}
        hideModal={() => setShowConfirmation(false)}
        title={'Are you sure you want to remove this reader?'}
        maxWidth={600}
      >
        <Spacer size={20} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            width: '50%',
            margin: 'auto',
          }}
        >
          <Button type="button" onClick={() => setShowConfirmation(false)}>
            Cancel
          </Button>
          <Button
            type="button"
            onClick={async () => {
              setShowConfirmation(false);

              const response = await deleteReader(id);

              if (!response || response.errors) {
                errorMessage();
              } else {
                const newReaders = readers.filter(
                  (member) => member.id !== reader.id
                );
                setFieldValue('readers', newReaders);
                successMessage();
              }
            }}
          >
            Okay
          </Button>
        </div>
        <Spacer size={20} />
      </Modal>
    </>
  );
};

export default compose(DeleteReader)(Readers);
