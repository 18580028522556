import React from 'react';
import styled from 'styled-components';
import Icon from 'components/Icon';
import Spacer from 'components/Spacer';

const Container = styled.div`
  height: 89vh;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  justify-content: center;
`;

const EmptyText = styled.span`
  //styleName: PP - H2;
  font-family: Barlow Condensed;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: center;
`;

const AddText = styled.span`
  //styleName: PP - Body;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  max-width: 60%;
  color: #d1d1d1;
`;

export const EmptyCart = () => {
  return (
    <Container>
      <Icon icon="shopping_cart" size={128} color={'#fff'} />

      <Spacer size={15} />
      <EmptyText>Empty Cart</EmptyText>
      <AddText>Add items by selecting options in the left columns.</AddText>
    </Container>
  );
};
