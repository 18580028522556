import { graphql } from '@apollo/react-hoc';
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const query = gql`
  query GetEmployeeV2($id: Int!) {
    getEmployeeV2(id: $id) {
      id
      first_name
      last_name
      email
      cellphone
      country_code
      calling_code
      role
      middle_name
      suffix
      accounts {
        id
        business_dba
      }
      capabilities {
        account_id
        events
        guests
        add_guests
        messages
        add_promos
        promos
        credits
        issue_credits
        transactions
        issue_refunds
        registrations
        customers
        memberships
        staff
        add_staff
        stripe
        scan_tickets
        check_tickets
        create_messages
        edit_registrations
        check_in_guests
        edit_memberships
        edit_billing
        pos
        cancel_event
      }
    }
  }
`;

export function useGetEmployee(id, options) {
  return useQuery(query, {
    variables: { id: id },
    ...options,
    fetchPolicy: 'no-cache',
    skip: !id,
  });
}

export default graphql(query, {
  options: ({ currentEmployee }) => {
    return {
      variables: { id: currentEmployee ? currentEmployee : 0 },
      fetchPolicy: 'no-cache',
    };
  },
});
