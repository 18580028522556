import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const query = gql`
  query GetLapSponsorshipReport($input: EventRefundInput!) {
    calculateEventRefundFees(input: $input)
  }
`;

export function useCalculateEventRefundFees(fetch, data) {
  const { id } = useParams();

  return useQuery(query, {
    variables: { input: { event_id: +id, options: data } },
    skip: !fetch || !data,
  });
}
