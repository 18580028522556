import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';
import { useSearchInput } from 'hooks/useSearchInput';

export const SPECTATOR_TICKET = gql`
  mutation SpectatorTicketMutation(
    $spectatorTicketInput: GetSpectatorTicketsList!
  ) {
    getSpectatorTicketsReport(input: $spectatorTicketInput) {
      tickets {
        user {
          formatted_name
          cellphone
          email
          zipcode
        }
        ticket {
          name
        }
        barcode
        holder
        price
      }
      event {
        name
        isMultiDay
        start_date
        end_date
        track {
          name
        }
      }
      total_sales
      total_tickets
    }
  }
`;

export function useGetSpectatorTicketsReport(event_date) {
  const [spectatorTicketMutation] = useMutation(SPECTATOR_TICKET);
  const { input } = useSearchInput();
  const { date } = input;

  const result = useCallback(
    (eventId) => {
      return spectatorTicketMutation({
        variables: {
          spectatorTicketInput: {
            event_id: Number(eventId),
            date: event_date ?? date,
          },
        },
      });
    },
    [spectatorTicketMutation, date, event_date]
  );
  return result;
}
