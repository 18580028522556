import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { SearchInput } from 'components/Form/SearchInput';
import Pagination from 'components/Pagination';
import { Table } from 'components/Table';
import Text from 'components/Text';
import { useGetTrackTransactionsGetEvent } from 'components/Transactions';
import { is_cellphone } from 'pages/app/Users';
import { formatTimestamp, formatPhoneNumber } from 'shared/formatters';
import { useTransactionFilter } from 'shared/transactionFilterContext';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: start;
  max-width: 85vw;
  width: 50%;
`;

const CashReport = () => {
  const [search, setSearch] = useState('');
  const [count, setCount] = useState();

  const history = useHistory();
  const theme = useTheme();

  const { pathname } = useLocation();
  const userTypeRoute = pathname?.split('/').includes('admin-track')
    ? 'track'
    : pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'admin';

  const { setSource, setPaymentMethod } = useTransactionFilter();

  useEffect(() => {
    setSource({ label: 'POS', value: 'pos' });
    setPaymentMethod({ label: 'Cash', value: 'cash' });
  }, [setPaymentMethod, setSource]);

  const { data } = useGetTrackTransactionsGetEvent();

  useEffect(() => {
    if (data && data?.getTrackTransactionsV2) {
      setCount(data?.getTrackTransactionsV2?.count);
    }
  }, [data]);

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const columns = [
    {
      label: 'Date/Time',
      key: 'date',
    },
    {
      label: 'Purchaser Name',
      key: 'purchaser',
    },
    {
      label: 'Order Total',
      key: 'subtotal',
    },
    {
      label: 'Discount',
      key: 'discount',
    },
    {
      label: 'Refund',
      key: 'refund',
    },
    {
      label: 'Credit',
      key: 'credit',
    },
    {
      label: 'Fee',
      key: 'fee',
    },
    {
      label: 'Total',
      key: 'total',
    },
  ];

  function renderRows(transaction) {
    return {
      date: formatTimestamp(
        Number(transaction.purchase_date),
        'MMM DD - YYYY h:mm A',
        true
      ).toUpperCase(),
      purchaser: (
        <Link
          to={
            userTypeRoute === 'admin'
              ? `/admin/transactions/detail/${transaction.id}`
              : `/admin-${userTypeRoute}/transactions/detail/${transaction.id}`
          }
          style={{
            color: theme.colors.primary,
          }}
        >
          {transaction.user
            ? is_cellphone(transaction.user.last_name)
              ? formatPhoneNumber(transaction.user.last_name)
              : `${transaction.user.last_name}, ${transaction.user.first_name}`
            : transaction.id}
        </Link>
      ),
      subtotal: transaction.subtotal ?? '$0.00',
      discount: transaction.discount ?? '$0.00',
      refund: transaction.refund ?? '$0.00',
      credit: transaction.credit ?? '$0.00',
      fee: transaction.fee ?? '$0.00',
      total: transaction.total ?? '$0.00',
    };
  }

  const handleChange = (e) => setSearch(e.target.value);

  const handleBlur = (e) => {
    history.push(`/admin/videos/?queryString=${e.target.value}`);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      history.push(`/admin/videos/?queryString=${e.target.value}`);
    }
  };

  return !data?.getTrackTransactionsV2 ? (
    <div />
  ) : (
    <>
      <Container>
        <ContainerHeader
          borderWidth={'1px'}
          style={{ position: 'relative', height: 160, width: '97%' }}
        >
          <TitleContainer style={{ flexDirection: 'column', width: '100%' }}>
            <FilterContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25 }}
              >
                Point of Sale Cash Report
              </Text>
              <SearchInput
                placeholder="Search Transactions"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
                value={search}
              />
            </FilterContainer>
            <FilterContainer>
              <Text>Sales: $2,440</Text>
            </FilterContainer>
          </TitleContainer>
        </ContainerHeader>
        <div style={{ padding: 20 }}>
          <Table
            items={data?.getTrackTransactionsV2.results}
            columns={columns}
            renderRows={renderRows}
          />
          <Pagination
            count={count}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </div>
      </Container>
    </>
  );
};

export default CashReport;
