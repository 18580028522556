import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CHECKOUT_POS = gql`
  mutation checkoutPOS($input: POSCheckoutInput!) {
    checkoutPOS(input: $input) {
      id
      users {
        promo
        user {
          id
          name
          first_name
          last_name
        }
        tickets {
          id
          name
          price
          refunded
          type
          barcode
          section
          row
          seat
          date
          seat_id
        }
      }
      event {
        name
      }
      track {
        name
      }
      card {
        last4
      }
    }
  }
`;

export function useCheckoutPos() {
  const [checkoutPosMutation] = useMutation(CHECKOUT_POS);

  const checkoutPos = useCallback(
    ({
      event_id,
      account_id,
      tickets,
      hold_token,
      payment_method,
      payment_intent,
    }) => {
      return checkoutPosMutation({
        variables: {
          input: {
            event_id,
            account_id,
            tickets: tickets,
            payment_method: payment_method,
            ...(hold_token ? { hold_token: hold_token } : {}),
            payment_intent: payment_intent,
          },
        },
        fetchPolicy: 'no-cache',
        skip: !tickets || tickets.length === 0 || !account_id || !event_id,
      });
    },
    [checkoutPosMutation]
  );

  return checkoutPos;
}
