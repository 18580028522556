import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import ContainerIcon from 'components/ContainerIcon';
import { SearchInput } from 'components/Form/SearchInput';
import Pagination from 'components/Pagination';
import Text from 'components/Text';
import ToolTip from 'components/Tooltip';
import { useSearchInput, useUpdateQueryString } from 'hooks/useSearchInput';
import useTheme from 'hooks/useTheme';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: row;
    align-items: flex-start;
    & > h1 {
      margin-bottom: 6px;
    }
  }
`;

export const SearchableListContainer = (props) => {
  const theme = useTheme();
  const {
    children,
    header,
    itemsPerPage,
    noSearch,
    onAddClick,
    pageCount,
    paginated,
    title,
    titleBarContent,
    searchInputPlaceholder = 'Search',
    inputMaxWidth,
    contentPadding,
    searchCriteria,
    endComponent,
    noContainerHeader,
    customStyle,
  } = props;
  const [searchInput, setSearchInput] = useState('');

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const { input } = useSearchInput();
  const currentPage = input.page ? Number(input.page) : 1;
  const updateQueryString = useUpdateQueryString();

  const handleChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleBlur = (e) => {
    updateQueryString(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      updateQueryString(e.target.value);
    }
  };

  return (
    <>
      {header && header}
      <Container style={customStyle}>
        {!isMobile ? (
          !noContainerHeader && (
            <ContainerHeader>
              <TitleContainer>
                {typeof title === 'string' ? (
                  <Text
                    type="heading"
                    as="h1"
                    color={theme.colors.text.header}
                    inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
                  >
                    {title}
                  </Text>
                ) : (
                  title
                )}
                <div style={{ marginRight: 10 }}>
                  {titleBarContent && titleBarContent}
                </div>
                {!noSearch && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'Row',
                      alignItems: 'center',
                      gap: 10,
                    }}
                  >
                    <SearchInput
                      placeholder={searchInputPlaceholder}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      onKeyDown={handleKeyPress}
                      value={searchInput}
                      style={{ maxWidth: inputMaxWidth }}
                    />
                    {searchCriteria && (
                      <ToolTip
                        title="Search Criteria"
                        info={true}
                        content={searchCriteria}
                      />
                    )}
                  </div>
                )}
              </TitleContainer>
              {onAddClick && <ContainerIcon onClick={onAddClick} />}
              {endComponent && (
                <Text
                  type="heading"
                  as="h3"
                  color={theme.colors.text.header}
                  inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
                >
                  {endComponent}
                </Text>
              )}
            </ContainerHeader>
          )
        ) : (
          <ContainerHeader>
            <TitleContainer>
              {typeof title === 'string' ? (
                <Text
                  type="heading"
                  as="h1"
                  color={theme.colors.text.header}
                  inlineStyle={{ marginRight: 20, whiteSpace: 'nowrap' }}
                >
                  {title}
                </Text>
              ) : (
                title
              )}
              <div style={{ marginRight: 10 }}>
                {titleBarContent && titleBarContent}
              </div>
            </TitleContainer>

            <TitleContainer>
              {!noSearch && (
                <SearchInput
                  placeholder={searchInputPlaceholder}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onKeyDown={handleKeyPress}
                  value={searchInput}
                  style={{ maxWidth: inputMaxWidth }}
                />
              )}
              <div style={{ marginTop: 10 }}>
                {onAddClick && <ContainerIcon onClick={onAddClick} />}
              </div>
            </TitleContainer>
          </ContainerHeader>
        )}
        <div
          style={{
            padding:
              contentPadding || contentPadding === 0 ? contentPadding : 20,
          }}
        >
          {children}
        </div>
        {paginated && (
          <Pagination
            count={pageCount ?? 0}
            perPage={itemsPerPage ?? 15}
            currentPage={currentPage}
          />
        )}
      </Container>
    </>
  );
};
SearchableListContainer.propTypes = {
  header: PropTypes.node,
  itemsPerPage: PropTypes.number,
  noSearch: PropTypes.bool,
  onAddClick: PropTypes.func,
  pageCount: PropTypes.number,
  paginated: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  titleBarContent: PropTypes.node,
  searchInputPlaceholder: PropTypes.string,
};
