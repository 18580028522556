import moment from 'moment';
import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { withTheme } from 'styled-components';
import { useGetEventTransactions } from 'components/Transactions/gql/useGetEventTransactions.mutation';

const Container = styled.button`
  border-radius: 5px;
  background-color: #fa4616;
  color: #fff;
  box-sizing: border-box;
  padding: 15px;
  border: none;
  width: calc(100% - 20px);
  margin: 0 10px 0 10px;

  &:focus {
    outline: none;
  }

  @media (min-width: 860px) {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0 10px 0 10px;
    background-color: transparent;
    cursor: pointer;
    width: auto;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 860px) {
    margin: 0;
    border: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  span {
    color: #fff;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    font-weight: 700;
    line-height: 16px;

    @media (min-width: 860px) {
      text-align: center;
      font-size: 12px;
      color: #3c4144;
      text-align: center;
      margin-top: 10px;
    }
  }

  svg {
    fill: #fff;
    margin-right: 10px;

    @media (min-width: 860px) {
      fill: #fa4616;
      margin: 0;
    }
  }
`;

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:text/plain;charset=utf-8,' + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

const Export = ({ icon, eventId, button, buttonText }) => {
  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = pathname.includes('-staging');

  const getEventTransactions = useGetEventTransactions();

  const getData = () => {
    return getEventTransactions(eventId)
      .then((response) => {
        const eventPasees = response.data.getEventTransactionsV2;
        return eventPasees;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const exportTrackPDF = async () => {
    const transactions = await getData();
    const headers = transactions.event.hide_fee
      ? `Purchaser Last Name,Purchaser First Name,Order Total,Discount,Refund,Credit,Absorbed Fees,Transaction Total,Time/Date,Transaction Link${'\n'}`
      : `Purchaser Last Name,Purchaser First Name,Order Total,Discount,Refund,Credit,Transaction Total,Time/Date,Transaction Link${'\n'}`;

    const transactionRows = transactions.data.map((data) =>
      data.data
        .map((transaction) => {
          const {
            id,
            purchaser,
            credit,
            discount,
            total,
            refund,
            subtotal,
            fee,
            purchase_date,
          } = transaction;

          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${id}`;

          return transactions.event.hide_fee
            ? `"${purchaser?.split(',')[0]}","${
                purchaser?.split(',')[1]
              }","${subtotal}","${discount}","${refund}","${credit}","${fee}","${total}","${purchase_date}","${linkUrl}"`
            : `"${purchaser?.split(',')[0]}","${
                purchaser?.split(',')[1]
              }","${subtotal}","${discount}","${refund}","${credit}","${total}","${purchase_date}","${linkUrl}"`;
        })
        .join('\n')
    );

    const dateTimeStamp = moment().format('MMM DD - YYYY h:mm A');

    const csv = `Transaction Report
      Event Date:, ${transactions.event.fullDate
        .replace(/,/i, ' ')
        .replace(/, /i, ' ')}
      Event Name:, ${transactions.event.name}
      Account Name:, ${transactions.event.account_name}
      Track Name:, ${transactions.event.track.name}
      Exported:, ${dateTimeStamp}
      Total Sales:, ${transactions.gross_sales}
      ${
        transactions.event.marketing_fee
          ? `1% Cash Back ${transactions.marketing_fund}`
          : transactions.event.hide_fee
          ? 'Promoter Absorbed fees \n,'
          : ''
      }
      ${
        transactions.event.marketing_fee && transactions.event.hide_fee
          ? 'Promoter Absorbed fees \n,'
          : ''
      }
      ${
        transactionRows[1]
          ? `Credits Redeemed - ${transactions.data[1].total}
      ${headers}${transactionRows[1]} \n`
          : `Credits Redeemed - ${transactions.data[1].total} \n`
      }
      ${
        transactionRows[2]
          ? `Credits Issued - ${transactions.data[2].total}
      ${headers}${transactionRows[2]} \n`
          : `Credits Issued - ${transactions.data[2].total} \n`
      }
      ${
        transactionRows[4]
          ? `Refunds Issued - ${transactions.data[4].total}
      ${headers}${transactionRows[4]} \n`
          : `Refunds Issued - ${transactions.data[4].total} \n`
      }
      ${
        transactionRows[3]
          ? `Discounts - ${transactions.data[3].total}
      ${headers}${transactionRows[3]} \n`
          : `Discounts - ${transactions.data[3].total} \n`
      }
      ${
        transactionRows[0]
          ? `All Transactions - ${transactions.data[0].total}
      ${headers}${transactionRows[0]},`
          : `All Transactions - ${transactions.data[0].total}`
      }
    `;

    download(
      `${moment(transactions.date).format('ddd MMM D - YYYY')} - ${
        transactions.event.name
      } - TRANSACTION REPORT.csv`,
      csv.trim()
    );
  };

  return (
    <Container onClick={() => exportTrackPDF()}>
      {button ? (
        <div>{button}</div>
      ) : (
        <IconWrapper>
          {icon}
          <span>{buttonText ? buttonText : 'Export List'}</span>
        </IconWrapper>
      )}
    </Container>
  );
};

export default withTheme(Export);
