import styled from 'styled-components';

export const MainContainer = styled.div`
  /* Add this to your CSS file */
  .StripeElement {
    padding: 10px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    background-color: #ffffff;
    margin: 10px 0px;
  }

  .StripeElement--invalid {
    border-color: #ed4337;
  }
`;
