import React, { useState } from 'react';
import EventManager from 'pages/track/EventManager';
import { ReservedOrderDetail } from './ReservedOrderDetail';
import { ReservedOrders } from './ReservedOrders';

// View and edit reserved seat flow
const steps = {
  1: 'viewReservedSeats',
  2: 'viewOrderDetails',
  3: 'viewSeatsMap',
};

export const MoveReservedSeats = ({ seatId }) => {
  const [step, setStep] = useState(steps[1]);

  const [selectedOrder, setSelectedOrder] = useState();
  const [selectedSeats, setSelectedSeats] = useState();

  const onOpenReservedOrder = (id) => {
    setSelectedOrder(id);
    setStep(steps[2]);
  };

  const onMoveSeats = () => {
    setStep(steps[3]);
  };

  return (
    <div>
      {step === steps[1] && (
        <ReservedOrders onOpenDetail={onOpenReservedOrder} />
      )}
      {step === steps[2] && selectedOrder && (
        <ReservedOrderDetail
          purchaseId={selectedOrder}
          selectedSeats={selectedSeats}
          setSelectedSeats={setSelectedSeats}
          onMoveSeats={onMoveSeats}
        />
      )}
      {step === steps[3] && (
        <EventManager
          seatId={seatId}
          mode="select"
          customStyle={{ height: '64vh' }}
          onObjectSelected={(object) => {
            console.log('object', object);
          }}
          onObjectDeselected={(object) => {
            console.log('object', object);
          }}
        />
      )}
    </div>
  );
};
