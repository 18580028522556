import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Route, MemoryRouter, Link } from 'react-router-dom';
import { ExternalAnchor } from 'components/Anchor';
import Icon from 'components/Icon';
import { TransactionModal as Modal } from 'components/Modal';
import { ModalSectionHeaderContainer } from 'components/Modal/styles';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { Person } from './Person';
import { RefundModal } from './RefundModal';
import {
  ModalContainer,
  DataRow,
  Header,
  TransferButton,
  RefundButton,
} from './styles';
import { TransactionCreditModal } from './TransactionCreditModal';
import { TransactionTickets } from './TransactionTickets';

export const TransactionModal = ({
  currentTransaction,
  close,
  admin,
  employeeAdmin,
  match,
  isUserTransaction,
}) => {
  return (
    <Modal
      maxWidth={600}
      isVisible={!!currentTransaction}
      close={close}
      hideModal={close}
      noHeader
      modalStyle={{ overflow: 'scroll' }}
    >
      <ModalRoutes
        close={close}
        admin={admin}
        employeeAdmin={employeeAdmin}
        currentTransaction={currentTransaction}
        match={match}
        isUserTransaction={isUserTransaction}
      />
    </Modal>
  );
};

const ModalRoutes = ({
  close,
  admin,
  currentTransaction,
  match,
  employeeAdmin,
  isUserTransaction,
  // ...props
}) => {
  return (
    <MemoryRouter>
      <Route
        path="/"
        exact
        render={(props) => (
          <BaseModal
            {...props}
            isUserTransaction={isUserTransaction}
            close={close}
            currentTransaction={currentTransaction}
            match={match}
            admin={admin}
            employeeAdmin={employeeAdmin}
          />
        )}
      />
      <Route
        path="/transfer"
        render={(props) => (
          <TransactionTickets {...props} close={close} match={match} />
        )}
      />
      <Route
        path="/issueCredit"
        render={(props) => (
          <TransactionCreditModal
            {...props}
            currentTransaction={currentTransaction}
            close={close}
          />
        )}
      />
      <Route
        path="/refundTransaction"
        render={(props) => (
          <RefundModal
            {...props}
            currentTransaction={currentTransaction}
            close={close}
            match={match}
            isUserTransaction={isUserTransaction}
          />
        )}
      />
    </MemoryRouter>
  );
};

const BaseModal = ({
  close,
  admin,
  currentTransaction,
  employeeAdmin,
  history,
  isUserTransaction,
  ...props
}) => {
  const [selectedPeople, setSelectedPeople] = useState([]);

  useEffect(() => {
    if (props.location.query) {
      setSelectedPeople([...props.location.query]);
    }
  }, [props.location.query]);
  if (!currentTransaction) return false;

  const isAllRefunded =
    currentTransaction.users &&
    currentTransaction.users.length &&
    currentTransaction.users
      .map((person) => person.tickets.map((ticket) => ticket.refunded).flat())
      .flat()
      .every((refundedTicket) => refundedTicket === true);

  const isCredited =
    currentTransaction.users &&
    currentTransaction.users.length &&
    currentTransaction.users
      .map((person) => person.tickets.map((ticket) => ticket.credited).flat())
      .flat()
      .some((creditedTicket) => creditedTicket === true);

  return (
    <ModalContainer>
      <Header>
        <Text type="heading">Transaction Information</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </Header>

      <>
        {!isUserTransaction && (
          <TransactionInformationRow
            label="Purchaser"
            data={
              currentTransaction.user
                ? currentTransaction.user.formatted_name
                : ''
            }
          />
        )}
        <TransactionInformationRow
          label="Total"
          data={currentTransaction.total}
        />
        <TransactionInformationRow
          label="Discount"
          data={currentTransaction.discount}
        />
        <TransactionInformationRow
          label="Credit"
          data={currentTransaction.credit}
        />
        <TransactionInformationRow
          label="Fee"
          data={currentTransaction.fee}
          noSpacer={!currentTransaction.fee}
        />
        <TransactionInformationRow
          label="Refund"
          data={currentTransaction.refund ?? '$0.00'}
        />

        <TransactionInformationRow
          label="Transfer"
          data={
            currentTransaction.transfer_id &&
            !currentTransaction.transfer_id.includes('null') ? (
              <ExternalAnchor
                href={currentTransaction.transfer_id}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </ExternalAnchor>
            ) : (
              'N/A'
            )
          }
          noSpacer={!currentTransaction.charge_id}
        />
        <TransactionInformationRow
          label="Charge"
          data={
            currentTransaction.charge_id &&
            !currentTransaction.charge_id.includes('null') ? (
              <ExternalAnchor
                href={currentTransaction.charge_id}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </ExternalAnchor>
            ) : (
              'N/A'
            )
          }
        />
        <TransactionInformationRow
          label="Order Source"
          data={currentTransaction.order_source ?? 'App'}
        />
        <TransactionInformationRow
          label="Payment Method"
          data={currentTransaction.payment_method ?? 'Card'}
        />
        <TransactionInformationRow
          label="Sold By"
          data={currentTransaction.register ?? ''}
        />
        <Spacer size={15} />

        {currentTransaction.users && currentTransaction.users.length ? (
          <>
            <ModalSectionHeaderContainer>
              <Text type="heading">
                {'tickethoss' !== process.env.REACT_APP_PLATFORM
                  ? 'Passes'
                  : 'Tickets'}
              </Text>
            </ModalSectionHeaderContainer>
            {currentTransaction.users &&
              currentTransaction.users.length &&
              currentTransaction.users.map((person, index) => (
                <Person
                  key={person.id ?? index}
                  person={person}
                  setSelectedPeople={setSelectedPeople}
                  selectedPeople={selectedPeople}
                  index={index}
                  employeeAdmin={employeeAdmin}
                />
              ))}
          </>
        ) : null}
      </>

      {!employeeAdmin ? (
        <>
          <RefundButton
            disabled={isAllRefunded && !isCredited}
            onClick={() => history.push('/refundTransaction')}
          >
            Issue Refund
          </RefundButton>
          <RefundButton
            disabled={isAllRefunded}
            onClick={() => history.push('/issueCredit')}
          >
            Issue Credit
          </RefundButton>
        </>
      ) : null}

      {admin && !isAllRefunded ? (
        <TransferButton
          disabled={!selectedPeople.map((item) => item.tickets).flat().length}
        >
          <Link
            to={{
              pathname: `/transfer`,
              query: [...selectedPeople],
            }}
            className={TransferButton}
          >
            transfer
          </Link>
        </TransferButton>
      ) : null}
    </ModalContainer>
  );
};

export const TransactionInformationRow = (props) => {
  const { label, data, noSpacer } = props;
  return (
    <>
      <DataRow>
        <Text type="bold" color="#707273" inlineStyle={{ width: '50%' }}>
          {label}:
        </Text>
        <Text
          type="bold"
          fontWeight="600"
          inlineStyle={{
            width: '50%',
            whiteSpace: 'normal',
            marginLeft: 10,
            textTransform: 'capitalize',
          }}
        >
          {data}
        </Text>
      </DataRow>
      {!noSpacer && <Spacer size={15} />}
    </>
  );
};
TransactionInformationRow.propTypes = {
  label: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  noSpacer: PropTypes.bool,
};
