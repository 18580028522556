import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import Loading from 'components/Loading';
import { Modal } from 'components/Modal';
import { useCart } from 'shared/CartContext';

const Container = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
`;

const STATUS_CONNECTED = 'Connected';

export const ReaderIpModal = ({ isVisible, setIsVisible }) => {
  const [ipAddress, setIpAddress] = useState('');
  const [isValidIp, setIsValidIp] = useState(true);

  const { setConnectionStatus, printer, connectionStatus } = useCart();

  const IPAddress = localStorage.getItem('readerIp');

  const ePosDevice = useRef();

  const [showIPInput, setShowIPInput] = useState(false);

  const connectPrinter = () => {
    setShowIPInput(false);
    setConnectionStatus('Connecting ...');

    const ePosDev = new window.epson.ePOSDevice();
    ePosDevice.current = ePosDev;
    if (IPAddress) {
      ePosDev.connect(IPAddress, 8043, (data) => {
        if (data === 'SSL_CONNECT_OK') {
          setIsVisible(false);
          ePosDev.createDevice(
            'local_printer',
            ePosDev.DEVICE_TYPE_PRINTER,
            { crypto: true, buffer: false },
            (devobj, retcode) => {
              if (retcode === 'OK') {
                printer.current = devobj;
                setConnectionStatus(STATUS_CONNECTED);
              } else {
                setIsVisible(false);
                alert('issue connecting printer');
                throw retcode;
              }
            }
          );
        } else {
          setIsVisible(false);

          alert('issue connecting printer');
          throw data;
        }
      });
    }
  };

  useEffect(() => {
    if (!IPAddress) setShowIPInput(true);
    else if (connectionStatus !== STATUS_CONNECTED) connectPrinter();
    else setIsVisible(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // IP validation regex: checks if the IP is in the format xxx.xxx.xxx.xxx
  const validateIpAddress = (ip) => {
    const ipRegex =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipRegex.test(ip);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setIpAddress(value);

    // Validate IP address
    setIsValidIp(validateIpAddress(value));
  };

  const handleContinue = () => {
    // Save the input IP address to local storage if valid
    if (isValidIp) {
      localStorage.setItem('readerIp', ipAddress);
      connectPrinter();
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      hideModal={handleClose}
      title={
        showIPInput ? `Input your printer IP Address` : 'Connecting to Printer'
      }
      maxWidth={600}
    >
      <Container>
        {showIPInput ? (
          <>
            <Input
              id="ip-address"
              name="ipAddress"
              label="IP Address"
              placeholder="xxx.xxx.xxx.xxx"
              required
              onChange={handleChange}
              value={ipAddress}
              error={!isValidIp && ipAddress !== '' ? 'Invalid IP address' : ''}
            />

            <ButtonRow>
              <Button
                type="button"
                onClick={handleContinue}
                disabled={!ipAddress || !isValidIp}
              >
                Save
              </Button>
            </ButtonRow>
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              justifyContent: 'center',
            }}
          >
            <Loading />
            <p>Connection in progress</p>
          </div>
        )}
      </Container>
    </Modal>
  );
};
