import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Button, LinkButton } from 'components/Button';
import { HideOnMobile, HideOnDesktop } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import { ConfirmModal } from 'components/Modal';
import { ModalSmsNotification } from 'components/ModalSmsNotification';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import { Table } from 'components/Table';
import Text from 'components/Text';
import useTheme from 'hooks/useTheme';
import {
  logDevError,
  deleteErrorMessage,
  deleteSuccessMessage,
} from 'shared/alerts';
import { AppContext } from 'shared/AppContext';
import { formatPhoneNumber } from 'shared/formatters';
import PermissionModal from '../PermissionModal/PermissionModal';
import { GET_GUESTS, useGetGuests, useDeleteGuest } from './gql';
import { GuestForm } from './GuestForm';
import {
  GuestSearch,
  useGuestSearch,
  withGuestSearchContext,
} from './GuestSearch';

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 25px;
  }

  @media (max-width: 860px) {
    display: block;
    flex: 1;
    & > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 25px;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 860px) {
    height: auto;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const TitleTextContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 860px) {
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 20px;
    margin-left: 20%;
  }
`;

export const GuestListsButtonWrapper = styled.div`
  margin-right: 25;
  height: 100%;
  padding-top: 20px;
  display: none;

  @media (min-width: 860px) {
    display: block;
  }
`;

const GuestListButtonWrapperMobile = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme.colors.border};
  border-style: solid;

  @media (min-width: 860px) {
    display: none;
  }
`;

const FilterContainer = styled.div`
  min-width: 210px;
`;

export const Guests = withGuestSearchContext((props) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const userType = pathname.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';
  const {
    state: { capabilities },
  } = useContext(AppContext);

  const [showPermissionModal, setShowPermissionModal] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [shouldDisplayDeleteModal, setShouldDisplayDeleteModal] =
    useState(false);
  const [newGuestData, setNewGuestData] = useState(null);
  const { guestSearchQuery: queryString } = useGuestSearch();
  const [guestId, setGuestId] = useState(null);
  const [guestTypeFilter, setGuestTypeFilter] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { data } = useGetGuests({ queryString });
  const deleteGuest = useDeleteGuest();

  const [showHelpModal, setShowHelpModal] = useState(false);

  const handleOutClick = () => {
    setIsVisible(!isVisible);
    setGuestId(null);
  };

  async function handleDeleteGuestConfirm() {
    try {
      deleteGuest({
        variables: { id: guestId },
        update: (cache) => {
          const data = cache.readQuery({
            query: GET_GUESTS,
            variables: { input: { queryString } },
          });
          if (data) {
            const updatedResults = data.getGuestsV2.results.filter(
              (guest) => guest.id !== guestId
            );
            cache.writeQuery({
              query: GET_GUESTS,
              variables: { input: { queryString } },
              data: {
                ...data,
                getGuests: {
                  ...data.getGuests,
                  count: data.getGuests.count - 1,
                  results: updatedResults,
                },
              },
            });
          }
        },
      });
      deleteSuccessMessage('Guest');
    } catch (error) {
      logDevError(error);
      deleteErrorMessage('Guest');
    } finally {
      setShouldDisplayDeleteModal(false);
    }
  }

  async function onSetNewGuestData(guest) {
    setNewGuestData(guest);
  }

  const urlParams = new URLSearchParams(window.location.search);
  const currentPage = parseInt(urlParams.get('page'));

  const guests =
    data?.getGuestsV2.results &&
    data.getGuestsV2.results.filter((result) =>
      guestTypeFilter?.value === 'year'
        ? !!result.year
        : guestTypeFilter?.value === 'event'
        ? !!result.event_id
        : true
    );
  const count = data?.getGuestsV2.count;

  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Phone Number',
      key: 'phone_number',
    },
    {
      label: 'Date',
      key: 'date',
    },
    {
      label: 'Event',
      key: 'event',
    },
    {
      label: 'Additional Guests',
      key: 'additional_guests',
    },
    {
      label: '',
      key: 'actions',
    },
  ];

  function renderRows(guest) {
    const {
      id,
      last_name,
      first_name,
      phone_number,
      event,
      ownership,
      year,
      additional_guests,
    } = guest;
    return {
      name: `${last_name}, ${first_name}`,
      phone_number: formatPhoneNumber(phone_number),
      date: year ?? event?.fullDate ?? '',
      event: event?.name ?? ownership?.label ?? '',
      additional_guests: additional_guests ? `+${additional_guests}` : '0',
      actions: (
        <>
          <Icon
            icon="edit"
            size={18}
            color={theme.colors.primary}
            onClick={async () => {
              setGuestId(id);
              setIsVisible(!isVisible);
            }}
            padding="0 15px 0 0"
          />
          <Icon
            icon="trash"
            size={18}
            color={theme.colors.primary}
            onClick={() => {
              setGuestId(id);
              setShouldDisplayDeleteModal(true);
            }}
          />
        </>
      ),
    };
  }

  return !guests ? null : (
    <>
      <Container>
        <ContainerHeader
          borderWidth={isMobile ? '0px' : '1px'}
          style={{ position: 'relative' }}
        >
          <TitleContainer>
            <TitleTextContainer>
              <Text
                type="heading"
                color={theme.colors.secondary}
                inlineStyle={{
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                }}
              >
                Guests
              </Text>

              <HideOnDesktop>
                <div
                  style={{
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 20,
                  }}
                >
                  <Icon
                    style={{ marginTop: 7 }}
                    icon="Dashboard-Help-Icon"
                    onClick={() => setShowHelpModal(true)}
                    size={35}
                    color={theme.colors.primary}
                  />
                </div>
              </HideOnDesktop>
            </TitleTextContainer>

            <HideOnDesktop>
              <Spacer size={30} />
            </HideOnDesktop>

            <GuestSearch />
            <FilterContainer>
              <AutoSuggest
                placeholder="View"
                options={[
                  {
                    label: 'Full Season Guests',
                    value: 'year',
                  },
                  {
                    label: 'Single Event Guests',
                    value: 'event',
                  },
                ]}
                value={guestTypeFilter}
                onChange={setGuestTypeFilter}
                isClearable
              />
            </FilterContainer>

            <HideOnMobile>
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: 20,
                }}
              >
                <Icon
                  style={{ marginTop: 7 }}
                  icon="Dashboard-Help-Icon"
                  onClick={() => setShowHelpModal(true)}
                  size={35}
                  color={theme.colors.primary}
                />
              </div>
            </HideOnMobile>
          </TitleContainer>
          <ButtonContainer>
            <div
              style={{
                display: 'block',
                height: '100%',
                paddingTop: 20,
                marginRight: 20,
              }}
            >
              <Button
                onClick={() => {
                  capabilities?.add_guests
                    ? setIsVisible(!isVisible)
                    : setShowPermissionModal(true);
                }}
                fontSize={20}
              >
                {isMobile ? 'Add Guest' : 'Add a new Guest'}
              </Button>
            </div>

            <GuestListsButtonWrapper
              style={{
                marginRight: 5,
                height: '100%',
                paddingTop: 20,
                minWidth: 160,
              }}
            >
              <LinkButton to={`/admin-${userType}/guest-lists`}>
                View Guest Lists
              </LinkButton>
            </GuestListsButtonWrapper>
          </ButtonContainer>
        </ContainerHeader>
        <GuestListButtonWrapperMobile>
          <LinkButton to={`/admin-${userType}/guest-lists`}>
            View Guest Lists
          </LinkButton>
        </GuestListButtonWrapperMobile>

        <div style={{ padding: 20 }}>
          <HideOnMobile>
            <Table columns={columns} items={guests} renderRows={renderRows} />
          </HideOnMobile>
          <HideOnDesktop>
            {guests.map((item) => (
              <Link
                key={item.id}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  textDecoration: 'none',
                  marginBottom: 20,
                }}
                to={{
                  pathname: `/admin-${userType}/guests/${item.id}`,
                  state: { queryString },
                }}
              >
                <Text
                  type="heading"
                  color="#00001f"
                  inlineStyle={{ textTransform: 'uppercase' }}
                >
                  {`${item.last_name}, ${item.first_name}`}
                </Text>
                <div style={{ transform: 'rotate(-90deg)' }}>
                  <Icon size={22} icon="chevron" color="#fa4616" />
                </div>
              </Link>
            ))}
          </HideOnDesktop>
        </div>

        <Pagination count={count} perPage={15} currentPage={currentPage || 1} />
      </Container>

      <GuestForm
        isVisible={isVisible}
        handleOutClick={handleOutClick}
        guestId={guestId}
        queryString={queryString}
        setNewGuestData={onSetNewGuestData}
        theme={theme}
      />

      <ConfirmModal
        isVisible={shouldDisplayDeleteModal}
        confirmText="Delete Guest"
        cancelText="Don't Delete"
        onConfirm={handleDeleteGuestConfirm}
        title="Do you want to delete this Guest?"
        hideModal={() => {
          setShouldDisplayDeleteModal(false);
        }}
      />

      <ModalSmsNotification
        isVisible={newGuestData !== null}
        cancelText="Close"
        title="New Guest Added"
        cellphone={newGuestData?.phone_number}
        body={`${newGuestData?.first_name} ${newGuestData?.last_name}, has been added, you can send them a message at this time.`}
        hideModal={() => {
          setNewGuestData(null);
        }}
      />

      <PermissionModal
        isVisible={showPermissionModal}
        hideModal={() => setShowPermissionModal(false)}
        link={`/admin-${userType}/settings/2`}
        history={props.history}
      />

      <HelpModals
        isVisible={showHelpModal}
        setIsVisible={setShowHelpModal}
        contents={[
          {
            title: 'Explanation Part 1',
            content:
              'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
            video: 'https://player.vimeo.com/video/903075544?h=a35101967c',
          },
          {
            title: 'Explanation Part 2',
            content: (
              <div>
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </p>
                <Spacer size={16} />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            ),
          },
          {
            title: 'Explanation Part 3',
            content: (
              <span>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </span>
            ),
          },
          {
            title: 'Hide Help Like This?',
            titleStyle: {
              fontSize: 32,
              fontWeight: 700,
              lineHeight: '40px',
              fontFamily: 'Barlow Condensed',
            },
            content: (
              <div style={{ alignContent: 'center', textAlign: 'center' }}>
                <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                  Click on any{' '}
                  <Icon
                    icon="Dashboard-Help-Icon"
                    size={28}
                    color={theme.colors.primary}
                  />{' '}
                  icon to bring it back.
                </p>

                <Spacer size={20} />
                <Button onClick={() => setShowHelpModal(false)}>
                  {' '}
                  Hide For Today{' '}
                </Button>

                <Spacer size={10} />
                <Button onClick={() => setShowHelpModal(false)} outlined={true}>
                  {' '}
                  Hide Forever
                </Button>

                <Spacer size={10} />
                <Button
                  onClick={() => setShowHelpModal(false)}
                  outlined={true}
                  buttonStyle={{ border: 'none' }}
                >
                  {' '}
                  Hide Now
                </Button>
              </div>
            ),
            hideFooter: true,
            customStyle: {
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            },
          },
        ]}
      />
    </>
  );
});
