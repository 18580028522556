import { Formik } from 'formik';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { compose } from 'recompose';
import styled from 'styled-components';
import {
  EventNameWrapper,
  HeaderWrapper,
  StatusWrapper,
} from 'components/Events/EventHeader';
import { FaqList } from 'components/Faqs';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { Dropzone } from 'components/Form/Dropzone';
import { Input } from 'components/Form/Input';
import { Select } from 'components/Form/Select';
import { Label } from 'components/Form/styles';
import RowSection from 'components/RowSection';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import {
  AddUpdateBtn,
  Content,
  Header,
  SectionTitle,
  Tab,
  TabContent,
  Tabs,
} from 'pages/app/Events/AddEvents/styles';
import Account from 'pages/app/Events/EventEdit/Dropdown/account';
import InfosRepeater from 'pages/app/Infos/InfosRepeater';
import {
  Column,
  ColumnText,
  TrackLogo,
} from 'pages/track/Events/Header/StyledHeader';
import CreateSeries from '../gql/mutations/create-series';
import UpdateSeries from '../gql/mutations/update-series';
import GetSeriesDetail from '../gql/queries/get-single-series';

const formValues = [
  'name',
  'short_name',
  'website',
  'schedule',
  'type_id',
  'added_by',
  'bio',
  'twitter',
  'facebook',
  'instagram',
  'youtube',
  'tiktok',
  'status',
  'image_id',
  'facebook_image_id',
  'facebook_image',

  'twitter_image_id',
  'twitter_image',
  'logo_id',
  'image',
  'logo',
  'user_id',
  'account_id',
  'admin_infos',
  'infos_with_order',
  'url',
  'faqs',
  'promoter_name',
  'promoter_email',
  'promoter_phone',
];

export const SeriesBody = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

function getInfosWithOrders(infos, index) {
  const { id, info, link, text, logo_id, video_url, image_id, pdf_id } = infos;
  return {
    id,
    info,
    link,
    text,
    logo_id,
    order: index,
    video_url,
    image_id,
    pdf_id,
  };
}

const SeriesEdit = ({ data, createSeries, updateSeries, props }) => {
  const successMessage = () =>
    toast.success(currentSeries ? 'Series Updated' : 'Series Created');
  const errorMessage = (response) =>
    toast.error(
      currentSeries
        ? response.global
          ? 'Error Updating Series'
          : "There were errors with your submission check the form's field for errors."
        : 'Error Creating Series'
    );

  const history = useHistory();
  const params = useParams();

  const currentSeries = Number(params.id);

  const tabsConfig = [
    {
      id: 0,
      name: 'Details',
    },
    {
      id: 2,
      name: 'General Info',
    },
    {
      id: 3,
      name: 'Faqs',
    },
  ];

  const [active, setActive] = useState(0);
  const [chosenTab, setChosenTab] = useState(tabsConfig[0]);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (data.loading) return <div />;

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formValues.reduce((acc, value) => {
        acc[value] =
          value === 'admin_infos' || value === 'infos_with_order'
            ? data.getSeriesDetail && data.getSeriesDetail['admin_infos']
              ? data.getSeriesDetail['admin_infos'].map((info) => {
                  return {
                    ...info,
                    info: { value: info.info.id, label: info.info.name },
                  };
                })
              : []
            : value === 'faqs'
            ? data.getSeriesDetail && data.getSeriesDetail[value]
              ? data.getSeriesDetail[value]?.map((faq) => {
                  const { ...rest } = faq;
                  return { ...rest };
                })
              : []
            : currentSeries &&
              data.getSeriesDetail &&
              data.getSeriesDetail[value]
            ? data.getSeriesDetail[value]
            : '';

        return acc;
      }, {})}
      validate={(values) => {
        const errors = {};
        if (!values.name) {
          errors.name = 'Required';
        }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
        let response;
        setSubmitting(true);

        const data = Object.entries(values).reduce((acc, entry) => {
          acc[entry[0]] = '' === entry[1] ? null : entry[1];
          return acc;
        }, {});

        data['account_id'] = data.account_id ? data.account_id.value : null;

        if (data['infos_with_order'] && data['infos_with_order'].length > 0) {
          data['admin_infos'] = data['infos_with_order'].map((info, index) => ({
            ...getInfosWithOrders(info, index),
          }));
        }
        delete data['infos_with_order'];

        if (currentSeries) {
          delete data.logo;
          delete data.image;
          delete data['facebook_image'];
          delete data['twitter_image'];

          response = await updateSeries({
            ...data,
            id: currentSeries,
            type_id: data?.type_id?.toString(),
            admin_infos: data.admin_infos
              ? data.admin_infos.map((info) => {
                  return {
                    ...(info.id ? { id: info.id } : null),
                    logo_id: info.logo_id ? Number(info.logo_id) : null,
                    link: info.link,
                    text: info.text,
                    info_id: info.info.value,
                    order: info.order,
                    video_url: info.video_url,
                    image_id: info.image_id ? Number(info.image_id) : null,
                    pdf_id: info.pdf_id ? Number(info.pdf_id) : null,
                  };
                })
              : null,
            faqs: data.faqs
              ? data.faqs.map((faq, index) => {
                  return {
                    question: faq.question,
                    answer: faq.answer,
                    order: index,
                  };
                })
              : null,
          });
        } else {
          delete data.logo;
          delete data.image;
          delete data['facebook_image'];
          delete data['twitter_image'];
          response = await createSeries({
            ...data,
            type_id: data?.type_id?.toString(),
            admin_infos: data.admin_infos
              ? data.admin_infos.map((info) => {
                  return {
                    ...(info.id ? { id: info.id } : null),
                    logo_id: info.logo_id ? Number(info.logo_id) : null,
                    link: info.link,
                    text: info.text,
                    info_id: info.info.value,
                    order: info.order,
                    video_url: info.video_url,
                    image_id: info.image_id ? Number(info.image_id) : null,
                    pdf_id: info.pdf_id ? Number(info.pdf_id) : null,
                  };
                })
              : null,
            faqs: data.faqs.map((faq, index) => {
              return {
                question: faq.question,
                answer: faq.answer,
                order: index,
              };
            }),
          });
        }

        setSubmitting(false);

        if (!response || response.errors) {
          errorMessage(response);
          return setErrors(response.errors);
        } else {
          successMessage();
          resetForm();
          history.goBack();
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <Header>
            <HeaderWrapper>
              <EventNameWrapper>
                <TrackLogo>
                  <img
                    style={{ width: '100%', height: 'auto', display: 'block' }}
                    src={values.logo ?? ''}
                    alt="logo"
                  />
                </TrackLogo>
                <Column>
                  <ColumnText>
                    <Text fontSize={24} lineHeight={29} type="heading">
                      {values.name ?? ''}
                    </Text>
                  </ColumnText>
                </Column>
              </EventNameWrapper>
              <StatusWrapper>
                <Select
                  id="status"
                  label="Status"
                  placeholder="Status"
                  options={[
                    { label: 'Draft', value: 'draft' },
                    { label: 'Published', value: 'published' },
                  ]}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.status}
                  error={errors.status || touched.status}
                />
              </StatusWrapper>
            </HeaderWrapper>
          </Header>
          <SeriesBody>
            <TabContent>
              <Tabs>
                <div style={{ width: '90%' }}>
                  {!isMobile ? (
                    tabsConfig.map((tab) => (
                      <Tab
                        onClick={(e) => handleClick(e)}
                        active={active === tab.id}
                        id={tab.id}
                      >
                        {tab.name}
                      </Tab>
                    ))
                  ) : (
                    <div style={{ width: '90%', margin: '0 5vw' }}>
                      <AutoSuggest
                        value={chosenTab}
                        onChange={(value) => {
                          setChosenTab(value);
                          setActive(value.value);
                        }}
                        onBlur={() => {
                          return;
                        }}
                        closeMenuOnSelect
                        options={tabsConfig.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })}
                      />
                      <Spacer size={10} />
                    </div>
                  )}
                </div>

                <AddUpdateBtn type="submit" disabled={isSubmitting} block>
                  {currentSeries ? 'Update Series' : 'Create Series'}
                </AddUpdateBtn>
              </Tabs>

              <Content active={active === 0}>
                <div style={{ width: '60%', marginLeft: 10 }}>
                  <Account
                    values={values}
                    errors={errors}
                    touched={touched}
                    id={currentSeries}
                    setFieldValue={setFieldValue}
                    currentSeries={currentSeries}
                    setFieldTouched={setFieldValue}
                  />
                  <Spacer size={18} />

                  <Dropzone
                    id="logo_id"
                    files={
                      values.logo_id
                        ? [{ name: values.logo_id, preview: values.logo }]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={errors.logo_id && touched.logo_id && errors.logo_id}
                    label="Series Logo"
                  />
                  <Dropzone
                    id="image_id"
                    files={
                      values.image_id
                        ? [{ name: values.image_id, preview: values.image }]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={
                      errors.image_id && touched.image_id && errors.image_id
                    }
                    label="Series Image"
                  />

                  <Dropzone
                    id="facebook_image_id"
                    files={
                      values.facebook_image_id
                        ? [
                            {
                              name: values.facebook_image_id,
                              preview: values.facebook_image,
                            },
                          ]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={
                      errors.facebook_image_id &&
                      touched.facebook_image_id &&
                      errors.facebook_image_id
                    }
                    label="Facebook Share Image"
                  />

                  <Dropzone
                    id="twitter_image_id"
                    files={
                      values.twitter_image_id
                        ? [
                            {
                              name: values.twitter_image_id,
                              preview: values.twitter_image,
                            },
                          ]
                        : []
                    }
                    onChange={setFieldValue}
                    setError={setFieldError}
                    error={
                      errors.twitter_image_id &&
                      touched.twitter_image_id &&
                      errors.twitter_image_id
                    }
                    label="Twitter Share Image"
                  />
                  <Input
                    id="name"
                    label="Name"
                    placeholder="Series Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    error={errors.name && touched.name && errors.name}
                  />

                  <Spacer size={18} />
                  <Input
                    id="short_name"
                    label="Series Short Name"
                    placeholder="Short Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.short_name}
                    error={
                      errors.short_name &&
                      touched.short_name &&
                      errors.short_name
                    }
                  />

                  <Spacer size={18} />
                  <Input
                    id="website"
                    label="Website URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                    error={errors.website && touched.website && errors.website}
                  />
                  <Spacer size={18} />
                  <Input
                    id="schedule"
                    label="Schedule URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.schedule}
                    error={
                      errors.schedule && touched.schedule && errors.schedule
                    }
                  />

                  <Spacer size={18} />
                  <Input
                    id="url"
                    label="Video"
                    placeholder="Video URL"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.url}
                    error={errors.url && touched.url && errors.url}
                  />

                  <Spacer size={18} />
                  <Select
                    id="type_id"
                    label="Series Type"
                    options={data.getSeriesTypes.map((type) => ({
                      value: type.id,
                      label: type.key,
                    }))}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.type_id}
                    error={errors.type_id && touched.type_id && errors.type_id}
                  />
                  <Spacer size={18} />
                  <Input
                    id="bio"
                    as="textarea"
                    label="Series Bio"
                    placeholder="Enter a short bio about the series"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bio}
                    error={errors.bio && touched.bio && errors.bio}
                  />
                  <Spacer size={18} />
                  <Label>Social</Label>
                  <RowSection leftText="Twitter">
                    <Input
                      id="twitter"
                      placeholder="Twitter Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.twitter}
                      error={
                        errors.twitter && touched.twitter && errors.twitter
                      }
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="Facebook">
                    <Input
                      id="facebook"
                      placeholder="Facebook Link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.facebook}
                      error={
                        errors.facebook && touched.facebook && errors.facebook
                      }
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="Instagram">
                    <Input
                      id="instagram"
                      placeholder="Instagram Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.instagram}
                      error={
                        errors.instagram &&
                        touched.instagram &&
                        errors.instagram
                      }
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="YouTube">
                    <Input
                      id="youtube"
                      placeholder="YouTube link"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.youtube}
                      error={
                        errors.youtube && touched.youtube && errors.youtube
                      }
                    />
                  </RowSection>
                  <Spacer size={10} />
                  <RowSection leftText="TikTok">
                    <Input
                      id="tiktok"
                      placeholder="TikTok Handle"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tiktok}
                      error={errors.tiktok && touched.tiktok && errors.tiktok}
                    />
                  </RowSection>

                  <Spacer size={10} />

                  <Label>Promoter Details</Label>
                  <Input
                    id="promoter_name"
                    name="promoter_name"
                    placeholder="Promoter Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_name}
                    error={errors.promoter_name}
                  />
                  <Spacer size={10} />
                  <Input
                    id="promoter_email"
                    name="promoter_email"
                    placeholder="promoter@email.com"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_email}
                    error={errors.promoter_email}
                  />
                  <Spacer size={10} />
                  <Input
                    id="promoter_phone"
                    name="promoter_phone"
                    placeholder="Phone Number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.promoter_phone}
                    error={errors.promoter_phone}
                  />

                  <Spacer size={18} />
                  {data.getAllUsers ? (
                    <AutoSuggest
                      name="user_id"
                      label="Series Owner"
                      value={data.getAllUsers.map((item) => {
                        return item.id === values.user_id
                          ? {
                              value: item.id,
                              label: item.name,
                            }
                          : null;
                      })}
                      error={errors.user_id}
                      touched={touched.user_id}
                      onChange={({ value }) => {
                        setFieldValue('user_id', value);
                      }}
                      onBlur={() => ''}
                      closeMenuOnSelect
                      options={data.getAllUsers.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  ) : null}
                  <Spacer size={18} />

                  <AutoSuggest
                    name="added_by"
                    label="Added By"
                    value={data.getAllUsers.map((item) => {
                      return item.id === values.added_by
                        ? {
                            value: item.id,
                            label: item.name,
                          }
                        : null;
                    })}
                    error={errors.added_by}
                    touched={touched.added_by}
                    onChange={({ value }) => {
                      setFieldValue('added_by', value);
                    }}
                    onBlur={() => ''}
                    closeMenuOnSelect
                    options={data.getAllUsers.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                  <Spacer size={18} />
                </div>
              </Content>

              <Content active={active === 2}>
                <SectionTitle>General Info</SectionTitle>
                <InfosRepeater
                  onChange={({ target, order }) => {
                    const newInfos = values.admin_infos.map((info) =>
                      info.order === order
                        ? {
                            ...info,
                            [target.name]: target.value,
                          }
                        : info
                    );
                    const newInfosWithOrder = values.infos_with_order.map(
                      (info) =>
                        info.order === order
                          ? {
                              ...info,
                              [target.name]: target.value,
                            }
                          : info
                    );
                    handleChange({
                      target: {
                        name: 'admin_infos',
                        value: newInfos,
                      },
                    });
                    handleChange({
                      target: {
                        name: 'infos_with_order',
                        value: newInfosWithOrder,
                      },
                    });
                  }}
                  onChangeInfo={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                  handleBlur={handleBlur}
                  values={values}
                  errors={errors}
                  touched={touched}
                  infos={values.admin_infos}
                  props={props}
                  handleSort={(name, value) => {
                    handleChange({
                      target: {
                        name: name,
                        value,
                      },
                    });
                  }}
                />
                <Spacer size={18} />
              </Content>

              <Content active={active === 3}>
                <div style={{ width: '60%', marginLeft: 10 }}>
                  <FaqList />

                  <Spacer size={18} />
                </div>
              </Content>
            </TabContent>
          </SeriesBody>
        </form>
      )}
    </Formik>
  );
};

export default compose(GetSeriesDetail, CreateSeries, UpdateSeries)(SeriesEdit);
