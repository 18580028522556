import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { PaymentCard } from 'components/PaymentCard';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import Images from 'images';
import StripeForm from 'pages/app/Account/Components/StripeForm';
import { useGetAccount } from 'pages/app/Accounts/gql/queries/useGetAccount';
import { AppContext } from 'shared/AppContext';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: row;
  border-bottom: 1px solid #d8d8d8;
  padding: 13px 15px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-evenly;
  text-align: left;
  padding: 20px;
  align-items: center;
`;

const Heading = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  max-width: ${(props) => (props.maxWidth ? '250px' : '100%')};
  align-items: center;
  gap: 10px;
`;

const CostContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
`;

const VideoLink = styled.div`
  text-decoration: underline;
  cursor: pointer;
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #d8d8d8;
  width: 90%;
  gap: 8px;
`;

const EditButton = styled.div`
  text-decoration: underline;
  color: grey;
  cursor: pointer;
`;

const BodyText = styled.div`
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: ${(props) => props.theme.colors.error};
  font-size: 16px;
  font-weight: 600;
`;

const StepsCount = {
  0: 'Step One',
  1: 'Step Two',
  2: 'Step Three',
  3: 'Step Four',
  4: 'Step Five',
  5: 'Step Six',
  6: 'Step Seven',
};

function capitalizeFirstLetter(string) {
  if (!string) return string; // Check if the string is empty or null
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const RefundEventContent = ({
  steps,
  step,
  setStep,
  selectedOptions,
  setSelectedOptions,
  onContinue,
  onOpenVideo,
  isMobile,
  theme,
  close,
  refundFeeData,
  event,
}) => {
  const { expense, options, video_url, name, label } = steps[step] ?? {};

  const isAdmin = window.location.pathname.includes('/admin/');

  const {
    state: { user },
  } = useContext(AppContext);

  const account = user?.accounts?.find((acc) => acc.id === event?.account_id);

  const { data } = useGetAccount(event?.account_id, account);

  const card = account?.card ? account.card : data?.getAccount?.card ?? '';

  const isCardCheckStep = step === steps.length;
  const isFinalStep = step === steps.length + 1;

  useEffect(() => {
    if (isCardCheckStep && (card || isAdmin)) {
      setStep(step + 1);
    }
  }, [card, isCardCheckStep, setStep, step, isAdmin]);

  const onSubmitCardData = () => {
    setStep(step + 1);
  };

  return (
    <>
      <HeaderContainer>
        <Text type="heading" inlineStyle={{ whiteSpace: 'normal' }}>
          Event Reimbursement
        </Text>
        <span style={{ color: `${theme.colors.primary}`, fontWeight: 600 }}>
          {isFinalStep && card ? StepsCount[step - 1] : StepsCount[step]}
        </span>
      </HeaderContainer>
      <Container>
        {step === 0 ? (
          <>
            <span style={{ width: '90%' }}>
              Over the next few steps we will walk you through how to handle the
              various purchasers to your event.
            </span>
            <Spacer size={20} />
          </>
        ) : null}

        {video_url && (
          <>
            <Row style={{ justifyContent: 'start' }}>
              <img src={Images.video} alt="Video" />
              <VideoLink
                onClick={onOpenVideo}
                style={{ color: theme.colors.primary }}
              >
                Learn more about these people.
              </VideoLink>
            </Row>
            <Spacer size={20} />
          </>
        )}

        {options && options.length > 0 && (
          <>
            <Row>
              <Heading>{label}:</Heading>
              <span></span>
            </Row>
            {options.map((option) => (
              <Row key={option.id}>
                <Checkbox
                  name="checkbox"
                  checked={selectedOptions[name] === option.value}
                  onChange={() => {
                    setSelectedOptions({
                      ...selectedOptions,
                      [name]: option.value,
                    });
                  }}
                  rightText={option.name}
                  size={[26, 26, 3]}
                />
              </Row>
            ))}
            <Spacer size={20} />
          </>
        )}

        {expense && selectedOptions[name] === 'refund' && (
          <>
            <CostContainer>
              <Row maxWidth>
                <Heading>Out of Pocket Costs:</Heading>
                <span></span>
              </Row>
              <Row maxWidth>
                Total Ticket Sales:{' '}
                <Text type="heading">{expense.sales_total}</Text>
              </Row>
              <Row maxWidth>
                Num of Transactions:{' '}
                <Text type="heading">{expense.transaction_count}</Text>
              </Row>
              <Row maxWidth>
                Total:{' '}
                <Text type="heading" color={theme.colors.primary}>
                  {expense.total}
                </Text>
              </Row>
            </CostContainer>
            <Spacer size={20} />
          </>
        )}

        {isCardCheckStep && account?.card && (
          <>
            <span style={{ width: '90%' }}>
              Because this request contains "Out of Pocket" expenses we need a
              card on file for your account.
            </span>

            <StripeForm account={account} onContinue={onSubmitCardData} />
          </>
        )}

        {isFinalStep && !refundFeeData && <Loading />}

        {isFinalStep &&
          refundFeeData &&
          refundFeeData?.calculateEventRefundFees && (
            <>
              <BodyText style={{ width: '90%' }}>
                Please review your selections to complete this reimbursement.
              </BodyText>

              <Spacer size={10} />
              {Object.keys(selectedOptions)?.map((key, index) => (
                <StepsWrapper key={index}>
                  <Row>
                    <BodyText style={{ fontWeight: '900' }}>{`Step ${
                      index + 1
                    }:`}</BodyText>
                    <EditButton onClick={() => setStep(index)}>Edit</EditButton>
                  </Row>

                  <BodyText>{`${capitalizeFirstLetter(selectedOptions[key])} ${
                    steps[index].label
                  }`}</BodyText>
                </StepsWrapper>
              ))}

              <Spacer size={20} />

              {account?.card && <PaymentCard card={account?.card} />}
              <Spacer size={20} />

              <Row>
                <BodyText style={{ fontWeight: '900' }}>
                  Total Out of Pocket Costs:
                </BodyText>
                <Text type="heading" color={theme.colors.primary}>
                  {refundFeeData?.calculateEventRefundFees}
                </Text>
              </Row>

              <Spacer size={20} />
            </>
          )}

        {!card && isAdmin && (
          <WarningContainer>
            <Icon icon="warning" size={16} color={theme.colors.error} /> No card
            is linked to this events account!
          </WarningContainer>
        )}

        {isCardCheckStep && !account?.card ? null : (
          <Row style={{ justifyContent: 'center' }}>
            <Button
              onClick={onContinue}
              disabled={!(selectedOptions[name] || step > steps.length - 1)}
            >
              Continue
            </Button>
            {!isMobile && (
              <Button onClick={close} outlined>
                Cancel
              </Button>
            )}
          </Row>
        )}
      </Container>
    </>
  );
};
