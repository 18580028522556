import React from 'react';
import styled from 'styled-components';
import { Button, IconButton } from 'components/Button';
import { IndicatorDots } from 'components/Carousel/IndicatorDots';
import { Modal } from 'components/Modal';
import Spacer from 'components/Spacer';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0dfdf;
  padding: 10px 0px 5px 0px;
  margin-top: 20px;
  max-height: 84px;
  width: 85%;
  margin: 0 auto;
`;

const Header = styled.span`
  font-family: Barlow Condensed;
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
`;

export const HelpModals = ({ isVisible, setIsVisible, contents }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const slidesToScroll = 1;
  const slideCount = contents.length;

  const goToSlide = (slide) => {
    setCurrentSlide(slide);
  };

  const onClose = () => {
    setIsVisible(false);
    setCurrentSlide(0);
  };

  return (
    <Modal
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      maxWidth={500}
      height={565}
      modalStyle={{
        borderRadius: '8px',
        minHeight: 565,
        maxHeight: 565,
        position: 'fixed',
        bottom: 20,
        right: 20,
        top: 'unset',
        display: 'flex',
        flexDirection: 'column',
        background: 'transparent',
      }}
      closable={false}
      noHeader={true}
    >
      <div
        style={{ alignSelf: 'flex-end', marginRight: 10 }}
        onClick={onClose}
        role="presentation"
      >
        <IconButton
          onClick={onClose}
          icon="close"
          size="normal"
          backgroundColor="white"
          style={{
            cursor: 'pointer',
            background: 'white',
            borderRadius: '50%',
            padding: 5,
            width: 28,
            height: 28,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            boxShadow: 'none',
          }}
          color="#5b372d"
          hoverColor="#5b372d"
        />
      </div>

      <Spacer height={10} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          flex: 1,
          borderRadius: '8px',
          background: 'white',
        }}
      >
        {contents[currentSlide]?.video && (
          <iframe
            style={{
              width: '100%',
              height: '280px',
              borderRadius: '8px 8px 0 0',
            }}
            src={contents[currentSlide]?.video}
            allow="autoplay; fullscreen"
            allowFullScreen
            title={contents[currentSlide]?.title ?? 'Help Video'}
          />
        )}
        <div
          style={{
            padding: 20,
            textAlign: 'start',
            textJustify: 'initial',
            ...contents[currentSlide]?.customStyle,
          }}
        >
          {contents[currentSlide]?.title ? (
            <Header style={contents[currentSlide].titleStyle}>
              {contents[currentSlide].title}
            </Header>
          ) : null}
          <Spacer height={20} />

          {contents[currentSlide]?.content ? (
            <div>{contents[currentSlide].content}</div>
          ) : null}
        </div>

        {!contents[currentSlide]?.hideFooter && (
          <Footer>
            {currentSlide > 0 ? (
              <Button
                outlined={true}
                onClick={() => goToSlide(currentSlide - 1)}
                buttonStyle={{ height: 44, borderRadius: 8 }}
              >
                Back
              </Button>
            ) : (
              <div style={{ minWidth: 87 }}>
                <Spacer width={100} />
              </div>
            )}

            <IndicatorDots
              currentSlide={currentSlide}
              slideCount={slideCount}
              slidesToScroll={slidesToScroll}
              goToSlide={goToSlide}
              inactiveColor="#FA461633"
              customStyle={{ height: 8, width: 8 }}
            />

            <Button
              onClick={() => goToSlide(currentSlide + 1)}
              buttonStyle={{ height: 44, borderRadius: 8 }}
            >
              {currentSlide === contents.length - 2 ? 'Got it' : 'Next'}
            </Button>
          </Footer>
        )}
      </div>
    </Modal>
  );
};
