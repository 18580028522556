import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Button } from 'components/Button';
import { getTickets } from './PrintTickets';

const formatLineItem = ({ name, price }) => {
  //48
  //32
  const lineLength = 48;
  let final_name = '';
  const priceLength = price.length;
  const nameCharacterLength = 35 - priceLength;

  if (nameCharacterLength < name.length) {
    final_name = `${name.substring(0, nameCharacterLength - 5)}..`.padEnd(
      lineLength - priceLength,
      ' '
    );
  } else {
    final_name = name.padEnd(lineLength - priceLength, ' ');
  }

  return `${final_name}${price}`;
};

const PrintReceipt = ({
  connectionStatus,
  printer,
  setIsPrinting,
  transaction,
}) => {
  const STATUS_CONNECTED = 'Connected';
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const printReceipt = () => {
    // Open Ip reader modal
    // onClick();

    // Display printing modal
    setIsPrinting(true);

    const prn = printer.current;

    if (!prn) {
      alert('Not connected to printer');
      return;
    }

    const tickets = getTickets(transaction);

    try {
      prn.addTextAlign(prn.ALIGN_CENTER);

      prn.addTextSmooth(true);

      prn.addLogo(34, 34);

      prn.addFeedLine(1);
      prn.addText('Gandrud Auto Group 350');

      prn.addFeedLine(1);
      prn.addText('@');
      prn.addFeedLine(1);
      prn.addText('Talladega Short Track and Raceway Park');

      prn.addTextAlign(prn.ALIGN_LEFT);

      prn.addFeedLine(1);

      prn.addText('------------------------------------------------');
      prn.addFeedLine(1);

      prn.addTextAlign(prn.ALIGN_LEFT);

      tickets.forEach((ticket) => {
        prn.addText(formatLineItem(ticket));
        prn.addFeedLine(1);
      });
      prn.addText('------------------------------------------------');
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({ name: '***TOTAL', price: transaction.total })
      );
      prn.addFeedLine(1);
      prn.addText('------------------------------------------------');
      prn.addText(
        formatLineItem({
          name: '***PAYMENT METHOD',
          price: transaction.payment_method.toUpperCase(),
        })
      );
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({
          name: '***ORDER #',
          price: transaction.id.toString(),
        })
      );
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({
          name: '***SOLD BY',
          price: transaction.register
            ? transaction.register.toUpperCase()
            : 'APP',
        })
      );
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({
          name: '***DELIVERY METHOD',
          price: transaction.delivery_method.toUpperCase(),
        })
      );
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({
          name: '***ORDER SOURCE',
          price: transaction.order_source.toUpperCase(),
        })
      );
      prn.addFeedLine(1);
      prn.addText(
        formatLineItem({
          name: '***TIMESTAMP',
          price: '11/5/2024 10:08 AM EST',
        })
      );
      prn.addFeedLine(5);
      prn.addCut(prn.CUT_FEED);

      prn.send();
    } catch (error) {
      alert(error);
    }
  };
  return (
    <Button
      buttonStyle={
        isMobile ? { width: '100px', margin: '0 10px' } : { margin: '0 10px' }
      }
      onClick={() => {
        printReceipt();
      }}
      disabled={connectionStatus !== STATUS_CONNECTED}
    >
      PRINT RECEIPT
    </Button>
  );
};

export default PrintReceipt;
