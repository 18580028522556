// import moment from 'moment';
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import Spacer from 'components/Spacer';
import DirectPrint from './DirectPrint';
// import { PrintReceipt } from './PrintReceipt';
// import TicketQR from './Ticket QR.png';
// import { PrintReceipt } from './PrintReceipt';

export const Receipt = () => {
  const imageUrl =
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAklEQVR4AewaftIAAAKuSURBVO3BQW7sWAwEwSxC979yjpdcPUCQusfmZ0T8wRqjWKMUa5RijVKsUYo1SrFGKdYoxRqlWKMUa5RijVKsUYo1SrFGKdYoFw8l4ZtUuiR0Km9KwjepPFGsUYo1SrFGuXiZypuScKLSJaFTOUlCp3Ki8qYkvKlYoxRrlGKNcvFhSbhD5Y4kdCrflIQ7VD6pWKMUa5RijXLxx6l0SThR6VQmKdYoxRqlWKNcDKfSJeFE5S8r1ijFGqVYo1x8mMonJaFT+SaV36RYoxRrlGKNcvGyJPwmSehUuiTckYTfrFijFGuUYo1y8ZDK/0nlk1T+kmKNUqxRijXKxUNJ6FS6JHQqdyThRKVLwh0qdyShUzlJQqfSJaFTeaJYoxRrlGKNEn/wiyShU3kiCScqXRI6lS4Jb1J5U7FGKdYoxRrl4suScEcSOpU3JeEkCZ3KSRI6lS4Jn1SsUYo1SrFGuXhZEu5Q6ZLQqXRJuEOlS0Kn0iXhm5LQqTxRrFGKNUqxRrl4KAl3qNyRhE6lS0Kn0iXhJAmdyh1JuEOlS8KbijVKsUYp1ijxB39YEu5QuSMJJyq/SbFGKdYoxRrl4qEkfJPKJyXhRKVLwptUnijWKMUapVijXLxM5U1JOFF5Igmdyh0qXRLuUHlTsUYp1ijFGuXiw5Jwh8oTSThR6VS6JJyo3KFykoRO5YlijVKsUYo1ysU/Jgmdyh1J6FQ6lS4JnUqn8qZijVKsUYo1ysUfl4QTlS4JXRJOVN6UhBOVJ4o1SrFGKdYoFx+m8kkqJ0k4UemS0CWhU+mS8ITKm4o1SrFGKdYoFy9LwjcloVPpVLokdEn4JJUuCV0SOpUnijVKsUYp1ijxB2uMYo1SrFGKNUqxRinWKMUapVijFGuUYo1SrFGKNUqxRinWKMUapVij/AfPegD+wq4NYAAAAABJRU5ErkJggg==';

  const component = (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        border: 'solid 2px black',
        textAlign: 'center',
      }}
    >
      <Spacer size={5} />
      <LinesEllipsis
        text="Adult General Admission"
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="words"
        style={{
          alignItems: 'center',
          fontFamily: 'Barlow Condensed',
          fontWeight: 'bolder',
          fontSize: 28,
          justifyContent: 'center',
          textAlign: 'center',
        }}
      />

      <Spacer size={10} />
      <span
        style={{
          backgroundColor: 'black',
          color: 'white',
          borderRadius: 4,
          display: 'inline',
          marginInline: 'auto',
          padding: '2px 4px',
          fontSize: 14,
        }}
      >
        00310317 RZBV
      </span>

      <Spacer size={10} />
      <div style={{ marginInline: 'auto' }}>
        <img
          alt="Radar"
          src={imageUrl}
          width="90"
          height="90"
          style={{ objectFit: 'cover' }}
        />
      </div>

      <Spacer size={10} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            fontWeight: 'bold',
            fontSize: 14,
            justifyContent: 'end',
          }}
        >
          <span>SAT</span> <span>MAY</span>
        </div>
        <span style={{ fontSize: 36, lineHeight: '32px', fontWeight: 'bold' }}>
          19
        </span>
      </div>

      <Spacer size={10} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          fontSize: 14,
          justifyContent: 'center',
        }}
      >
        <span>2024 Season Opener</span>
        <span style={{ fontWeight: 'normal', fontSize: 10, marginTop: 3 }}>
          Bakersfield Speedway
        </span>
      </div>

      <Spacer size={10} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          fontWeight: 'bold',
          fontSize: 14,
          justifyContent: 'center',
        }}
      >
        <span>Warning Risks</span>
        <span style={{ fontWeight: 'normal', fontSize: 10, marginTop: 3 }}>
          www.TicketHoss.com/Risks
        </span>
      </div>
    </div>
  );
  return (
    <div
      style={{
        display: 'flex',
        margin: 'auto',
        flexDirection: 'column',
        marginTop: '10%',
        textAlign: 'center',
      }}
    >
      <DirectPrint content={component} />

      {/* <PrintReceipt date={moment()} text="Print Image Directly" /> */}
    </div>
  );
};
