import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import React from 'react';
import { Button } from 'components/Button';
import { Input } from 'components/Form/Input';
import Loading from 'components/Loading';
import { MainContainer } from './Styles';

export const StripeFields = ({
  handleSubmit,
  errorMessage,
  loading,
  zipCode,
  setZipCode,
  onContinue,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <MainContainer>
        <div>
          <label htmlFor="card-number">Card Number</label>
          <CardNumberElement id="card-number" className="StripeElement" />
        </div>
        <div>
          <label htmlFor="card-expiry">Expiry Date</label>
          <CardExpiryElement id="card-expiry" className="StripeElement" />
        </div>
        <div>
          <label htmlFor="card-cvc">CVC</label>
          <CardCvcElement id="card-cvc" className="StripeElement" />
        </div>
        <div>
          <label htmlFor="card-zip">ZIP Code</label>
          <Input
            id="card-zip"
            type="text"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="StripeElement"
            placeholder="ZIP Code"
          />
        </div>
      </MainContainer>

      {errorMessage && <div>{errorMessage}</div>}
      {loading ? (
        <Loading />
      ) : (
        <Button type="submit" disabled={loading}>
          {onContinue ? 'Continue' : 'Save'}
        </Button>
      )}
    </form>
  );
};
