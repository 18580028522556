import moment from 'moment';
import React from 'react';
import { Modal } from 'components/Modal';
import { formatCustomDate, formatEventTimestamp } from 'shared/formatters';
import { useGetAllTickets } from '../gql';
import { Item, Wrapper, List, Label, Info, Flex } from './passesModalStyle';

export const PassesModal = ({ showModal, close, event }) => {
  const { data } = useGetAllTickets();
  const tickets = data?.getAllTickets;

  if (!tickets) return false;

  return (
    <Modal
      isVisible={showModal}
      hideModal={close}
      title={
        process.env.REACT_APP_PLATFORM !== 'tickethoss'
          ? 'Passes on Sale'
          : 'Tickets on Sale'
      }
    >
      <Wrapper showModal={showModal}>
        <List>
          {tickets.map((ticket) => {
            const {
              id,
              color_code,
              name,
              start_date,
              end_date,
              price,
              track_price,
              sale_start,
              sale_end,
              on_sale_time,
              off_sale_time,
              type,
            } = ticket;

            return (
              <Item key={id} color={color_code ?? ''}>
                <div style={{ flex: 1 }}>
                  <Flex>
                    <Label>Pass:</Label> <Info>{name.toUpperCase()}</Info>
                  </Flex>
                  <Flex>
                    <Label>Dates: </Label>
                    <Info>
                      {end_date && start_date
                        ? `${formatCustomDate(start_date, 'MM-DD-YYYY').format(
                            'MMM D'
                          )}-${formatCustomDate(end_date, 'MM-DD-YYYY').format(
                            'D - YYYY'
                          )}`.toUpperCase()
                        : start_date
                        ? formatCustomDate(start_date, 'MM-DD-YYYY')
                            .format('ddd MMM D - YYYY')
                            .toUpperCase()
                        : null}
                    </Info>
                  </Flex>
                  <Flex>
                    <Label>App Price:</Label>{' '}
                    <Info>{`$${price.toFixed(2)}`}</Info>
                  </Flex>
                  {track_price && (
                    <Flex>
                      <Label>Track Price:</Label>{' '}
                      <Info>{`$${track_price.toFixed(2)}`}</Info>
                    </Flex>
                  )}
                  {sale_start && (
                    <Flex>
                      <Label>Sale Start:</Label>{' '}
                      <Info>
                        {sale_start
                          ? moment(sale_start).format('MM/DD/YYYY')
                          : ''}{' '}
                        {on_sale_time
                          ? formatEventTimestamp(
                              on_sale_time,
                              event?.track?.timezone
                            )
                          : sale_start
                          ? formatEventTimestamp(
                              '00:00:00',
                              event?.track?.timezone
                            )
                          : ''}
                      </Info>
                    </Flex>
                  )}
                  {sale_end && (
                    <Flex>
                      <Label>Sale End:</Label>{' '}
                      <Info>
                        {sale_end ? moment(sale_end).format('MM/DD/YYYY') : ''}{' '}
                        {off_sale_time
                          ? formatEventTimestamp(
                              off_sale_time,
                              event?.track?.timezone
                            )
                          : sale_end
                          ? formatEventTimestamp(
                              '00:00:00',
                              event?.track?.timezone
                            )
                          : ''}
                      </Info>
                    </Flex>
                  )}
                  {type && (
                    <Flex style={{ marginBottom: 0 }}>
                      <Label>Type:</Label>{' '}
                      <Info>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </Info>
                    </Flex>
                  )}
                </div>
              </Item>
            );
          })}
        </List>
      </Wrapper>
    </Modal>
  );
};
