import React from 'react';
import styled from 'styled-components';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import Loading from 'components/Loading';
import { useGetAllAccounts } from 'pages/app/Credits/gql/queries/useGetAllAccounts';

const FilterContainer = styled.div`
  min-width: 230px;
  margin-right: 10px;

  @media screen and (max-width: 860px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

const AccountFilter = ({
  accountFilter,
  setAccountFilter,
  label,
  customStyle,
}) => {
  const { data, loading } = useGetAllAccounts();
  return loading ? (
    <Loading size={50} />
  ) : data && data.getAllAccounts ? (
    <FilterContainer style={customStyle}>
      <AutoSuggest
        label={label && 'Account'}
        placeholder="Account"
        options={data.getAllAccounts.map((item) => ({
          value: item.id,
          label: item.business_dba,
        }))}
        value={accountFilter ? accountFilter : null}
        isSearchable
        isClearable
        onChange={(account) => {
          setAccountFilter(account ?? null);
        }}
      />
    </FilterContainer>
  ) : null;
};

export default AccountFilter;
