import React from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled, { withTheme } from 'styled-components';
import Anchor from 'components/Anchor';
import { DateCard } from 'components/DateCard';
import {
  EventTrackFilter,
  EventSeriesFilter,
  EventYearFilter,
  useEventYearFilter,
} from 'components/Events';
import { EventAccountFilter } from 'components/Events/Components/EventAccountFilter';
import Icon from 'components/Icon';
import MobileContainer from 'components/MobileContainer';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import Text from 'components/Text';
import { TransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { CardHeader } from 'pages/employee/Events/EventsList';
import { TrackName } from 'pages/employee/Events/style';

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`;

const CardInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EventsList = (props) => {
  const { data, match, currentPage, count, loading } = props;

  const { pathname } = useLocation();
  const isAdminEventList = pathname.includes('/admin/');

  const {
    trackFilter,
    setTrackFilter,
    seriesFilter,
    setSeriesFilter,
    accountFilter,
    setAccountFilter,
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useEventYearFilter();

  const showTitles = data.reduce((acc, event) => {
    if (true === acc) {
      return acc;
    }

    if (false === acc) {
      return event.track.id;
    } else {
      return acc !== event.track.id ? true : event.track.id;
    }
  }, false);

  return (
    <div>
      {!isAdminEventList ? (
        <EventYearFilter />
      ) : (
        <div style={{ padding: '0 18px' }}>
          <TransactionDateFilter
            maxWidth="300px"
            minWidth="190px"
            mobilePicker
            customStartDate={startDateFilter}
            customSetStartDateFilter={setStartDateFilter}
            customEndDate={endDateFilter}
            customSetEndDateFilter={setEndDateFilter}
          />
        </div>
      )}

      {isAdminEventList && (
        <EventTrackFilter
          trackFilter={trackFilter}
          setTrackFilter={setTrackFilter}
        />
      )}
      {isAdminEventList && (
        <EventSeriesFilter
          seriesFilter={seriesFilter}
          setSeriesFilter={setSeriesFilter}
        />
      )}
      {isAdminEventList && (
        <EventAccountFilter
          accountFilter={accountFilter}
          setAccountFilter={setAccountFilter}
        />
      )}
      {data.map((item) => (
        <Anchor to={`${match.url}/event/${item.id}`} key={item.id}>
          <MobileContainer>
            <FlexRow>
              <CardInfo>
                {showTitles && item?.track?.name ? (
                  <CardHeader>
                    <TrackName>{item.track.name}</TrackName>
                  </CardHeader>
                ) : null}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 100 }}>
                    <DateCard item={item} />
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <Text
                        type="heading"
                        fontSize={25}
                        lineHeight={28}
                        color="#fff"
                      >
                        {item.name}
                      </Text>
                      <Spacer size={10} />
                    </div>
                  </div>
                </div>
              </CardInfo>
              <div style={{ transform: 'rotate(-90deg)' }}>
                <Icon icon="chevron" size={22} color={'red'} />
              </div>
            </FlexRow>
          </MobileContainer>
        </Anchor>
      ))}
      <div style={{ backgroundColor: '#00001F', margin: 10, borderRadius: 5 }}>
        <Pagination
          count={loading ? 0 : count}
          perPage={15}
          currentPage={currentPage || 1}
          color={'#fff'}
        />
      </div>
      <Spacer size={40} />
    </div>
  );
};

export default withTheme(EventsList);
