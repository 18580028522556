import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const GET_RESERVE_ORDER_TICKETS = gql`
  query GetReserveOrderTickets($purchase_id: Int!) {
    getReserveOrderTickets(purchase_id: $purchase_id) {
      id
      name
      barcode
      section
      row
      seat
      ticket_holder {
        first_name
        last_name
        formatted_name
      }
    }
  }
`;

export function useGetReserveOrderTickets(purchase_id) {
  return useQuery(GET_RESERVE_ORDER_TICKETS, {
    variables: {
      purchase_id: purchase_id,
    },
    skip: !purchase_id,
    fetchPolicy: 'no-cache',
  });
}
