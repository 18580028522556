import React from 'react';
import styled from 'styled-components';

const VisaImage =
  'http://cdn.mcauto-images-production.sendgrid.net/909720bf791384b7/72dc204a-9092-4e87-baa3-4d1b6f805053/42x14.jpg';
const MasterImage =
  'http://cdn.mcauto-images-production.sendgrid.net/909720bf791384b7/1cc5f078-6500-4da9-aeb3-ffca65f71850/49x32.jpg';
const AmericanExpressImage =
  'http://cdn.mcauto-images-production.sendgrid.net/909720bf791384b7/e1b4c144-70f8-424c-aab6-d2f6707d8977/43x29.png';
const Discover =
  'http://cdn.mcauto-images-production.sendgrid.net/909720bf791384b7/72dc204a-9092-4e87-baa3-4d1b6f805053/42x14.jpg';

const CardDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: normal;
  border-color: #dcdcdc;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 0.94em 0.8em;
  box-sizing: border-box;
  background: white;
  align-items: center;
`;

export const PaymentCard = ({ card }) => {
  const imageSrc =
    card?.brand === 'Discover'
      ? Discover
      : card?.brand === 'MasterCard'
      ? MasterImage
      : card?.brand === 'American Express'
      ? AmericanExpressImage
      : VisaImage;

  return (
    <CardDiv>
      <span>
        {card?.brand} | **** {card?.last4}
      </span>

      <img src={imageSrc} alt="Card" height={22} width={43} />
    </CardDiv>
  );
};
