import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { PrintButton } from 'components/PrintButton';

const DirectPrint = ({ content }) => {
  const componentRef = React.useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Printable Document',
    onAfterPrint: () => {
      // Optionally, you can add additional actions after printing
    },
    pageStyle: `
      @page {
        size: 2.0in 3.5in;
        margin: 0; // Remove default margins
      }
      
      @media print {
        body {
          transform: scale(1); // Adjust the scale as needed
          transform-origin: top left;
        }
      }
    `,
  });

  // const handlePrintToPDF = () => {
  //   // const unit = 'in';
  //   // const orientation = 'portrait'; // portrait or landscape
  //   // const pageWidth = 2;
  //   // const pageHeight = 3.5;

  //   const doc = new jsPDF();
  //   //     {
  //   //   orientation: orientation,
  //   //   unit: unit,
  //   //   format: [pageWidth, pageHeight],
  //   // }

  //   const contentElement = componentRef.current;

  //   // Calculate the scale factor to fit content into the PDF page
  //   // const scaleFactor = Math.min(
  //   //   pageWidth / contentElement.offsetWidth,
  //   //   pageHeight / contentElement.offsetHeight
  //   // );

  //   // Use jsPDF to add the scaled content to the PDF
  //   doc.html(contentElement, {
  //     x: 0,
  //     y: 0,
  //     html2canvas: { scale: 1 },
  //     callback: () => {
  //       // Save or display the PDF
  //       doc.save('TestReceipt.pdf');
  //     },
  //   });
  // };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 20,
      }}
    >
      <div
        ref={componentRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '2in',
          height: '3.2in',
          padding: '5px',
          background: 'white',
        }}
      >
        {content}
      </div>

      <div>
        <PrintButton
          printReceipt={handlePrint}
          text={'Print Receipt'}
          variant="minimal"
        />
        {/* <button onClick={handlePrintToPDF}>Print to PDF</button> */}
      </div>
    </div>
  );
};

export default DirectPrint;
