import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  EventsTable,
  EventStatusColorSelect,
  EventYearFilter,
  useEventYearFilter,
} from 'components/Events';
import { useGetEventsAdmin } from 'components/Events/gql';
import { useGetTrackSeriesEventsAdmin } from 'components/Events/gql/TrackSeriesEvents.queries';
import { SearchableListContainer } from 'components/SearchableListContainer';
import { TransactionDateFilter } from 'components/Transactions/TransactionDateFilter';
import { EventHeader } from './Header';

const Events = ({ match, location }) => {
  const {
    startDateFilter,
    setStartDateFilter,
    endDateFilter,
    setEndDateFilter,
  } = useEventYearFilter();
  const history = useHistory();

  const isAdminEventList = location.pathname.includes('/admin');
  const eventType = location.pathname.includes('track')
    ? 'track'
    : location.pathname.includes('series')
    ? 'series'
    : 'events';

  const { data: trackData } = useGetTrackSeriesEventsAdmin(
    eventType,
    { skip: eventType === 'events' },
    '200'
  );

  const { data } = useGetEventsAdmin(eventType, {
    skip: eventType !== 'events',
  });

  const events =
    eventType === 'track' || eventType === 'series'
      ? trackData?.getEventsAdmin?.results
      : data?.getEventsAdmin?.results ?? [];

  if (!events) return null;

  const renderReadyStatus = (event) => (
    <EventStatusColorSelect
      eventId={event.id}
      statusColor={event.status_color}
    />
  );

  const getTitleBarContent = () => {
    if (isAdminEventList && eventType === 'events') {
      return (
        <TransactionDateFilter
          customStartDate={startDateFilter}
          customSetStartDateFilter={setStartDateFilter}
          customEndDate={endDateFilter}
          customSetEndDateFilter={setEndDateFilter}
        />
      );
    }
    return <EventYearFilter />;
  };

  return (
    <>
      <SearchableListContainer
        header={<EventHeader id={match.params.id ?? ''} type={eventType} />}
        title="Events"
        titleBarContent={getTitleBarContent()}
        searchInputPlaceholder="Search Events"
        paginated={eventType === 'events'}
        pageCount={data?.getEventsAdmin?.count ?? 0}
        onAddClick={() => history.push('/admin/events/addEvent')}
      >
        <EventsTable
          events={events}
          listType="admin-events"
          renderReadyStatus={
            isAdminEventList && eventType === 'events'
              ? renderReadyStatus
              : null
          }
        />
      </SearchableListContainer>
    </>
  );
};

export default Events;
