import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CHECKOUT_POS = gql`
  mutation POSRequest($input: POSRequestInput!) {
    posRequest(input: $input)
  }
`;

export function usePOSRequest() {
  const [posRequestMutation] = useMutation(CHECKOUT_POS);

  const posRequest = useCallback(
    ({ account_id, amount, reader_id }) => {
      return posRequestMutation({
        variables: {
          input: {
            account_id,
            amount: amount,
            ...(reader_id ? { reader_id: reader_id } : null),
          },
        },
        fetchPolicy: 'no-cache',
        skip: !amount || !account_id,
      });
    },
    [posRequestMutation]
  );

  return posRequest;
}
