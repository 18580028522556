import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { DateCard } from 'components/DateCard';
import { useRefundEvent } from 'components/Events';
import Icon from 'components/Icon';
import Text from 'components/Text';
import ToolTip from 'components/Tooltip';
import { useGetRefundStatus } from 'components/Transactions';
import useTheme from 'hooks/useTheme';
import Export from './export';
import { TicketReportPrint } from './TicketReportPrint/TicketReportPrint';
import { TransactionsPrintList } from './TransactionsPrintList';

const RefundEvent = styled.div`
  width: auto;
  padding: 10px;
  color: #fff;
  background-color: #fa4616;
  border-radius: 5px;
  font-family: 'Barlow Condensed';
  font-weight: 600;
  margin-left: 35px;
  font-size: 20px;
  cursor: pointer;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-inline: 10px;
`;

export const TransactionsListHeader = ({ event, location }) => {
  const { data } = useGetRefundStatus();
  const refundEvent = useRefundEvent();
  const theme = useTheme();
  const isTrackAdmin = location.pathname.includes('/admin-track/');

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 40,
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <DateCard
          item={event.getEvent}
          margin={'margin: 0 10px 0 0'}
          horizontal
        />
        <Text type="heading" fontSize={32} color={theme.colors.secondary}>
          {event.getEvent.name}
        </Text>
        {'postponed' === event.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
              transform: 'rotate(10deg)',
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
            alt="postponed-stamp"
          />
        ) : null}
        {'cancelled' === event.getEvent.status ? (
          <img
            style={{
              width: '20%',
              height: 'auto',
              display: 'block',
              marginLeft: 20,
            }}
            src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
            alt="cancelled-stamp"
          />
        ) : null}
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <InfoWrapper>
          <Export
            icon={<Icon icon="Export-Icon" size={40} />}
            eventId={event.getEvent.id}
            buttonText="Transaction Report CSV"
          />
          <ToolTip
            title="Search Criteria"
            info={true}
            content={
              'Export a CSV file with the list of transactions for the selected event.'
            }
          />
        </InfoWrapper>

        <InfoWrapper>
          <TransactionsPrintList
            date={
              event.getEvent.isMultiDay
                ? `${event.getEvent.fullMonth} ${
                    event.getEvent.listDates
                  } - ${moment(event.getEvent.end_date).format('YYYY')} `
                : event.getEvent.fullDate
            }
            eventId={event.getEvent.id}
            text="Transaction Report PDF"
          />
          <ToolTip
            title="Search Criteria"
            info={true}
            content={
              'Print a PDF file with the list of transactions for the selected event.'
            }
          />
        </InfoWrapper>

        <TicketReportPrint
          date={
            event.getEvent.isMultiDay
              ? `${event.getEvent.fullMonth} ${
                  event.getEvent.listDates
                } - ${moment(event.getEvent.end_date).format('YYYY')} `
              : event.getEvent.fullDate
          }
          eventId={event.getEvent.id}
          text="Ticket Breakdown PDF"
          textStyle={{ minWidth: 90 }}
        />

        {data && data.getEventRefundStatus ? (
          !isTrackAdmin ? (
            <RefundEvent
              onClick={async () => {
                if (
                  window.confirm('Are you sure you want to the entire event?')
                ) {
                  try {
                    const response = await refundEvent(event.getEvent.id);
                    if (!response || response.errors) {
                      toast.error('Event can not be refunded');
                      return;
                    }

                    if (response.data.refundEvent) {
                      toast.success('Event has been refunded');
                    } else {
                      toast.error('Event can not be refunded');
                    }
                  } catch (e) {}
                }
              }}
            >
              Refund Event
            </RefundEvent>
          ) : null
        ) : null}
      </div>
    </div>
  );
};
