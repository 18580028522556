import qs from 'qs';
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { HideOnMobile, ContainerWrapper } from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { DateCard } from 'components/DateCard';
import { Checkbox } from 'components/Form/Checkbox';
import { SearchInput } from 'components/Form/SearchInput';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import { CheckInModal } from 'components/Modal';
import { WaiverDownloadButton } from 'components/Participants';
import {
  useCheckInUser,
  useGetParticipantsList,
  useUncheckInUser,
} from 'components/Participants/gql';
import { CancelModal, PassesModal } from 'components/Participants/Modals';
import Spacer from 'components/Spacer';
import { WaiverNotSigned } from 'components/SVG/WaiverNotSigned';
import { Table, TableRow } from 'components/Table';
import Text from 'components/Text';
import { useDebounceCallback } from 'hooks/useDebounceCallback';
import useNewPurchase from 'hooks/useNewPurchase';
import useTheme from 'hooks/useTheme';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';
import DateDropdown from './dateDropdown';
import HeaderButtons from './HeaderButtons';

const TitleContainer = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
  }
`;

const HeaderWrapper = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px 40px 20px 40px;
  }
`;

const Header = styled.div`
  width: 80vw;
`;

const NameLink = styled(Link)`
  background-color: transparent;
  padding: 0;
  margin: 0;
  display: inline-block;
  color: ${(props) => props.theme.colors.primary};
  border: none;
  font-size: 16px;
  font-family: Roboto;
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    outline: 0;
  }
`;

const Tr = styled(TableRow)`
  & > :first-child {
    padding-left: 20px;
  }
  & > :last-child {
    padding-right: 20px;
  }
`;

const PassesButton = styled.button`
  padding: 0;
  margin-left: auto;
  margin-right: 40px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;

  &:focus {
    outline: 0;
  }
`;

const isFullyChecked = ({ tickets }) => {
  const isComplete = tickets.filter(
    (ticket) => ticket.is_checked || ticket.status === 'refunded'
  );
  return isComplete.length === tickets.length;
};

const IconWrapper = styled.span`
  transform: rotate(-90deg);
  display: block;
  margin-left: 10px;
`;

const Participants = (props) => {
  const { location, match } = props;
  const { date } = qs.parse(location.search.substring(1));
  const [search, setSearch] = useState('');
  const { data, subscribeToMore } = useGetParticipantsList({
    date,
    search,
  });

  const [showPermissionModal, setShowPermissionModal] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);

  const userType = props.pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : 'track';

  const {
    state: { capabilities },
  } = useContext(AppContext);

  const checkInUser = useCheckInUser();
  const uncheckInUser = useUncheckInUser();
  const [unCheck, setUncheck] = useState(false);
  const [purchaseItem, setPurchaseItem] = useState(false);

  const [shouldDisplayCheckInModal, setShouldDisplayCheckInModal] =
    useState(false);

  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSelectedEvent, setShowSelectedEvent] = useState({});
  const [showPasses, setShowPasses] = useState(false);
  const theme = useTheme();

  const eventId = parseInt(match.params.id, 10);
  useNewPurchase({ subscribeToMore }, eventId, search, date);

  const selectOne = async () => {
    return isFullyChecked(purchaseItem)
      ? await uncheckInUser([purchaseItem.pass_id])
      : await checkInUser(
          [purchaseItem.pass_id],
          () => null,
          () => null
        );
  };

  const adminTrack = location.pathname.indexOf('/admin-track/');

  const debouncedInputChange = useCallback(
    useDebounceCallback((value) => {
      setSearch(value);
    }, 1000),
    []
  );

  const handleChange = (event) => {
    const targetValue = event.target.value;

    debouncedInputChange(targetValue);
  };

  const handleBlur = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      setSearch(e.target.value);
    }
  };

  const participants = data?.getParticipantsList;
  const event = data?.getEvent;

  useEffect(() => {
    if (!event) return;

    setShowSelectedEvent({ ...event });
  }, [event]);

  const tableColumns = [
    {
      label: 'Check In',
      key: 'check_in',
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Duty',
      key: 'duty',
    },
    {
      label: 'Age',
      key: 'age',
    },
    {
      label: 'Promo',
      key: 'promo',
    },
    {
      label: 'Waiver',
      key: 'waiver',
    },
  ];

  function renderRows(participant) {
    const { status, name, is_pending, duty, age, promo_code, pass_id } =
      participant;
    return {
      rowProps: {
        highlight: is_pending ? 'orange' : undefined,
      },
      check_in: (
        <Checkbox
          inversed
          disabled={is_pending}
          name="checkbox1"
          checked={isFullyChecked(participant)}
          onChange={() => {
            setPurchaseItem(participant);
            setUncheck(isFullyChecked(participant));
            if (!capabilities || capabilities?.check_tickets)
              setShouldDisplayCheckInModal(true);
            else setShowPermissionModal(true);
          }}
          size={[36, 20, 3]}
          check={status !== 'refunded'}
        />
      ),
      name: (
        <NameLink
          highlight={is_pending ? 'orange' : undefined}
          to={(location) => ({
            ...location,
            pathname: `${location.pathname}/${participant.id}`,
          })}
        >
          {name.toUpperCase()}
        </NameLink>
      ),
      duty,
      age,
      promo: promo_code,
      waiver: is_pending ? (
        <WaiverNotSigned width={40} style={{ padding: 5 }} />
      ) : pass_id ? (
        <WaiverDownloadButton
          passId={pass_id}
          icon={
            <Icon icon="Waiver-Icon" color={theme.colors.primary} size={40} />
          }
        />
      ) : null,
    };
  }

  return !event ? (
    <Loading size={60} />
  ) : !participants || !event ? null : (
    <>
      <HeaderWrapper>
        <Header>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <DateCard item={event} margin={'margin: 0 10px 0 0'} />

            <Text type="heading" fontSize={32} color={theme.colors.secondary}>
              {event.name}
            </Text>

            {'postponed' === event.status ? (
              <img
                style={{
                  width: '10%',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 20,
                  transform: 'rotate(10deg)',
                }}
                src="https://d3294qt0f4hbwl.cloudfront.net/postponed.png"
                alt="postponed-stamp"
              />
            ) : null}
            {'cancelled' === event.status ? (
              <img
                style={{
                  width: '10%',
                  height: 'auto',
                  display: 'block',
                  marginLeft: 20,
                }}
                src="https://d3294qt0f4hbwl.cloudfront.net/cancelled.png"
                alt="cancelled-stamp"
              />
            ) : null}
          </div>
        </Header>
        <HeaderButtons
          event={event}
          date={date}
          match={match}
          setShowCancelModal={setShowCancelModal}
        />
        <div style={{ width: '20%', marginLeft: 40 }}>
          <img
            style={{ width: '100%', height: 'auto', display: 'block' }}
            src={event.track?.logo ?? event.series?.logo}
            alt="logo"
          />
        </div>
      </HeaderWrapper>
      <Container>
        <ContainerHeader>
          <ContainerWrapper>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25, marginBottom: 10 }}
              >
                Participants ({participants.length})
              </Text>
              <SearchInput
                placeholder="Search Participants"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
                value={search}
                style={{ width: 200 }}
              />
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginLeft: 15,
                }}
              >
                <Icon
                  style={{ marginTop: 7 }}
                  icon="Dashboard-Help-Icon"
                  onClick={() => setShowHelpModal(true)}
                  size={35}
                  color={theme.colors.primary}
                />
              </div>
            </TitleContainer>
            <DateDropdown />
            <PassesButton
              onClick={() => {
                setShowPasses(true);
              }}
            >
              <Text
                type="label"
                fontSize={18}
                color={theme.colors.secondary}
                inlineStyle={{ cursor: 'pointer' }}
              >
                {process.env.REACT_APP_PLATFORM !== 'tickethoss'
                  ? 'Passes on Sale'
                  : 'Tickets on Sale'}
              </Text>
              <IconWrapper>
                <Icon icon="chevron" size={24} color={theme.colors.primary} />
              </IconWrapper>
            </PassesButton>
          </ContainerWrapper>
        </ContainerHeader>
        <div style={{ padding: '20px 0' }}>
          <HideOnMobile>
            <Table
              items={participants ?? []}
              columns={tableColumns}
              Components={{ Row: Tr }}
              renderRows={renderRows}
              noData={
                <p
                  style={{
                    color: '#000',
                    fontSize: 20,
                    fontFamily: 'Barlow Condensed',
                    fontWeight: 600,
                    padding: 20,
                  }}
                >
                  The event does not have any participants yet
                </p>
              }
            />

            {showCancelModal && (
              <CancelModal
                adminTrack={adminTrack}
                showCancelModal={showCancelModal}
                close={() => {
                  setShowCancelModal(false);
                }}
                selectedEvent={showSelectedEvent}
                currentType={match.url.includes('track') ? 'track' : 'series'}
                objectId={match.params.id}
              />
            )}

            {showPasses && (
              <PassesModal
                showModal={showPasses}
                event={event}
                close={() => {
                  setShowPasses(false);
                }}
              />
            )}

            <CheckInModal
              itemType={
                process.env.REACT_APP_PLATFORM !== 'tickethoss'
                  ? 'Pass'
                  : 'Ticket'
              }
              isVisible={!!shouldDisplayCheckInModal}
              onConfirm={selectOne}
              setIsVisible={setShouldDisplayCheckInModal}
              unCheck={unCheck}
            />
          </HideOnMobile>
        </div>
        <PermissionModal
          isVisible={showPermissionModal}
          hideModal={() => setShowPermissionModal(false)}
          link={`/admin-${userType}/settings/1`}
          history={props.history}
        />

        <HelpModals
          isVisible={showHelpModal}
          setIsVisible={setShowHelpModal}
          contents={[
            {
              title: 'Explanation Part 1',
              content:
                'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
              video: 'https://player.vimeo.com/video/903075544?h=a35101967c',
            },
            {
              title: 'Explanation Part 2',
              content: (
                <div>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                  <Spacer size={16} />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              ),
            },
            {
              title: 'Explanation Part 3',
              content: (
                <span>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </span>
              ),
            },
            {
              title: 'Hide Help Like This?',
              titleStyle: {
                fontSize: 32,
                fontWeight: 700,
                lineHeight: '40px',
                fontFamily: 'Barlow Condensed',
              },
              content: (
                <div style={{ alignContent: 'center', textAlign: 'center' }}>
                  <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                    Click on any{' '}
                    <Icon
                      icon="Dashboard-Help-Icon"
                      size={28}
                      color={theme.colors.primary}
                    />{' '}
                    icon to bring it back.
                  </p>

                  <Spacer size={20} />
                  <Button onClick={() => setShowHelpModal(false)}>
                    {' '}
                    Hide For Today{' '}
                  </Button>

                  <Spacer size={10} />
                  <Button
                    onClick={() => setShowHelpModal(false)}
                    outlined={true}
                  >
                    {' '}
                    Hide Forever
                  </Button>

                  <Spacer size={10} />
                  <Button
                    onClick={() => setShowHelpModal(false)}
                    outlined={true}
                    buttonStyle={{ border: 'none' }}
                  >
                    {' '}
                    Hide Now
                  </Button>
                </div>
              ),
              hideFooter: true,
              customStyle: {
                justifyContent: 'center',
                margin: 'auto',
                textAlign: 'center',
              },
            },
          ]}
        />
      </Container>
    </>
  );
};

export default Participants;
