import React, { useState } from 'react';
import { Col } from 'react-grid-system';
import { Button } from 'components/Button';
import { Checkbox } from 'components/Form/Checkbox';
import { ResponsiveCol, ResponsiveRow } from '../../AddEvents/styles';
import { TieredPricingFields } from './TieredPricingFields';

export const TieredPricing = ({
  ticketTiers,
  setTicketTiers,
  handleChange,
  ticket_tiers,
}) => {
  const [ticketTiersData, setTicketTiersData] = useState(ticket_tiers);

  const handleAddTier = () => {
    setTicketTiersData((prev) => [
      ...prev,
      {
        label: '',
        price: '',
        track_price: '',
      },
    ]);
  };

  const handleRemoveTier = (index) => {
    const updatedTiers = ticketTiersData.filter((_, i) => i !== index);
    setTicketTiersData(updatedTiers);

    handleChange({
      target: {
        name: 'ticket_tiers',
        value: updatedTiers,
      },
    });
  };

  const handleTierChange = ({ index, name, value }) => {
    const updatedTiers = ticketTiersData.map((tier, i) => {
      if (index === i) {
        return {
          ...tier,
          [name]: value,
        };
      }
      return tier;
    });

    setTicketTiersData(updatedTiers);

    handleChange({
      target: {
        name: 'ticket_tiers',
        value: updatedTiers,
      },
    });
  };

  return (
    <>
      <ResponsiveRow>
        <ResponsiveCol>
          <Checkbox
            myStyle={{
              marginBottom: '20px',
              marginRight: '15px',
              display: 'inline-block',
            }}
            name="ticket_tiers"
            className="ticket_tiers"
            checked={ticketTiers}
            onChange={(event) => {
              const value = event.target?.checked;
              setTicketTiers(value);
              handleChange({
                target: {
                  name: 'ticket_tiers',
                  value,
                },
              });
            }}
            rightText="Tiered Pricing"
          />
        </ResponsiveCol>
        <Col></Col>
      </ResponsiveRow>
      {ticketTiers && (
        <>
          <ResponsiveRow>
            <Col></Col>
            <Col></Col>
            <ResponsiveCol
              style={{
                flex: 2,
                justifyContent: 'end',
                display: 'flex',
              }}
            >
              <Button onClick={handleAddTier} type="button">
                Add
              </Button>
            </ResponsiveCol>
            <Col></Col>
          </ResponsiveRow>
          {ticketTiersData.map((tier, i) => (
            <TieredPricingFields
              key={i}
              tier={tier}
              index={i}
              handleChange={handleTierChange}
              handleRemoveTier={handleRemoveTier}
            />
          ))}
        </>
      )}
    </>
  );
};
