import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const query = gql`
  query GetRefundSteps($event_id: Int!) {
    getRefundSteps(event_id: $event_id) {
      name
      options {
        name
        value
      }
      video_url
      expense {
        sales_total
        transaction_count
        total
      }
      label
    }
  }
`;

export function useGetRefundSteps(isVisible) {
  const { id } = useParams();

  return useQuery(query, {
    variables: { event_id: +id },
    skip: !id || !isVisible,
  });
}
