import React from 'react';
import Icon from 'components/Icon';
import { Modal } from 'components/Modal';
import Text from 'components/Text';
import { VimeoVideo } from 'components/Video';
import { HeaderContainer } from './RefundEventContent';

function extractVimeoID(url) {
  const regex = /vimeo\.com\/(\d+)/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const RefundVideo = ({ url, isVisible, close }) => {
  if (!isVisible || !url) return null;

  return (
    <Modal
      maxWidth={500}
      isVisible={isVisible}
      close={close}
      hideModal={close}
      noHeader
      modalStyle={{ overflow: 'scroll', padding: 20, paddingBottom: 40 }}
    >
      <HeaderContainer
        style={{ justifyContent: 'space-between', borderBottom: 'none' }}
      >
        <Text type="heading">Who are this people?</Text>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            close();
          }}
        />
      </HeaderContainer>

      <VimeoVideo vimeoId={extractVimeoID(url)} />
    </Modal>
  );
};
