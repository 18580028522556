import { sign } from 'jsonwebtoken';

// Generate a jwt token with a secret key and a value
// add 7 random characters to the end of the token
// send the token as a header key called secret_key
export const generateSekretKey = async (payload) => {
  const secretKey = process.env.REACT_APP_SECRET_KEY;
  const value = process.env.REACT_APP_SECRET_VALUE;
  const randomChars = generateRandomChars(7);

  const token = sign(payload ? payload : { platform: value }, secretKey, {
    algorithm: 'HS256',
  });

  return token + randomChars;
};

// Function to generate random characters
const generateRandomChars = (length) => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};
