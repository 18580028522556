import React, { useContext, useState } from 'react';
import { useLocation, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import { Button, LinkButton } from 'components/Button';
import {
  CardText,
  Card,
  HideOnMobile,
  HideOnDesktop,
} from 'components/Card/cardStyle';
import Container from 'components/Container';
import ContainerHeader from 'components/ContainerHeader';
import { EventYearFilter, withEventYearFilterContext } from 'components/Events';
import { useGetUserEvents } from 'components/Events/gql';
import { SearchInput } from 'components/Form/SearchInput';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import LineHeightText from 'components/LineHeightText';
import Loading from 'components/Loading';
import { ModalSmsNotification } from 'components/ModalSmsNotification';
import Pagination from 'components/Pagination';
import Spacer from 'components/Spacer';
import {
  TableComponent as Table,
  TableRow,
  TableCell,
  TableBody,
  TableHeader,
} from 'components/Table';
import Text from 'components/Text';
import {
  GuestForm,
  GuestListsButtonWrapper,
  useGuestSearch,
  withGuestSearchContext,
} from 'pages/track/Guests';
import PermissionModal from 'pages/track/PermissionModal/PermissionModal';
import { AppContext } from 'shared/AppContext';

const TitleContainer = styled.div`
  @media (min-width: 860px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  height: 100%;

  @media (max-width: 860px) {
    height: auto;
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const Page = withEventYearFilterContext(
  withGuestSearchContext(({ theme, history }) => {
    const [search, setSearch] = useState('');

    const {
      state: { capabilities },
    } = useContext(AppContext);

    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const [guestId, setGuestId] = useState(null);
    const [newGuestData, setNewGuestData] = useState(null);
    const { guestSearchQuery: queryString } = useGuestSearch();

    const [isVisible, setIsVisible] = useState(false);
    const [showHelpModal, setShowHelpModal] = useState(false);

    const handleOutClick = () => {
      setIsVisible(!isVisible);
      setGuestId(null);
    };

    async function onSetNewGuestData(guest) {
      setNewGuestData(guest);
    }

    const { pathname } = useLocation();
    const userType = pathname.split('/').includes('admin-employee')
      ? 'employee'
      : 'track';

    const { data, loading } = useGetUserEvents();

    const handleChange = (e) => setSearch(e.target.value);

    const handleBlur = (e) => {
      history.push(
        `/admin-${userType}/guest-lists/?queryString=${e.target.value}`
      );
    };

    const handleKeyPress = (e) => {
      if (e.keyCode === 13) {
        history.push(
          `/admin-${userType}/guest-lists/?queryString=${e.target.value}`
        );
      }
    };

    const urlParams = new URLSearchParams(window.location.search);
    const currentPage = parseInt(urlParams.get('page'));

    const events = data?.getEventsByAccount?.results ?? [];

    const showTracks = events.reduce((acc, event) => {
      if (true === acc) {
        return acc;
      }

      if (false === acc) {
        return event.track.id;
      } else {
        return acc !== event.track.id ? true : event.track.id;
      }
    }, false);

    return (
      <div>
        <Container>
          <ContainerHeader>
            <TitleContainer>
              <Text
                type="heading"
                color="#3C4144"
                inlineStyle={{ marginRight: 25 }}
              >
                Events
              </Text>
              <SearchInput
                placeholder="Search Events"
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyPress}
                value={search}
              />
              <EventYearFilter />
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginRight: 20,
                }}
              >
                <Icon
                  style={{ marginTop: 7 }}
                  icon="Dashboard-Help-Icon"
                  onClick={() => setShowHelpModal(true)}
                  size={35}
                  color={theme.colors.primary}
                />
              </div>
            </TitleContainer>

            <ButtonContainer>
              <div
                style={{
                  display: 'block',
                  height: '100%',
                  paddingTop: 20,
                  marginRight: 20,
                }}
              >
                <Button
                  onClick={() => {
                    capabilities?.add_guests
                      ? setIsVisible(!isVisible)
                      : setShowPermissionModal(true);
                  }}
                  fontSize={20}
                >
                  Add a new Guest
                </Button>
              </div>
              <GuestListsButtonWrapper
                style={{
                  marginRight: 5,
                  height: '100%',
                  paddingTop: 20,
                  minWidth: 160,
                }}
              >
                <LinkButton to={`/admin-${userType}/guests`}>
                  View All Guests
                </LinkButton>
              </GuestListsButtonWrapper>
            </ButtonContainer>
          </ContainerHeader>

          <div style={{ padding: 20 }}>
            {loading ? (
              <Loading />
            ) : (
              <>
                <HideOnMobile>
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableCell scope="col">Event</TableCell>
                        {true === showTracks ? (
                          <TableCell scope="col">Track</TableCell>
                        ) : null}
                        <TableCell scope="col">Date</TableCell>
                        <TableCell scope="col">
                          <LineHeightText>Gate Time</LineHeightText>
                        </TableCell>
                        <TableCell scope="col">Status</TableCell>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {events.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Link
                              style={{ color: theme.colors.primary }}
                              to={`/admin-${userType}/guest-lists/event/${item.id}`}
                            >
                              <LineHeightText>{item.name}</LineHeightText>
                            </Link>
                          </TableCell>
                          {true === showTracks ? (
                            <TableCell>
                              <LineHeightText>{item.track.name}</LineHeightText>
                            </TableCell>
                          ) : null}
                          <TableCell>
                            <LineHeightText>
                              {item.isMultiDay
                                ? `${item.day} - ${item.month.toUpperCase()} ${
                                    item.listDates
                                  } - ${item.year}`
                                : `${item.day} - ${item.date} - ${item.year}`}
                            </LineHeightText>
                          </TableCell>
                          <TableCell>
                            <LineHeightText>{item.nextGateTime}</LineHeightText>
                          </TableCell>
                          <TableCell>
                            <LineHeightText>
                              {item.status.toUpperCase()}
                            </LineHeightText>{' '}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </HideOnMobile>
                <HideOnDesktop>
                  {events.map((item) => (
                    <Card key={item.id}>
                      <Link
                        style={{
                          color: theme.colors.primary,
                          fontSize: 18,
                          marginBottom: 10,
                          display: 'block',
                          lineHeight: '30px',
                        }}
                        to={`/admin-${userType}/guest-lists/event/${item.id}`}
                      >
                        {item.name}
                      </Link>
                      <CardText>
                        Date:{' '}
                        {item.isMultiDay
                          ? `${item.day} - ${item.month} ${item.listDates} - ${item.year}`
                          : `${item.day} - ${item.month} ${item.date} - ${item.year}`}
                      </CardText>
                      <CardText>Gate Time: {item.nextGateTime} </CardText>
                    </Card>
                  ))}
                </HideOnDesktop>
              </>
            )}
          </div>
          <Pagination
            count={data?.getEventsByAccount?.count ?? 0}
            perPage={15}
            currentPage={currentPage || 1}
          />
        </Container>

        <GuestForm
          isVisible={isVisible}
          handleOutClick={handleOutClick}
          guestId={guestId}
          queryString={queryString}
          setNewGuestData={onSetNewGuestData}
          theme={theme}
        />

        <ModalSmsNotification
          isVisible={newGuestData !== null}
          cancelText="Close"
          title="New Guest Added"
          cellphone={newGuestData?.phone_number}
          body={`${newGuestData?.first_name} ${newGuestData?.last_name}, has been added, you can send them a message at this time.`}
          hideModal={() => {
            setNewGuestData(null);
          }}
        />

        <PermissionModal
          isVisible={showPermissionModal}
          hideModal={() => setShowPermissionModal(false)}
          link={`/admin-${userType}/settings/2`}
          history={history}
        />

        <HelpModals
          isVisible={showHelpModal}
          setIsVisible={setShowHelpModal}
          contents={[
            {
              title: 'Explanation Part 1',
              content:
                'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
              video: 'https://player.vimeo.com/video/903075544?h=a35101967c',
            },
            {
              title: 'Explanation Part 2',
              content: (
                <div>
                  <p>
                    Lorem ipsum is placeholder text commonly used in the
                    graphic, print, and publishing industries for previewing
                    layouts and visual mockups.
                  </p>
                  <Spacer size={16} />
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </p>
                </div>
              ),
            },
            {
              title: 'Explanation Part 3',
              content: (
                <span>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </span>
              ),
            },
            {
              title: 'Hide Help Like This?',
              titleStyle: {
                fontSize: 32,
                fontWeight: 700,
                lineHeight: '40px',
                fontFamily: 'Barlow Condensed',
              },
              content: (
                <div style={{ alignContent: 'center', textAlign: 'center' }}>
                  <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                    Click on any{' '}
                    <Icon
                      icon="Dashboard-Help-Icon"
                      size={28}
                      color={theme.colors.primary}
                    />{' '}
                    icon to bring it back.
                  </p>

                  <Spacer size={20} />
                  <Button onClick={() => setShowHelpModal(false)}>
                    {' '}
                    Hide For Today{' '}
                  </Button>

                  <Spacer size={10} />
                  <Button
                    onClick={() => setShowHelpModal(false)}
                    outlined={true}
                  >
                    {' '}
                    Hide Forever
                  </Button>

                  <Spacer size={10} />
                  <Button
                    onClick={() => setShowHelpModal(false)}
                    outlined={true}
                    buttonStyle={{ border: 'none' }}
                  >
                    {' '}
                    Hide Now
                  </Button>
                </div>
              ),
              hideFooter: true,
              customStyle: {
                justifyContent: 'center',
                margin: 'auto',
                textAlign: 'center',
              },
            },
          ]}
        />
      </div>
    );
  })
);

export default withRouter(withTheme(Page));
