import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useParams } from 'react-router-dom';
import { useSearchInput } from 'hooks/useSearchInput';

export const GET_RESERVE_ORDERS = gql`
  query GetReserveOrders($input: GetReserveOrdersInput!) {
    getReserveOrders(input: $input) {
      count
      results {
        id
        purchase_date
        user {
          first_name
          last_name
          formatted_name
        }
        total
      }
    }
  }
`;

export function useGetReserveOrders(searchBy) {
  const { input } = useSearchInput();

  const { id: idParam } = useParams();
  const id = idParam ? Number(idParam) : null;

  return useQuery(GET_RESERVE_ORDERS, {
    variables: {
      input: {
        event_id: id,
        ...(searchBy && input.queryString ? { search_by: searchBy } : null),
        ...input,
      },
    },
    skip: !id,
    fetchPolicy: 'no-cache',
  });
}
