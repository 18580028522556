import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const MOBILE_DELIVERY = gql`
  mutation mobileDelivery($input: MobileDeliveryInput!) {
    mobileDelivery(input: $input)
  }
`;

export function useMobileDelivery() {
  const [mobileDeliveryMutation] = useMutation(MOBILE_DELIVERY);

  const mobileDelivery = useCallback(
    (input) => {
      return mobileDeliveryMutation({
        variables: {
          input: input,
        },
        fetchPolicy: 'no-cache',
        skip: !input.cellphone,
      });
    },
    [mobileDeliveryMutation]
  );

  return mobileDelivery;
}
