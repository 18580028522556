import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const CANCEL_POS = gql`
  mutation PosCancel($input: POSCancelInput!) {
    posCancel(input: $input)
  }
`;

export function useCancelPOS() {
  const [posCancelMutation] = useMutation(CANCEL_POS);

  const posCancel = useCallback(
    ({ payment_intent, reader_id }) => {
      return posCancelMutation({
        variables: {
          input: {
            payment_intent: payment_intent,
            reader_id: reader_id,
          },
        },
        fetchPolicy: 'no-cache',
        skip: !payment_intent || !reader_id,
      });
    },
    [posCancelMutation]
  );

  return posCancel;
}
