import React from 'react';
import { Input } from 'components/Form/Input';
import Icon from 'components/Icon';
import { ResponsiveCol, ResponsiveRow } from '../../AddEvents/styles';

export const TieredPricingFields = ({
  tier,
  index,
  handleChange,
  handleRemoveTier,
}) => {
  return (
    <ResponsiveRow style={{ alignItems: 'center' }}>
      <ResponsiveCol style={{ flex: 2 }}>
        <Input
          style={{
            marginRight: '15px',
            marginBottom: '20px',
          }}
          id={`${tier}.label`}
          name={`${tier}.label`}
          label="Label (Name)"
          placeholder=""
          onChange={({ target }) =>
            handleChange({
              index: index,
              name: `label`,
              value: target.value,
            })
          }
          value={tier?.label ?? ''}
        />
      </ResponsiveCol>
      <ResponsiveCol style={{ flex: 2 }}>
        <Input
          style={{
            marginRight: '15px',
            marginBottom: '20px',
          }}
          id={`${tier}.price`}
          name={`${tier}.price`}
          label="App Price"
          placeholder=""
          onChange={({ target }) =>
            handleChange({
              index: index,
              name: `price`,
              value: +target.value,
            })
          }
          type="number"
          value={tier?.price ?? ''}
        />
      </ResponsiveCol>
      <ResponsiveCol style={{ flex: 2 }}>
        <Input
          style={{
            marginRight: '15px',
            marginBottom: '20px',
          }}
          id={`${tier}.track_price`}
          name={`${tier}.track_price`}
          label="Track Price"
          placeholder=""
          onChange={({ target }) =>
            handleChange({
              index: index,
              name: `track_price`,
              value: +target.value,
            })
          }
          type="number"
          value={tier?.track_price ?? ''}
        />
      </ResponsiveCol>
      <ResponsiveCol>
        <Icon
          icon="close"
          size={18}
          color={'#fa4616'}
          onClick={() => {
            handleRemoveTier(index);
          }}
        />
      </ResponsiveCol>
    </ResponsiveRow>
  );
};
