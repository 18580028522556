import { useMutation } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';
import { useCallback } from 'react';

export const ADD_ACCOUNT_CARD = gql`
  mutation AddAccountCard($input: AddAccountCardInput!) {
    addAccountCard(input: $input)
  }
`;

export function useAddAccountCard() {
  const [addAccountCardMutation] = useMutation(ADD_ACCOUNT_CARD);

  const addAccountCard = useCallback(
    (data) => {
      return addAccountCardMutation({
        variables: { input: data },
      });
    },
    [addAccountCardMutation]
  );
  return addAccountCard;
}
