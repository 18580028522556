import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  EventSeriesFilter,
  EventsTable,
  EventTrackFilter,
  EventYearFilter,
  useEventYearFilter,
} from 'components/Events';
import { EventAccountFilter } from 'components/Events/Components/EventAccountFilter';
import { EventFilterType } from 'components/Events/Components/EventFilterType';
import { useGetEventsAdmin } from 'components/Events/gql/RegistrationEvents.queries';
import { SearchableListContainer } from 'components/SearchableListContainer';

const SelectWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;

  @media screen and (max-width: 1300px) {
    flex-wrap: wrap;
  }
`;

export const RegistrationsEventsDesktop = () => {
  const { pathname } = useLocation();
  const eventType = pathname.includes('track')
    ? 'track'
    : pathname.includes('/admin/series')
    ? 'series'
    : 'events';
  const { data } = useGetEventsAdmin(eventType);

  const {
    yearRegistrationFilter,
    setYearRegistrationFilter,
    seriesRegistrationFilter,
    trackRegistrationFilter,
    setTrackRegistrationFilter,
    setSeriesRegistrationFilter,
    accountRegistrationFilter,
    setAccountRegistrationFilter,
    filterRegistrationType,
    setFilterRegistrationType,
  } = useEventYearFilter();

  const events = data?.getEventsAdmin?.results ?? [];
  const count = data?.getEventsAdmin?.count ?? 0;

  return (
    <SearchableListContainer
      pageCount={count}
      paginated
      searchInputPlaceholder="Search Events"
      title="Registrations"
      titleBarContent={
        <SelectWrapper>
          <EventYearFilter
            yearCustomFilter={yearRegistrationFilter}
            setYearCustomFilter={setYearRegistrationFilter}
          />
          <EventFilterType
            filterType={filterRegistrationType}
            setFilterType={setFilterRegistrationType}
            setTrackFilter={setTrackRegistrationFilter}
            setSeriesFilter={setSeriesRegistrationFilter}
            setAccountFilter={setAccountRegistrationFilter}
          />
          {filterRegistrationType?.value === 'track' && (
            <EventTrackFilter
              trackFilter={trackRegistrationFilter}
              setTrackFilter={setTrackRegistrationFilter}
            />
          )}
          {filterRegistrationType?.value === 'series' && (
            <EventSeriesFilter
              seriesFilter={seriesRegistrationFilter}
              setSeriesFilter={setSeriesRegistrationFilter}
            />
          )}
          {filterRegistrationType?.value === 'account' && (
            <EventAccountFilter
              accountFilter={accountRegistrationFilter}
              setAccountFilter={setAccountRegistrationFilter}
            />
          )}
        </SelectWrapper>
      }
    >
      <EventsTable events={events} listType="registrations" />
    </SearchableListContainer>
  );
};
