import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useState } from 'react';
import 'jspdf-autotable';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import { PrintButton } from 'components/PrintButton';
import { useGetEventTransactions } from 'components/Transactions/gql/useGetEventTransactions.mutation';
import checkbox from './checkbox.png';
import { KartPassLogo } from './kartpass-logo';
import { Logo } from './logo';
import { TicketHossLogo } from './tickethoss-logo';
import 'moment-timezone';

const ButtonContainer = styled.div`
  & > button {
    width: 100%;

    @media screen and (min-width: 861px) {
      width: auto;
    }
  }
`;

// const setPriceLine = (transactions) => {
//   const priceLines = transactions
//     .map((user, index) => {
//       return `${user.tickets
//         .map((ticket, ticketIndex) => {
//           return index === 0 && ticket.name.length > 37
//             ? `${'\n'}${ticket.price}${'\n'}`
//             : ticket.name.length > 37
//             ? `${'\n'}${'\n'}${ticket.price}${'\n'}`
//             : index === 0
//             ? `${'\n'}${ticket.price}`
//             : ticketIndex === 0
//             ? `${'\n'}${'\n'}${ticket.price}`
//             : `${'\n'}${ticket.price}`;
//         })
//         .join('\n')}`;
//     })
//     .join('\n');
//   return priceLines;
// };

const getHeaders = (hide_fee) => {
  return [
    hide_fee
      ? [
          'Date/Time',
          'Purchaser',
          'Order Total',
          'Discount',
          'Refund',
          'Credit',
          'Absorbed Fees',
          'Transaction Total',
        ]
      : [
          'Date/Time',
          'Purchaser',
          'Order Total',
          'Discount',
          'Refund',
          'Credit',
          'Transaction Total',
        ],
  ];
};

const getColumns = (hide_fee) => {
  return hide_fee
    ? [
        { dataKey: 'date', header: 'Date/Time' },
        { dataKey: 'purchaser', header: 'Purchaser' },
        { dataKey: 'subtotal', header: 'Order Total' },
        { dataKey: 'discount', header: 'Discount' },
        { dataKey: 'refund', header: 'Refund' },
        { dataKey: 'credit', header: 'Credit' },
        { dataKey: 'fee', header: 'Absorbed Fees' },
        { dataKey: 'total', header: 'Transaction Total' },
      ]
    : [
        { dataKey: 'date', header: 'Date/Time' },
        { dataKey: 'purchaser', header: 'Purchaser' },
        { dataKey: 'subtotal', header: 'Order Total' },
        { dataKey: 'discount', header: 'Discount' },
        { dataKey: 'refund', header: 'Refund' },
        { dataKey: 'credit', header: 'Credit' },
        { dataKey: 'total', header: 'Transaction Total' },
      ];
};

const buildData = (transactions, hide_fee) => {
  return transactions.map((transaction) =>
    hide_fee
      ? [
          transaction.purchase_date,
          transaction.purchaser,
          transaction.subtotal,
          transaction.discount,
          transaction.refund ? `${transaction.refund}` : '',
          transaction.credit,
          transaction.fee,
          transaction.total,
        ]
      : [
          transaction.purchase_date,
          transaction.purchaser,
          transaction.subtotal,
          transaction.discount,
          transaction.refund ? `${transaction.refund}` : '',
          transaction.credit,
          transaction.total,
        ]
  );
};

export const TransactionsPrintList = ({ date, eventId, text, button }) => {
  const isKartPass = 'kartpass' === process.env.REACT_APP_PLATFORM;
  const isTicketHoss = 'tickethoss' === process.env.REACT_APP_PLATFORM;

  const { pathname } = useLocation();
  const userType = pathname?.split('/').includes('admin-employee')
    ? 'employee'
    : pathname?.split('/').includes('admin-track')
    ? 'admin-track'
    : 'admin';
  const isStaging = window.location.href.includes('dashboard-staging');

  const getEventTransactions = useGetEventTransactions();
  const [disabled] = useState(false);

  const getData = () => {
    return getEventTransactions(eventId)
      .then((response) => {
        const eventTransaction = response.data.getEventTransactionsV2;
        return eventTransaction;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const exportPDF = async () => {
    const eventTransaction = await getData();
    const unit = 'pt';
    const size = 'A4'; // Use A1, A2, A3 or A4
    const orientation = 'landscape'; // portrait or landscape

    const event = eventTransaction.event;

    const grossSales = eventTransaction.gross_sales;
    // const netSales = eventTransaction.net_sales;
    // const numOfTickets = eventTransaction.num_tickets;

    const doc = new jsPDF(orientation, unit, size, true);

    doc.setFontSize(15);
    doc.setFontStyle('bold');

    const title = date;

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ?? pageSize.getHeight();

    const text = doc.splitTextToSize(title, pageWidth - 650, {});

    const trackOrSeriesName =
      event.track?.name ??
      (event.series?.length > 0 ? event.series[0].name : '');
    doc.text(text, 40, 40);
    doc.text(
      event?.name.length > 75 ? event?.name.slice(0, 75) : event?.name,
      40,
      60
    );
    let Y = 60;
    if (event?.name.length > 75) {
      Y += 20;
      doc.text(event?.name.slice(75, 150), 40, Y);
    }

    if (event?.account_name) {
      Y += 20;
      doc.text(event?.account_name, 40, Y);
    }

    if (trackOrSeriesName) {
      Y += 20;
      doc.text(trackOrSeriesName, 40, Y);
    }

    Y += 20;
    doc.text(
      `${
        event.hide_fee ? `Net Sales ${grossSales}` : `Total Sales ${grossSales}`
      } `,
      40,
      Y
    );
    Y += 20;

    if (event.marketing_fee) {
      doc.text(`1% Cash Back ${eventTransaction.marketing_fund}`, 40, Y);
    }
    Y += 20;

    doc.setTextColor('#fa4616');

    doc.setTextColor('#000');

    doc.text('Transaction Report', 620, 45);
    Y += 30;

    if (checkbox) {
      if (isKartPass) {
        doc.addImage(KartPassLogo, 'PNG', 650, 50, 100, 75, '', 'FAST');
      } else if (isTicketHoss) {
        doc.addImage(TicketHossLogo, 'PNG', 660, 65, 100, 54, '', 'FAST');
      } else {
        doc.addImage(Logo, 'PNG', 660, 50, 100, 100, '', 'FAST');
      }
    }

    const extra = 30;
    const headers = getHeaders(eventTransaction.event.hide_fee);

    const data1 = buildData(
      eventTransaction.data[1].data,
      eventTransaction.event.hide_fee
    );

    doc.setTextColor('#fa4616');

    if (event.hide_fee) {
      Y += 10;
      doc.text(`Promoter Absorbed Fees`, 620, Y);
      Y += 10;
    }

    doc.setTextColor('#000');

    doc.text(`Credits Redeemed - ${eventTransaction?.data[1]?.total}`, 40, Y);
    Y += 10;

    doc.autoTable({
      startY: Y,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data1,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${
            eventTransaction.data[1].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data2 = buildData(
      eventTransaction.data[2].data,
      eventTransaction.event.hide_fee
    );

    doc.text(
      `Credits Issued - ${eventTransaction?.data[2]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 10;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data2,
      styles: {
        fontStyle: 'bold',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${
            eventTransaction.data[2].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data3 = buildData(
      eventTransaction.data[3].data,
      eventTransaction.event.hide_fee
    );

    let availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Discounts - ${eventTransaction?.data[3]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data3,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${
            eventTransaction.data[3].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data4 = buildData(
      eventTransaction.data[4].data,
      eventTransaction.event.hide_fee
    );

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `Refunds Issued - ${eventTransaction?.data[4]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data4,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${
            eventTransaction.data[4].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const data5 = buildData(
      eventTransaction.data[0].data,
      eventTransaction.event.hide_fee
    );

    availableSpace = pageHeight - (doc.lastAutoTable.finalY + extra);

    if (availableSpace < 10) {
      doc.addPage();
    }

    doc.text(
      `All Transactions - ${eventTransaction?.data[0]?.total}`,
      40,
      doc.lastAutoTable.finalY + extra
    );
    Y += 20;

    doc.autoTable({
      startY: doc.lastAutoTable.finalY + extra + 10,
      rowPageBreak: 'avoid',
      columns: getColumns(eventTransaction.event.hide_fee),
      head: headers,
      body: data5,
      styles: {
        fontStyle: 'bold',
      },
      headStyles: {
        fillColor: isKartPass ? '#00001F' : '#fa4616',
      },
      columnStyles: {
        purchaser: {
          textColor: '#fa4616',
        },
      },
      tableWidth: 'auto',
      willDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          const rowIndex = data.row.index;
          // Calculate the X and Y positions for the text
          const x = data.cell.x + 4.43;
          const y = data.cell.y + 13.5;

          // Define the URL for the clickable link
          const linkUrl = `https://dashboard${isStaging ? '-staging' : ''}.${
            process.env.REACT_APP_PLATFORM
          }.com/${userType}/transactions/event/${eventId}?purchase_id=${
            eventTransaction.data[0].data[rowIndex]?.id
          }`;

          // Add the text to the cell
          data.cell.text.forEach((text, index) => {
            doc.textWithLink(text, x, y + index * 11.2, {
              url: linkUrl,
            });
          });
        }
      },
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i <= pageCount; i++) {
      doc.setPage(i);
      doc.setFontSize(10);
      doc.setFontStyle('normal');

      const now = moment();
      const momentInSpecificTimezone = now.tz('America/New_York');
      const dateTimeStamp =
        momentInSpecificTimezone.format('MMM DD - YYYY h:mm A').toUpperCase() +
        ' EST';
      doc.text(`Exported ${dateTimeStamp}`, pageWidth - 40, pageHeight - 20, {
        align: 'right',
      });
    }
    // Set typography back to default
    doc.setFontSize(15);
    doc.setFontStyle('bold');

    doc.setProperties({
      title: `${date} - ${event.name} - TRANSACTION REPORT.pdf`,
      subject: `${date} - ${event.name} - TRANSACTION REPORT`,
      author: process.env.REACT_APP_PLATFORM,
    });
    return {
      ...doc,
      fileName: `${date} - ${event.name} - TRANSACTION REPORT.pdf`,
    };
  };

  return (
    <ButtonContainer>
      <PrintButton
        buildPdf={exportPDF}
        text={text}
        disabled={disabled}
        variant={'minimal'}
        button={button}
      />
    </ButtonContainer>
  );
};
