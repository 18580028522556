import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Modal } from 'components/Modal';
import { useCart } from 'shared/CartContext';
import { useGetReaders } from './gql/queries/useGetReaders';

const Container = styled.div`
  padding: 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  width: 100%;
`;

const ReaderEntry = styled.div`
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 15px;
  padding: 15px 30px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isSelected ? props.theme.colors.primary : 'transparent'};
  border: 1px solid #ccc;
  color: ${(props) => (props.isSelected ? '#fff' : '#000')};
  gap: 10px;
  font-size: 18px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.primary};
    color: #fff;
  }
`;

export const ReaderSelectionModal = ({
  isVisible,
  setIsVisible,
  setIsCheckoutVisible,
}) => {
  const { accountId } = useCart();
  const { data } = useGetReaders(accountId);

  const [readers, setReaders] = useState([]);
  const [selectedReader, setSelectedReader] = useState(null);

  useEffect(() => {
    if (isVisible) {
      if (data) {
        const readers = data.getAccount?.readers;

        if (readers?.length > 0) {
          if (readers.length === 1) {
            document.cookie = `${accountId}-reader=${
              readers[0].reader_id
            }; expires=${new Date(
              new Date().getTime() + 60 * 1000 * 5
            ).toUTCString()}; path=/`;

            setIsVisible(false);
            setIsCheckoutVisible(true);
          } else setReaders(readers);
        }
      }
    }
  }, [accountId, isVisible, data, setIsVisible, setIsCheckoutVisible]);

  const handleClick = (reader) => {
    setSelectedReader(reader);
  };

  const handleContinue = () => {
    // Save the selected reader id to cookies with an expiration time of 24 hours
    document.cookie = `${accountId}-reader=${
      selectedReader.reader_id
    }; expires=${new Date(
      new Date().getTime() + 60 * 1000 * 5
    ).toUTCString()}; path=/`;

    setIsVisible(false);
    setIsCheckoutVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      isVisible={isVisible}
      hideModal={handleClose}
      title={`Select your credit card reader from the list below`}
      maxWidth={600}
    >
      <Container>
        {readers?.map((reader) => (
          <ReaderEntry
            key={reader.id}
            isSelected={selectedReader && reader.id === selectedReader.id}
            onClick={() => handleClick(reader)}
          >
            <span
              style={{
                fontWeight: 600,
              }}
            >
              {reader.label}
            </span>
            <span
              style={{
                fontWeight: 500,
              }}
            >
              {reader.reader_id}
            </span>
          </ReaderEntry>
        ))}
        <ButtonRow>
          <Button
            type="button"
            onClick={handleContinue}
            disabled={!selectedReader}
          >
            Continue
          </Button>
        </ButtonRow>
      </Container>
    </Modal>
  );
};
