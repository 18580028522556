import { useQuery } from '@apollo/react-hooks';
import { gql } from 'graphql-tag';

export const POS_REVIEW = gql`
  query PosReview($input: POSReviewInput!) {
    posReview(input: $input) {
      subtotal
      amount
      discount
    }
  }
`;

export function usePosReview(event_id, tickets) {
  return useQuery(POS_REVIEW, {
    variables: {
      input: { event_id, tickets: tickets },
    },
    fetchPolicy: 'no-cache',
    skip: !tickets || tickets.length === 0,
  });
}
