import { Formik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import {
  isPossiblePhoneNumber,
  getCountryCallingCode,
} from 'react-phone-number-input';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Button } from 'components/Button';
import { Drawer, DrawerPadding } from 'components/Drawer';
import { AutoSuggest } from 'components/Form/AutoSuggest';
import { DatePicker } from 'components/Form/DatePicker';
import { Input } from 'components/Form/Input';
import { PhoneInput } from 'components/Form/PhoneInput';
import { Radio } from 'components/Form/Radio';
import { ErrorText } from 'components/Form/styles';
import { HelpModals } from 'components/HelpModals';
import Icon from 'components/Icon';
import { OwnershipInput } from 'components/Ownership';
import { useMyOwnershipQuery } from 'components/Ownership/gql/me';
import Spacer from 'components/Spacer';
import { useCreateGuest } from './gql/useCreateGuest';
import { useGetGuestAndUserNotificationEvents } from './gql/useGetGuest';
import { useUpdateGuest } from './gql/useUpdateGuest';

const FormLabel = styled.p`
  margin-bottom: 20px;
  color: #fff;
  font-weight: 600;
  font-family: 'Barlow Condensed';
  text-align: left;
  font-size: 20px;
  line-height: 29px;

  @media (min-width: 768px) {
    color: #000;
  }
`;

const GuestInviteMessage = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-size: 1rem;
  line-height: 1.2;
`;

export const hasMoreThanOneVenue = (user) => {
  if (!user.ownership.tracks.length && !user.ownership.series.length)
    return false;

  if (user.ownership.tracks.length + user.ownership.series.length < 2)
    return false;

  return true;
};

export const GuestForm = ({
  isVisible,
  handleOutClick,
  guestId,
  guestTypeToAdd,
  queryString,
  setNewGuestData,
  theme,
}) => {
  const successMessage = () =>
    toast.success(guestId ? 'Guest Updated' : 'Guest Created');
  const errorMessage = () =>
    toast.error(guestId ? 'Error Updating Guest' : 'Error Creating Guest');

  const { id: eventId } = useParams();
  const { data } = useGetGuestAndUserNotificationEvents(guestId);
  const { data: myOwnershipData } = useMyOwnershipQuery();
  const createGuest = useCreateGuest({ queryString });
  const updateGuest = useUpdateGuest();

  const [showHelpModal, setShowHelpModal] = useState(false);

  const guest = data?.getGuest;
  if (!guest && guestId) return null;

  const events = data?.getUserEvents.results ?? [];

  const openHelpModal = () => {
    setShowHelpModal(true);
    handleOutClick();
  };

  return (
    <Drawer
      title={
        guestId ? (
          'Edit Guest Details'
        ) : (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            Create New Guest
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                marginLeft: 20,
              }}
            >
              <Icon
                style={{ marginTop: 7 }}
                icon="Dashboard-Help-Icon"
                onClick={openHelpModal}
                size={35}
                color={theme.colors.primary}
              />
            </div>
          </div>
        )
      }
      isVisible={isVisible}
      handleOutClick={handleOutClick}
    >
      <Formik
        enableReinitialize={true}
        initialValues={
          guestId
            ? {
                first_name: guest?.first_name ?? '',
                last_name: guest?.last_name ?? '',
                phone_number: guest?.phone_number ?? '',
                calling_code: guest?.calling_code ?? '1',
                country_code: guest?.country_code ?? 'US',
                year:
                  guestTypeToAdd === 'yearly_guests'
                    ? moment().format('YYYY')
                    : guest?.year
                    ? String(guest.year)
                    : moment().format('YYYY'),
                event_id:
                  guestTypeToAdd === 'event_guests'
                    ? eventId
                    : guest?.event_id ?? '',
                event: '',
                additional_guests: guest?.additional_guests ?? '',
                notes: guest?.notes ?? '',
                guest_type:
                  guestTypeToAdd === 'event_guests'
                    ? 'event_guests'
                    : guestTypeToAdd === 'yearly_guests'
                    ? 'yearly_guests'
                    : guest?.year
                    ? 'yearly_guests'
                    : guest?.event_id
                    ? 'event_guests'
                    : '',
                ownership: guest?.ownership ?? '',
              }
            : {
                first_name: '',
                last_name: '',
                phone_number: '',
                calling_code: '1',
                country_code: 'US',
                year: moment().format('YYYY'),
                event_id: '',
                event: '',
                additional_guests: '',
                notes: '',
                guest_type: '',
                ownership: '',
              }
        }
        validate={(values) => {
          const errors = {
            ...(!values.first_name ? { first_name: 'Required' } : null),
            ...(!values.last_name ? { last_name: 'Required' } : null),
            ...(values.phone_number &&
            !isPossiblePhoneNumber(values.phone_number)
              ? { phone_number: 'Please enter a valid phone number' }
              : null),
            ...(!values.guest_type ? { guest_type: 'Required' } : null),
            ...(values.guest_type === 'yearly_guests' && !values.year
              ? { guest_type: 'Required' }
              : null),
            ...(values.guest_type === 'event_guests' && !values.event_id
              ? { guest_type: 'Required' }
              : null),
            ...(values.guest_type === 'yearly_guests' &&
            hasMoreThanOneVenue(myOwnershipData?.me) &&
            (!values.ownership || values.ownership === '')
              ? { ownership: 'Required' }
              : null),
          };

          return errors;
        }}
        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);

          const data = Object.entries(values).reduce(
            (fieldValues, [key, value]) => {
              const fieldValue = value === '' ? null : value;
              return {
                ...fieldValues,
                [key]: fieldValue,
              };
            },
            {}
          );

          const mutation = guestId ? updateGuest : createGuest;

          const ownedTrackOrSeries =
            myOwnershipData?.me?.ownership?.tracks?.[0]?.id ??
            myOwnershipData?.me?.ownership?.series?.[0]?.id;
          const ownership = data.ownership?.value ?? ownedTrackOrSeries;

          const input = {
            ...(guestId ? { id: Number(guestId) } : null),
            first_name: data.first_name,
            last_name: data.last_name,
            phone_number: data.phone_number.replace(
              `+${data.calling_code}`,
              ''
            ),
            country_code: data.country_code,
            calling_code: data.calling_code,
            year:
              data.guest_type === 'yearly_guests' ? Number(data.year) : null,
            event_id:
              data.guest_type === 'event_guests' ? Number(data.event_id) : null,
            notes: data.notes,
            additional_guests: Number(data.additional_guests),
            ownership: ownership ? Number(ownership) : null,
          };

          const response = await mutation(input);

          setSubmitting(false);

          if (!response || response.errors) {
            errorMessage();
            setSubmitting(false);
            return setErrors(response.errors);
          } else {
            successMessage();
            resetForm();
            handleOutClick();
            if (response.data.createGuestV2) {
              setNewGuestData(response.data.createGuestV2);
            }
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <>
                <DrawerPadding border>
                  <FormLabel style={{ color: '#00001f' }}>
                    {guestId
                      ? 'Fill out the form to edit a guests information'
                      : 'Fill out the form to add a new guest'}
                  </FormLabel>
                  <Spacer size={18} />
                  <Input
                    id="first_name"
                    label="First Name"
                    placeholder="First Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.first_name}
                    error={
                      errors.first_name &&
                      touched.first_name &&
                      errors.first_name
                    }
                  />
                  <Spacer size={18} />
                  <Input
                    id="last_name"
                    label="Last Name"
                    placeholder="Last Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.last_name}
                    error={
                      errors.last_name && touched.last_name && errors.last_name
                    }
                  />
                  <Spacer size={18} />
                  <PhoneInput
                    id="phone_number"
                    label="Mobile Phone Number"
                    placeholder="___ ___ ____"
                    onBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue('phone_number', value ?? '');
                    }}
                    onCountryChange={(e) => {
                      if (e) {
                        setFieldValue('country_code', e ?? '');
                        setFieldValue(
                          'calling_code',
                          getCountryCallingCode(e) ?? ''
                        );
                      }
                    }}
                    value={values.phone_number}
                    error={errors.phone_number}
                  />
                  <Spacer size={18} />
                  <Radio
                    title="Event Guest to"
                    name="guest_type"
                    value={values.guest_type}
                    options={[
                      {
                        value: 'yearly_guests',
                        label: 'The entire year',
                      },
                      {
                        value: 'event_guests',
                        label: 'A single event',
                      },
                    ]}
                    onChange={(event) => {
                      setFieldValue('guest_type', event.target.value);
                    }}
                  />
                  {touched.guest_type && errors.guest_type && (
                    <ErrorText>{errors.guest_type}</ErrorText>
                  )}
                  {values.guest_type === 'yearly_guests' && (
                    <>
                      <Spacer size={18} />
                      <DatePicker
                        id="year"
                        name="year"
                        label="Year"
                        onChange={(name, value) => {
                          setFieldValue(
                            name,
                            value ? moment(value).format('YYYY') : ''
                          );
                        }}
                        onBlur={handleBlur}
                        value={values.year}
                        error={touched.year && errors.year}
                        type="date"
                        showYearPicker
                        dateFormat="yyyy"
                      />
                    </>
                  )}
                  {values.guest_type === 'event_guests' && (
                    <>
                      <Spacer size={18} />
                      <AutoSuggest
                        name="event_id"
                        id="event_id"
                        label="Event"
                        isClearable
                        placeholder="Event"
                        onChange={(value) => {
                          setFieldValue('event', value ?? '');
                          setFieldValue('event_id', value?.value ?? '');
                        }}
                        value={values.event_id ? values.event : null}
                        error={touched.event_id && errors.event_id}
                        closeMenuOnSelect
                        options={events.map((event) => {
                          const {
                            id,
                            date,
                            day,
                            isMultiDay,
                            listDates,
                            month,
                            name,
                          } = event;
                          return {
                            value: Number(id),
                            label: isMultiDay
                              ? `${month.toUpperCase()} ${listDates} - ${name}`
                              : `${day} ${date} - ${name}`,
                          };
                        })}
                      />
                      {touched.event_id && errors.event_id && (
                        <ErrorText>{errors.event_id}</ErrorText>
                      )}
                    </>
                  )}
                  {values.guest_type === 'yearly_guests' && (
                    <>
                      <Spacer size={18} />
                      <OwnershipInput
                        label="Select a track or series"
                        placeholder="Select a track or series..."
                        value={values.ownership}
                        error={touched.ownership && errors.ownership}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}
                  <Spacer size={18} />
                  <Input
                    id="additional_guests"
                    label="Additional Guests Allowed"
                    placeholder="Number of Additional Guests."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.additional_guests}
                    error={
                      errors.additional_guests &&
                      touched.additional_guests &&
                      errors.additional_guests
                    }
                  />
                  <Spacer size={18} />
                  <Input
                    id="notes"
                    as="textarea"
                    label="Internal Guest Notes"
                    placeholder="Enter a note about this guest"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.notes}
                    error={errors.notes && touched.notes && errors.notes}
                  />
                  <Spacer size={12} />
                  {!guestId && (
                    <GuestInviteMessage>
                      If the guest's mobile number is added above, they will
                      receive a text message notifying them that they are on the
                      guest list for the season or event, and how many guests
                      that they may bring.
                    </GuestInviteMessage>
                  )}
                </DrawerPadding>
              </>
              <DrawerPadding>
                <Button type="submit" disabled={isSubmitting} block>
                  {guestId ? 'Update Guest' : 'Add and Invite'}
                </Button>
              </DrawerPadding>
            </form>
          );
        }}
      </Formik>
      <HelpModals
        isVisible={showHelpModal}
        setIsVisible={setShowHelpModal}
        contents={[
          {
            title: 'Explanation Part 1',
            content:
              'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
            video: 'https://player.vimeo.com/video/903075544?h=a35101967c',
          },
          {
            title: 'Explanation Part 2',
            content: (
              <div>
                <p>
                  Lorem ipsum is placeholder text commonly used in the graphic,
                  print, and publishing industries for previewing layouts and
                  visual mockups.
                </p>
                <Spacer size={16} />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </p>
              </div>
            ),
          },
          {
            title: 'Explanation Part 3',
            content: (
              <span>
                Lorem ipsum is placeholder text commonly used in the graphic,
                print, and publishing industries for previewing layouts and
                visual mockups.
              </span>
            ),
          },
          {
            title: 'Hide Help Like This?',
            titleStyle: {
              fontSize: 32,
              fontWeight: 700,
              lineHeight: '40px',
              fontFamily: 'Barlow Condensed',
            },
            content: (
              <div style={{ alignContent: 'center', textAlign: 'center' }}>
                <p style={{ fontWeight: 500, fontFamily: 'Roboto' }}>
                  Click on any{' '}
                  <Icon
                    icon="Dashboard-Help-Icon"
                    size={28}
                    color={theme.colors.primary}
                  />{' '}
                  icon to bring it back.
                </p>

                <Spacer size={20} />
                <Button onClick={() => setShowHelpModal(false)}>
                  {' '}
                  Hide For Today{' '}
                </Button>

                <Spacer size={10} />
                <Button onClick={() => setShowHelpModal(false)} outlined={true}>
                  {' '}
                  Hide Forever
                </Button>

                <Spacer size={10} />
                <Button
                  onClick={() => setShowHelpModal(false)}
                  outlined={true}
                  buttonStyle={{ border: 'none' }}
                >
                  {' '}
                  Hide Now
                </Button>
              </div>
            ),
            hideFooter: true,
            customStyle: {
              justifyContent: 'center',
              margin: 'auto',
              textAlign: 'center',
            },
          },
        ]}
      />
    </Drawer>
  );
};
